import { TCreateUserDto } from "types/Core/Users/createUserDto";

const createUserMapper = (formValues: any): TCreateUserDto => ({
  username: formValues.username,
  password: formValues.password,
  name: formValues.name,
  lastname: formValues.lastname,
  isSuperAdministrator: Boolean(Number(formValues.isSuperAdministrator.value)),
  mail: formValues.mail,
  avatarUrl: formValues.avatarUrl === "" ? null : formValues.avatarUrl,
  clientId: formValues.client.value,
  projects: formValues.projects.map((elem: { value: any }) => ({
    projectId: elem.value,
    rolesId: ["1"],
  })),
});

export default createUserMapper;
