import { ReactElement } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import LinkIcon from "mdi-react/LinkIcon";
import FormTextField from "@components/Shared/FormTextField";
import { useTranslation } from "react-i18next";
import IFormProps from "@interfaces/Shared/formProps";
import renderSelectField from "@easydev/form/Select";
import useStates from "@mappers/Shared/getState.mapper";

const CreateForm = ({ handleSubmit }: IFormProps): ReactElement => {
  const { GetStates } = useStates();
  const { t } = useTranslation(["core"]);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="title"
            component={FormTextField}
            type="textbox"
            placeholder={t("core:common.title")}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.desktopImageLink")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkIcon />
          </div>
          <Field
            name="desktopLinkImage"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.desktopImageLink")}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.mobileImageLink")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkIcon />
          </div>
          <Field
            name="mobileLinkImage"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.mobileImageLink")}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateForm;
