import { ReactElement } from "react";
import GoogleAnalyticsIcon from "mdi-react/GoogleAnalyticsIcon";
import { Col, Container, Row } from "reactstrap";
import styles from "./Dashboard.module.scss";

const Dashboard = (): ReactElement => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className={styles.page__title}>
            <GoogleAnalyticsIcon />
            Dashboard
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
