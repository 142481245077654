import { ElementType, ReactElement } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import useCreateItem from "@hooks/Shared/useCreateItem";
import ModalConfirmation from "@components/ModalConfirmation";
import getModuleName from "@helpers/modules";
import { EUiStates } from "@constants/enum";
import { THookCreate } from "types/Shared/hooks";
import Loader from "@components/Loader";
import Title from "@components/Title";

interface ICreate {
  CustomForm: ElementType;
  createHook: THookCreate;
  validator: (FormValues: Record<string, any>) => any;
  formMapper: (FormValues: any) => any;
}

const Create = ({
  CustomForm,
  createHook,
  validator,
  formMapper,
}: ICreate): ReactElement => {
  const { t } = useTranslation(["core"]);
  const { pathname } = useLocation();
  const {
    isCreating,
    isModalOpen,
    handleSaveForm,
    handleCreate,
    handleIsModalOpen,
  } = useCreateItem();

  return (
    <Container>
      <ModalConfirmation
        icon="plus-circle"
        title={`${t(getModuleName(pathname))} | ${t(
          "core:common.creation_confirm"
        )}`}
        body={t("core:notifications.creation_confirm")}
        handleConfirm={() => {
          handleCreate(createHook);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.SUCCESS}
      />
      <Title />
      <Row>
        <Col xs={12} md={12} lg={12} xl={10}>
          <Card>
            <CardBody>
              <Form
                onSubmit={(FormValues) => {
                  handleSaveForm(FormValues, formMapper);
                }}
                validate={validator}
              >
                {({ handleSubmit, form }) =>
                  isCreating ? (
                    <Loader isInsideThing />
                  ) : (
                    <CustomForm handleSubmit={handleSubmit} form={form} />
                  )
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Create;
