import {
  VAULT_CAROUSEL_MODIFY,
  VAULT_CAROUSEL_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import ICarouselItem from "@interfaces/Clients/WhaleShark/vaultCarousel/carouselItem";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listCarouselItems = (items: ICarouselItem[], handleDelete: Function) =>
  items.map(({ uuid, title, createdAt, updatedAt, state }, index) => ({
    uuid,
    order: index + 1,
    title,
    createdAt: timestampToString(createdAt),
    updatedAt: timestampToString(updatedAt),
    state: <BoxStatus state={state} />,
    actions: (
      <BoxListButton
        uuid={uuid}
        description={title}
        detailRoute={VAULT_CAROUSEL_DETAIL}
        modifyRoute={VAULT_CAROUSEL_MODIFY}
        handleDelete={handleDelete}
      />
    ),
  }));

export default listCarouselItems;
