import { ReactElement, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import deletePartner from "@services/Clients/WhaleShark/partner/deleteItem";
import useListColumns from "@hooks/Clients/WhaleShark/partner/useListColumns";
import useDeleteItem from "@hooks/Shared/useDeleteItem";
import ModalConfirmation from "@components/ModalConfirmation";
import CreateBtn from "@components/CreateButton";
import Loader from "@components/Loader";
import Table from "@components/Table";
import Title from "@components/Title";
import { EUiStates } from "@constants/enum";
import useListItems from "@hooks/Shared/useListItems";
import getPartners from "@services/Clients/WhaleShark/partner/getItems";
import listMapper from "@mappers/Clients/WhaleShark/partner/ListPartner.mapper";

const ListItems = (): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const { isLoading, basicData, tableItems } = useListItems(
    isDeletingItem,
    getPartners,
    listMapper,
    true
  );
  const { isModalOpen, isDeleting, handleDelete, handleIsModalOpen } =
    useDeleteItem(basicData);
  const COLUMNS = useListColumns();

  useEffect(() => {
    setIsDeletingItem(isDeleting);
  }, [isDeleting]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <ModalConfirmation
        icon="trash"
        title={`Whale Shark | ${t("whaleshark:common.partner")} | ${t(
          "core:common.delete_confirm"
        )}`}
        body={`${t("core:notifications.delete_message")} "${
          basicData?.description
        }" ?`}
        handleConfirm={() => {
          handleDelete(deletePartner);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.DANGER}
      />

      <Title />
      <CreateBtn />
      <Table columns={COLUMNS} items={tableItems} />
    </Container>
  );
};

export default ListItems;
