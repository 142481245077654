import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import IdentifierIcon from "mdi-react/IdentifierIcon";
import FormTextboxIcon from "mdi-react/FormTextboxIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import avatar_logo from "@images/account/avatar.png";
import IClient from "@interfaces/Core/ClientManager/client";
import ButtonContainer from "@components/ButtonContainer";
import BoxImage from "@components/BoxImage";
import { timestampToString } from "@helpers/dates.format";

interface IDetailForm {
  data: IClient;
}

const DetailClient = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["core"]);

  return (
    <form className="form">
      <Col md={5}>
        <BoxImage altImage="avatar" urlImage={data?.logoUrl ?? avatar_logo} />
      </Col>
      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.name")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <FormTextboxIcon />
            </div>
            <input type="text" value={data?.name} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">{t("core:common.key")}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <IdentifierIcon />
            </div>
            <input type="text" value={data?.key} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.created_at")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarPlusIcon />
            </div>
            <input value={timestampToString(data?.createdAt)} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.updated_at")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarEditIcon />
            </div>
            <input value={timestampToString(data?.updatedAt)} disabled />
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailClient;
