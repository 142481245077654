/* eslint-disable import/no-anonymous-default-export */
import { EN } from "@constants/countryCodes";
import { KEY_STORE_I18N, KEY_STORE_USER } from "../constants/env";
import { ICurrentUser } from "../interfaces/Core/Authentication/currentUser";

class LocalStorageService {
  setI18nStorage = (lng: string) => {
    localStorage.setItem(KEY_STORE_I18N, lng);
  };

  getI18nStorage = () => {
    return localStorage.getItem(KEY_STORE_I18N) ?? EN;
  };

  setLocalUserStorage = (user: ICurrentUser) => {
    localStorage.setItem(KEY_STORE_USER, JSON.stringify(user));
  };

  getLocalUserStorage = (): ICurrentUser | null => {
    const localUser = localStorage.getItem(KEY_STORE_USER);
    const currentUser = localUser
      ? (JSON.parse(localUser) as ICurrentUser)
      : null;

    return currentUser;
  };

  removeLocalStorage = () => {
    localStorage.removeItem(KEY_STORE_USER);
  };
}
export default new LocalStorageService();
