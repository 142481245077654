import { ReactElement } from "react";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import ButtonContainer from "@components/ButtonContainer";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import EthereumIcon from "mdi-react/EthereumIcon";
import FormTextboxIcon from "mdi-react/FormTextboxIcon";
import BookmarkBoxMultipleIcon from "mdi-react/BookmarkBoxMultipleIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import Player from "@components/Player";
import BoxStatus from "@components/BoxStatus";
import { AORIST, OPEN_SEA } from "@constants/types";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import { IAsset } from "@interfaces/Clients/Reisinger/asset";
import styles from "./DetailForm.module.scss";
import { timestampToString } from "@helpers/dates.format";

interface IDetailForm {
  data: IAsset;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["reisinger", "core"]);
  const type = data?.config?.type ?? OPEN_SEA;

  return (
    <form className="form">
      <Col md={5} className={styles.profile__info}>
        <Player
          media={data?.animation_url ?? data?.image_url}
          title="nft preview"
        />

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.config.show ?? true} />
          </div>
        </div>
      </Col>

      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.type")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <FormTextboxIcon />
            </div>
            <input
              type="text"
              value={data?.config?.type ?? OPEN_SEA}
              disabled
            />
          </div>
        </div>

        {type === OPEN_SEA && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.name")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <FormTextboxIcon />
              </div>
              <input type="text" value={data?.name} disabled />
            </div>
          </div>
        )}

        {type === OPEN_SEA && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.link")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <LinkVariantIcon />
              </div>
              <input type="text" value={data?.permalink} disabled />
              <a
                href={data?.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="form__form-group-icon">
                  <OpenInNewIcon />
                </div>
              </a>
            </div>
          </div>
        )}

        {type === AORIST && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.title")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <ViewHeadlineIcon />
              </div>
              <input type="text" value={data?.name} disabled />
            </div>
          </div>
        )}

        {type === AORIST && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.page_link")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <LinkVariantIcon />
              </div>
              <input type="text" value={data?.permalink} disabled />
              <a
                href={data?.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="form__form-group-icon">
                  <OpenInNewIcon />
                </div>
              </a>
            </div>
          </div>
        )}

        {type === AORIST && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.piece_link")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <LinkVariantIcon />
              </div>
              <input type="text" value={data?.image_url} disabled />
              <a
                href={data?.image_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="form__form-group-icon">
                  <OpenInNewIcon />
                </div>
              </a>
            </div>
          </div>
        )}

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.edition")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <BookmarkBoxMultipleIcon />
            </div>
            <input
              type="text"
              value={data?.config.description ?? "-"}
              disabled
            />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.price")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <EthereumIcon />
            </div>
            <input value={`${data?.config.price ?? "-"}`} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.release_date")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarPlusIcon />
            </div>
            <input
              value={timestampToString(data?.config?.release_date?.getTime())}
              disabled
            />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.order")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <OrderNumericAscendingIcon />
            </div>
            <input
              type="number"
              value={data?.config.order ?? undefined}
              disabled
            />
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
