import { ElementType, ReactElement } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import Loader from "@components/Loader";
import Title from "@components/Title";

interface IDetail {
  CustomForm: ElementType;
  detailHook: (uuid: string) => any;
}

const Detail = ({ CustomForm, detailHook }: IDetail): ReactElement => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data, loading } = detailHook(uuid);

  return (
    <Container>
      <Title />
      <Row>
        <Col xs={12} md={12} lg={12} xl={10}>
          <Card>
            <CardBody>
              {loading ? <Loader /> : <CustomForm data={data} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Detail;
