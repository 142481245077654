export const fullToolbar = [
  "heading",
  "|",
  "fontSize",
  "fontFamily",
  "|",
  "fontColor",
  "fontBackgroundColor",
  "|",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "|",
  "alignment",
  "|",
  "numberedList",
  "bulletedList",
  "|",
  "outdent",
  "indent",
  "|",
  "link",
  "blockQuote",
  "imageInsert",
  "mediaEmbed",
  "|",
  "undo",
  "redo",
];

export const defaultFontFamily = {
  options: [
    "Arial",
    "Georgia",
    "Impact",
    "Montserrat",
    "Stolzl",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ],
};
