import { NFT_PIECES } from "@constants/routes/Clients/reisinger.routes";

const REISINGER = [
  {
    route: NFT_PIECES,
    name: "reisinger:common.nft_pieces",
  },
];

export default REISINGER;
