import { Row } from "reactstrap";
import DataReactTable from "@easydev/tables/DataReactTable";

const TableDraggeable = ({ columns, items, updateIndexes }) => {
  if (items) {
    return (
      <Row className="table-container">
        <DataReactTable
          reactTableData={{ tableHeaderData: columns, tableRowsData: items }}
          withDragAndDrop
          updateIndexes={updateIndexes}
          withPagination
          manualPageSize={[items.length, 200, 300, 400]}
          defaultTableSize={100}
        />
      </Row>
    );
  }
  return <div></div>;
};

export default TableDraggeable;
