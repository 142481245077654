import { Route, Switch } from "react-router-dom";
import MainWrapper from "./MainWrapper";
import {
  LOGIN,
  HOME,
  PAGE401,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
} from "@constants/routes/routes";
import WrappedRoutes from "./WrappedRoutes";
import NotFound404 from "@pages/NotFound404";
import NotAccess from "@pages/NotAccess401";
import LogIn from "@pages/LogIn";
import ForgotPassword from "@pages/Account/ForgotPassword";
import ResetPasswordConfirmation from "@pages/Account/ResetPasswordConfimation";
import ResetPassword from "@pages/Account/ResetPassword";

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path={LOGIN} component={LogIn} />
        <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={RESET_PASSWORD} component={ResetPassword} />
        <Route
          exact
          path={RESET_PASSWORD_CONFIRM}
          component={ResetPasswordConfirmation}
        />
        <Route exact path={PAGE401} component={NotAccess} />
        <Route path={HOME} component={WrappedRoutes} />
        <Route component={NotFound404} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
