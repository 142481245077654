import { useState, useEffect } from "react";
import getAssets from "@services/Clients/Reisinger/nftPieces/getAssets";
import { useHistory } from "react-router-dom";
import { IAsset } from "@interfaces/Clients/Reisinger/asset";
import { showNotification } from "@easydev/Notification";
import orderListAssets from "@mappers/Clients/Reisinger/nftPieces/OrderListAssets.mapper";
import ISimpleAsset from "@interfaces/Clients/Reisinger/simpleAsset";
import { useTranslation } from "react-i18next";
import { IBaseUse } from "@interfaces/Shared/baseUse";
import reorderAssets from "@services/Clients/Reisinger/nftPieces/reorderAssets";
import orderAssets from "@mappers/Clients/Reisinger/nftPieces/OrderAssets.mapper";
import { NFT_PIECES } from "@constants/routes/Clients/reisinger.routes";

interface IUseChangeOrder extends IBaseUse {
  assets: ISimpleAsset[] | undefined;
  handleConfirmModify: () => void;
  updateIndexes: (val: any) => void;
}

type TDropResult = {
  destination: number;
  source: number;
};

const useChangeOrder = (): IUseChangeOrder => {
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState<ISimpleAsset[]>([]);
  const [modalStatus, setModalStatus] = useState(false);
  const history = useHistory();

  const { t } = useTranslation(["core", "reisinger"]);

  const columns = [
    {
      Header: t("core:common.name"),
      accessor: "name",
    },
    {
      Header: t("core:common.order").concat(" " + t("core:common.current")),
      accessor: "order_old",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.order").concat(" " + t("core:common.new")),
      accessor: "order_new",
      disableGlobalFilter: true,
    },
  ];

  const reorder = (
    rows: ISimpleAsset[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(rows);
    const total_items = result.length;
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    result.map((item, index) => {
      return (item.order_new = (index - total_items) * -1);
    });

    return result;
  };

  const updateIndexes = (result: TDropResult) => {
    const items = reorder(assets, result.source, result.destination);
    setAssets(items);
  };

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  const handleConfirmModify = () => {
    setIsLoading(true);
    const assetsDto = orderAssets(assets);

    assetsDto &&
      reorderAssets(assetsDto)
        .then(async () => {
          showNotification(
            `Reisinger | ${t("reisinger:common.nft_pieces")}`,
            t("core:notifications.reorder_success_message"),
            t("core:common.success")
          );

          history.push(NFT_PIECES);
        })
        .catch((error: { response: { data: { error: string } } }) => {
          showNotification(
            `Reisinger | ${t("reisinger:common.nft_pieces")} ${t(
              "core:common.delete"
            )}`,
            `${t("core:notifications.err_message")} ${
              error?.response?.data?.error
            }`,
            t("core:common.danger")
          );
        })
        .finally(() => {
          setIsLoading(false);
          handleModalStatus();
        });
  };

  useEffect(() => {
    setIsLoading(true);

    getAssets()
      .then(async (response: { data: IAsset[] }) => {
        setAssets(orderListAssets(response.data));
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    columns,
    modalStatus,
    isLoading,
    assets,
    handleModalStatus,
    handleConfirmModify,
    updateIndexes,
  };
};

export default useChangeOrder;
