import { IEditableArrayColumn } from "@interfaces/Shared/editableArrayColumn";
import AddIcon from "mdi-react/AddIcon";
import Button from "../Button";

interface IEditableArrayHeader<T> {
  rows: T[];
  columns: IEditableArrayColumn[];
  setNewArray: (newRows: T[]) => void;
  maxLength?: number;
  disabled?: boolean;
}

const EditableArrayHeader = <T extends Record<string, any>>({
  rows,
  columns,
  setNewArray,
  maxLength,
  disabled,
}: IEditableArrayHeader<T>) => {
  const addRow = () => {
    setNewArray([
      ...rows,
      Object.fromEntries(columns.map((c) => [c.accessor, ""])) as T,
    ]);
  };

  return (
    <div className="editable__array-header">
      {columns.map((z, k) => (
        <div key={`h-${k}`} className="editable__array-header__cell">
          {z.title}
        </div>
      ))}
      {!disabled && (
        <Button
          iconMdi={<AddIcon />}
          onClick={addRow}
          disabled={!!maxLength && rows.length >= maxLength}
        />
      )}
    </div>
  );
};

export default EditableArrayHeader;
