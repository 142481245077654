import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import useStates from "@mappers/Shared/getState.mapper";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import IFormProps from "@interfaces/Shared/formProps";
import LinkIcon from "mdi-react/LinkIcon";
import IClaimTraits from "@interfaces/Clients/Hackatao/claimTraits/claimTraits";
import TextLongIcon from "mdi-react/TextLongIcon";
import ButtonContainer from "@components/ButtonContainer";

interface IModifyForm extends IFormProps {
  data: IClaimTraits;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "Hackatao"]);
  const { GetState, GetStates } = useStates();
  const [status] = useState(GetState(data?.state));

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.name")}
            isAboveError
            initialValue={data?.name}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.connectText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="connectText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.connectText")}
            isAboveError
            initialValue={data?.connectText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.winnerText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="winnerText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.winnerText")}
            isAboveError
            initialValue={data?.winnerText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.notWinnerText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="notWinnerText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.notWinnerText")}
            isAboveError
            initialValue={data?.notWinnerText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.alreadyClaimedText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="alreadyClaimedText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.alreadyClaimedText")}
            isAboveError
            initialValue={data?.alreadyClaimedText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.loadingText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="loadingText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.loadingText")}
            isAboveError
            initialValue={data?.loadingText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.successText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="successText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.successText")}
            isAboveError
            initialValue={data?.successText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.traitsPerWallet")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="traitsPerWallet"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.traitsPerWallet")}
            isAboveError
            initialValue={data?.traitsPerWallet}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.whiteList")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <TextLongIcon />
          </div>
          <Field
            name="whitelist"
            component="textarea"
            type="text"
            placeholder={t("hackatao:common.whiteList")}
            isAboveError
            initialValue={data?.whitelist?.join("\n")}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.buttonText")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="buttonText"
            component={FormTextField}
            type="text"
            placeholder={t("hackatao:common.buttonText")}
            isAboveError
            initialValue={data?.buttonText}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.linkImageLeft")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkIcon />
          </div>
          <Field
            name="linkImageLeft"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.url_image")}
            isAboveError
            initialValue={data?.linkImageLeft}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("hackatao:common.linkImageRight")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkIcon />
          </div>
          <Field
            name="linkImageRight"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.url_image")}
            isAboveError
            initialValue={data?.linkImageRight}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
