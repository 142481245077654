import { useState, useContext } from "react";
import { Field, Form } from "react-final-form";
import { Link, useHistory, generatePath } from "react-router-dom";
import ModifyConfirmation from "@shared/Modals/ModifyConfirmation";
import PasswordField from "@easydev/form/Password";
import LockIcon from "mdi-react/LockIcon";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardBody,
  ButtonToolbar,
} from "reactstrap";
import updatePasswordValidate from "@validators/Security/Account/UpdatePassword.validate";
import { MY_PROFILE } from "@constants/routes/routes";
import { useTranslation } from "react-i18next";
import UserContext from "@context/UserContext";
import { showNotification } from "@easydev/Notification";
import updatePassword from "@services/Core/Account/updatePassword";
import updatePasswordAccountMapper from "@mappers/Security/Account/UpdatePasswordAccount.mapper";

const UpdatePassword = () => {
  const { currentUser } = useContext(UserContext);
  const [modalStatus, setModalStatus] = useState(false);
  const [formValues, setFormValues] = useState(undefined);
  const { t } = useTranslation("core");
  const history = useHistory();

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  const handleUpdatePassword = (FormValues) => {
    setFormValues(FormValues);
    handleModalStatus();
  };

  const handleConfirmModify = () => {
    const userDto = updatePasswordAccountMapper(formValues);

    currentUser &&
      updatePassword(currentUser.uuid, userDto)
        .then(async () => {
          showNotification(
            `${t("core:profile.my_profile")} | ${t("core:common.modify")} ${t(
              "core:common.password"
            )}`,
            t("core:notifications.user_edit_success"),
            t("core:common.success")
          );
          history.push(generatePath(MY_PROFILE));
        })
        .catch((error) => {
          showNotification(
            `${t("core:profile.my_profile")} | ${t("core:common.modify")}`,
            `${t("core:notifications.user_err")} ${
              error?.response?.data?.error
            }`,
            t("core:common.danger")
          );
        })
        .finally(() => {
          handleModalStatus();
        });
  };

  return (
    <Container>
      <ModifyConfirmation
        title={`${t("core:profile.my_profile")} | ${t(
          "core:common.modify_confirm"
        )}`}
        body={t("core:profile.p_modify_password")}
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirmModify={handleConfirmModify}
      />
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            <LockIcon />
            {t("core:profile.my_profile")} | {t("core:common.modify")}{" "}
            {t("core:common.password")}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={8}>
          <Card>
            <CardBody>
              <Form
                onSubmit={(FormValues) => {
                  handleUpdatePassword(FormValues);
                }}
                validate={updatePasswordValidate}
              >
                {({ handleSubmit }) => (
                  <form
                    className="form form--horizontal"
                    onSubmit={handleSubmit}
                  >
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.old_password")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="oldpassword"
                          component={PasswordField}
                          keyIcon
                          isAboveError
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.password")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="password"
                          component={PasswordField}
                          keyIcon
                          isAboveError
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.confirm")} {t("core:common.password")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="confirmpassword"
                          component={PasswordField}
                          keyIcon
                          isAboveError
                        />
                      </div>
                    </div>

                    <ButtonToolbar className="form__button-toolbar">
                      <Link to={MY_PROFILE}>
                        <Button type="button">
                          <span className="lnr lnr-chevron-left" />
                          {t("core:common.goback")}
                        </Button>
                      </Link>
                      <Button color="success" type="submit">
                        <span className="lnr lnr-pencil" />{" "}
                        {t("core:common.modify")}
                      </Button>
                    </ButtonToolbar>
                  </form>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdatePassword;
