import { Route, Switch } from "react-router-dom";
import Users from "@pages/Security/Users/ListUsers";
import CreateUser from "@pages/Security/Users/CreateUser";
import ModifyUser from "@pages/Security/Users/ModifyUser";
import UserDetail from "@pages/Security/Users/DetailUser";
import ListClients from "@pages/Security/Clients/ListClients";
import CreateClient from "@pages/Security/Clients/CreateClient";
import DetailClient from "@pages/Security/Clients/DetailClient";
import ModifyClient from "@pages/Security/Clients/ModifyClient";
import ListProjects from "@pages/Security/Projects/ListProjects";
import CreateProject from "@pages/Security/Projects/CreateProject";
import DetailProject from "@pages/Security/Projects/DetailProject";
import ModifyProject from "@pages/Security/Projects/ModifyProject";
import {
  USERS,
  USERS_CREATE,
  USERS_MODIFY,
  USER_DETAIL,
  CLIENTS,
  CLIENTS_CREATE,
  CLIENTS_DETAIL,
  CLIENTS_MODIFY,
  PROJECTS,
  PROJECTS_CREATE,
  PROJECTS_DETAIL,
  PROJECTS_MODIFY,
} from "@constants/routes/routes";

const Security = () => (
  <Switch>
    <Route exact path={CLIENTS} component={ListClients} />
    <Route exact path={CLIENTS_MODIFY} component={ModifyClient} />
    <Route exact path={CLIENTS_CREATE} component={CreateClient} />
    <Route exact path={CLIENTS_DETAIL} component={DetailClient} />

    <Route exact path={USERS} component={Users} />
    <Route exact path={USERS_CREATE} component={CreateUser} />
    <Route exact path={USERS_MODIFY} component={ModifyUser} />
    <Route exact path={USER_DETAIL} component={UserDetail} />

    <Route exact path={PROJECTS} component={ListProjects} />
    <Route exact path={PROJECTS_CREATE} component={CreateProject} />
    <Route exact path={PROJECTS_MODIFY} component={ModifyProject} />
    <Route exact path={PROJECTS_DETAIL} component={DetailProject} />
  </Switch>
);

export default Security;
