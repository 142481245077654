import { ISelect } from "@interfaces/Shared/select";
import { AORIST, OPEN_SEA } from "@constants/types";

interface IUseTypes {
  GetType: (assetContract: string) => ISelect;
  GetTypes: () => ISelect[];
}

const useTypes = (): IUseTypes => {
  const GetType = (type: string) => {
    if (type === OPEN_SEA) {
      return { label: OPEN_SEA, value: OPEN_SEA };
    }

    return { label: AORIST, value: AORIST };
  };

  const GetTypes = () => {
    return [
      { label: OPEN_SEA, value: OPEN_SEA },
      { label: AORIST, value: AORIST },
    ];
  };

  return { GetType, GetTypes };
};

export default useTypes;
