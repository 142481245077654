import { ReactElement } from "react";
import { Button, ButtonToolbar, Modal } from "reactstrap";

interface IModifyConfirmation {
  title: string;
  body: string;
  modalStatus?: boolean;
  handleModalStatus?: () => void;
  handleConfirmModify: () => void;
}

const ModifyConfirmation = ({
  title,
  body,
  modalStatus,
  handleModalStatus,
  handleConfirmModify,
}: IModifyConfirmation): ReactElement => (
  <Modal isOpen={modalStatus} className={`modal-dialog--warning`}>
    <div className="modal__header">
      <button
        className="lnr lnr-cross modal__close-btn"
        aria-label="modal__close-btn"
        type="button"
        onClick={handleModalStatus}
      />
      <h4 className="text-modal modal__title">
        <span className="lnr lnr-pencil modal__title-icon" />
        {title}
      </h4>
    </div>
    <div className="modal__body">{body}</div>
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel" onClick={handleModalStatus}>
        Cancel
      </Button>{" "}
      <Button
        className="modal_ok"
        color={"warning"}
        onClick={handleConfirmModify}
      >
        Ok
      </Button>
    </ButtonToolbar>
  </Modal>
);

export default ModifyConfirmation;
