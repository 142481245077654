import { API_URL_WHALESHARK_CALENDAR } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import ICalendarUpdate from "@interfaces/Clients/WhaleShark/calendar/calendarFormatted";

const updateItem = (uuid: string, editItem: ICalendarUpdate) =>
  apiInstance({
    url: API_URL_WHALESHARK_CALENDAR + uuid,
    method: "put",
    data: editItem,
  });

export default updateItem;
