import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import useStates from "@mappers/Shared/getState.mapper";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import IFormProps from "@interfaces/Shared/formProps";
import IGridAvatars from "@interfaces/Clients/Hackatao/gridAvatars/gridAvatars";
import LinkIcon from "mdi-react/LinkIcon";

interface IModifyForm extends IFormProps {
  data: IGridAvatars;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "Hackatao"]);
  const { GetState, GetStates } = useStates();
  const [status] = useState(GetState(data?.state));

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="title"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.title")}
            isAboveError
            initialValue={data?.title}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkIcon />
          </div>
          <Field
            name="linkImage"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.url_image")}
            isAboveError
            initialValue={data?.linkImage}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
