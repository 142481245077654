import { typeSocial } from "@constants/enums/shared/enum";

export const createSocialsArray = (
  instagram: string,
  twitter: string,
  discord: string
) => {
  const initialArray = [
    { type: typeSocial.instagram, link: instagram },
    { type: typeSocial.twitter, link: twitter },
    { type: typeSocial.discord, link: discord },
  ];

  const socialsArray = initialArray.filter((x) => x.link);

  return socialsArray.length > 0 ? socialsArray : undefined;
};
