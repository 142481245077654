import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { NavLink } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";
import renderCheckBoxField from "@easydev/form/CheckBox";
import PasswordField from "@easydev/form/Password";
import FormField from "@easydev/form/FormField";
import { FORGOT_PASSWORD } from "@constants/routes/routes";
import loginValidate from "@validators/Security/Account/LogIn.validate";

const LogInForm = ({ onSubmit, errorMessage }) => (
  <Form onSubmit={onSubmit} validate={loginValidate}>
    {({ handleSubmit }) => (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Mail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AlternateEmailIcon />
            </div>
            <Field
              name="email"
              component={FormField}
              type="email"
              placeholder={"example@mail.com"}
              isAboveError
              data-testid="email-input"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={PasswordField}
              placeholder={"password"}
              className="input-without-border-radius"
              keyIcon
              isAboveError
              data-testid="password-input"
            />
            <div className="account__forgot-password">
              <NavLink to={FORGOT_PASSWORD} data-testid="forgot-password-link">
                {"Forgot your password?"}
              </NavLink>
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label={"Remember me"}
              type="checkbox"
              data-testid="remember-me-check"
            />
          </div>
        </div>
        <Alert
          className="account__btn"
          color="danger"
          isOpen={!!errorMessage}
          data-testid="alert"
        >
          <AlertCircleOutlineIcon /> {errorMessage}
        </Alert>
        <Button
          className="account__btn"
          type="submit"
          color="primary"
          data-testid="sign-in-btn"
        >
          {"Sign In"}
        </Button>
      </form>
    )}
  </Form>
);

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

LogInForm.defaultProps = {
  errorMessage: "",
};

export default LogInForm;
