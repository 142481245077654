import { ReactElement, MouseEventHandler, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import SidebarLink from "../SidebarLink";
import SidebarCategory from "../SidebarCategory";
import PropTypes from "prop-types";
import { USERS, LOGOUT, CLIENTS, PROJECTS } from "@constants/routes/routes";
import { TUserContext } from "types/Core/Authentication/userContext";
import UserContext from "@context/UserContext";
import { IsValidResource } from "@helpers/resources.access";
import { KEY_PROJECT } from "@constants/env";
import Modules from "./Modules/Modules";

interface ISidebarContent {
  onClick: MouseEventHandler<HTMLAnchorElement>;
  sidebarCollapse: boolean;
}

const SidebarContent = ({
  onClick,
  sidebarCollapse,
}: ISidebarContent): ReactElement => {
  const { HandleLogout } = useContext<TUserContext>(UserContext);
  const { t } = useTranslation("core");
  const [isOpen, setIsOpen] = useState("");

  return (
    <div className="sidebar__content">
      <Modules
        onClick={onClick}
        sidebarCollapse={sidebarCollapse}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {IsValidResource(KEY_PROJECT) && (
        <ul className="sidebar__block">
          <SidebarCategory
            icon={"lock"}
            title={t("navigation.security")}
            sidebarCollapse={sidebarCollapse}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("navigation.users")}
              route={USERS}
              onClick={onClick}
              dataTestId="security-users"
            />
            <SidebarLink
              title={t("core:common.clients")}
              route={CLIENTS}
              onClick={onClick}
              dataTestId="security-clients"
            />
            <SidebarLink
              title={t("core:common.projects")}
              route={PROJECTS}
              onClick={onClick}
              dataTestId="security-projects"
            />
          </SidebarCategory>
        </ul>
      )}

      <ul className="sidebar__block">
        <SidebarLink
          title={t("navigation.log_out")}
          icon="exit"
          onClick={HandleLogout}
          route={LOGOUT}
          dataTestId="log-out"
        />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
