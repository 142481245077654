import { API_URL_WHALESHARK_PARTNER } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditPartner from "types/Clients/WhaleShark/partner/createEditPartner";

const updateItem = (uuid: string, editedItem: TCreateEditPartner) =>
  apiInstance({
    url: API_URL_WHALESHARK_PARTNER + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
