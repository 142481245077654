import { timelineCategory } from "./enum";

export const TIMELINE_CATEGORIES = [
  {
    label: "Launches",
    value: timelineCategory.launches,
  },
  {
    label: "Press",
    value: timelineCategory.press,
  },
  {
    label: "Milestones",
    value: timelineCategory.milestones,
  },
  {
    label: "irl Events",
    value: timelineCategory.irlEvents,
  },
  {
    label: "Movies",
    value: timelineCategory.movies,
  },
];
