import { KEY_STORE_DRAFT } from "@constants/backofficeDraft";
import { RawDraftContentState } from "draft-js";

export const createDraft = (rawContent: RawDraftContentState) => {
  localStorage.setItem(KEY_STORE_DRAFT, JSON.stringify(rawContent));
};

export const getDraft = () => {
  return localStorage.getItem(KEY_STORE_DRAFT);
};

export const removeDraft = () => {
  localStorage.removeItem(KEY_STORE_DRAFT);
};
