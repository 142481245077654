import { API_URL_WHALESHARK_GLANCE } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TGlanceItem from "types/Clients/WhaleShark/glance/glanceApi";

const updateItem = (uuid: string, editedItem: TGlanceItem) =>
  apiInstance({
    url: API_URL_WHALESHARK_GLANCE + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
