import IGridAvatars from "@interfaces/Clients/Hackatao/gridAvatars/gridAvatars";
import {
  GRIDAVATARS_DETAIL,
  GRIDAVATARS_MODIFY,
} from "@constants/routes/Clients/hackatao.routes";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listGridAvatarsMapper = (
  dataFromApi: IGridAvatars[],
  handleDelete: Function
) => {
  const listGridAvatars = dataFromApi.map(
    ({ uuid, title, createdAt, updatedAt, state }, itemIndex) => ({
      uuid,
      order: itemIndex + 1,
      title,
      createdAt: timestampToString(createdAt),
      updatedAt: timestampToString(updatedAt),
      state: <BoxStatus state={state} />,
      actions: (
        <BoxListButton
          uuid={uuid}
          description={title}
          detailRoute={GRIDAVATARS_DETAIL}
          modifyRoute={GRIDAVATARS_MODIFY}
          handleDelete={handleDelete}
        />
      ),
    })
  );

  return listGridAvatars;
};

export default listGridAvatarsMapper;
