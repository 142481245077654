import { Route, Switch } from "react-router-dom";
import DetailClaimTrait from "@pages/Clients/Hackatao/claimTrait/DetailClaimTrait";
import ListClaimTrait from "@pages/Clients/Hackatao/claimTrait/ListClaimTrait/ListItem";
import CreateClaimTrait from "@pages/Clients/Hackatao/claimTrait/CreateClaimTrait";
import ModifyClaimTrait from "@pages/Clients/Hackatao/claimTrait/ModifyClaimTrait";
import LearnMore from "@pages/Clients/Hackatao/learnMore";
import {
  CLAIMTRAIT_DETAIL,
  CLAIMTRAIT,
  CLAIMTRAIT_CREATE,
  CLAIMTRAIT_MODIFY,
  LEARNMORE,
  GRIDAVATARS,
  GRIDAVATARS_CREATE,
  GRIDAVATARS_MODIFY,
  GRIDAVATARS_DETAIL,
  MAINAVATARS,
  MAINAVATARS_DETAIL,
  MAINAVATARS_CREATE,
  MAINAVATARS_MODIFY,
} from "@constants/routes/Clients/hackatao.routes";
import ListGridAvatars from "@pages/Clients/Hackatao/gridAvatars/ListGridAvatars/ListItem";
import CreateGridAvatars from "@pages/Clients/Hackatao/gridAvatars/CreateGridAvatars";
import ModifyGridAvatars from "@pages/Clients/Hackatao/gridAvatars/ModifyGridAvatars";
import DetailMainAvatars from "@pages/Clients/Hackatao/mainAvatars/DetailMainAvatars";
import DetailGridAvatars from "@pages/Clients/Hackatao/gridAvatars/DetailGridAvatars/DetailItem";
import ListMainAvatars from "@pages/Clients/Hackatao/mainAvatars/ListMainAvatars";
import CreateMainAvatars from "@pages/Clients/Hackatao/mainAvatars/CreateMainAvatars";
import ModifyMainAvatars from "@pages/Clients/Hackatao/mainAvatars/ModifyMainAvatars";

const HackataoModules = () => (
  <Switch>
    <Route exact path={CLAIMTRAIT} component={ListClaimTrait} />
    <Route exact path={CLAIMTRAIT_DETAIL} component={DetailClaimTrait} />
    <Route exact path={CLAIMTRAIT_CREATE} component={CreateClaimTrait} />
    <Route exact path={CLAIMTRAIT_MODIFY} component={ModifyClaimTrait} />
    <Route exact path={LEARNMORE} component={LearnMore} />
    <Route exact path={GRIDAVATARS} component={ListGridAvatars} />
    <Route exact path={GRIDAVATARS_CREATE} component={CreateGridAvatars} />
    <Route exact path={GRIDAVATARS_MODIFY} component={ModifyGridAvatars} />
    <Route exact path={GRIDAVATARS_DETAIL} component={DetailGridAvatars} />
    <Route exact path={MAINAVATARS} component={ListMainAvatars} />
    <Route exact path={MAINAVATARS_DETAIL} component={DetailMainAvatars} />
    <Route exact path={MAINAVATARS_CREATE} component={CreateMainAvatars} />
    <Route exact path={MAINAVATARS_MODIFY} component={ModifyMainAvatars} />
  </Switch>
);

export default HackataoModules;
