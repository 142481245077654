import { ReactElement, useState } from "react";
import { NavLink, useHistory, generatePath, useParams } from "react-router-dom";
import ResetPasswordConfirmForm from "@easydev/reset_password_confirm/ResetPasswordConfirmForm";
import resetPasswordValidate from "@validators/Security/Account/ResetPassword.validate";
import { LOGIN, RESET_PASSWORD_CONFIRM } from "@constants/routes/routes";
import logo from "@images/shared/logo.png";
import { useTranslation } from "react-i18next";
import Loader from "@components/Loader";
import resetPasswordConfirm from "@services/Core/Account/resetPasswordConfirm";

const ResetPassword = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const { token, uuid } = useParams<{ token: string; uuid: string }>();
  const { t } = useTranslation(["core"]);

  const handleResetPassword = (FormValues: Record<string, any>) => {
    setIsLoading(true);

    resetPasswordConfirm(token, uuid, FormValues.password)
      .then(async (result) => {
        history.push(generatePath(RESET_PASSWORD_CONFIRM));
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <img src={logo} alt="logo" />
            </h3>
          </div>
          {isLoading ? (
            <Loader isInsideThing />
          ) : (
            <ResetPasswordConfirmForm
              onSubmit={(FormValues) => {
                handleResetPassword(FormValues);
              }}
              validate={resetPasswordValidate}
              errorMessage={errorMessage}
            />
          )}

          <div className="account__have-account">
            <p>
              {t("core:profile.remember_password")}{" "}
              <NavLink to={LOGIN}>{t("core:profile.login")}</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
