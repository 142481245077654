import { NavLink } from "react-router-dom";
import { Form, Field } from "react-final-form";
import PasswordField from "@easydev/form/Password";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";
import { Alert, Button } from "reactstrap";
import PropTypes from "prop-types";
import lockScreenValidate from "@validators/Security/Account/LockScreen.validate";
import { LOGOUT } from "@constants/routes/routes";
import { useTranslation } from "react-i18next";

const LockScreenForm = ({ onSubmit, errorMessage }) => {
  const { t } = useTranslation("core");

  return (
    <Form onSubmit={onSubmit} validate={lockScreenValidate}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group form__form-group--forgot">
            <span className="form__form-group-label">
              {t("core:common.password")}
            </span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={PasswordField}
                placeholder={t("core:common.password")}
                className="input-without-border-radius"
                keyIcon
                isAboveError
              />
            </div>
          </div>
          <Alert
            className="account__btn"
            color="danger"
            isOpen={!!errorMessage}
          >
            <AlertCircleOutlineIcon /> {errorMessage}
          </Alert>
          <Button className="account__btn" color="primary" type="submit">
            {t("core:common.unlock")}
          </Button>
          <NavLink className="btn btn-outline-danger account__btn" to={LOGOUT}>
            {t("core:navigation.log_out")}
          </NavLink>
        </form>
      )}
    </Form>
  );
};

LockScreenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

LockScreenForm.defaultProps = {
  errorMessage: "",
};

export default LockScreenForm;
