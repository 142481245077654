import { t } from "i18next";

const priceValidate = (price: number) => {
  let errMsg = undefined;

  if (price && price <= 0) {
    errMsg = t("core:validators:price-greater-than");
  }

  return errMsg;
};

export default priceValidate;
