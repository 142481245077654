import { useState } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import CalendarRangeIcon from "mdi-react/CalendarRangeIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import BookmarkBoxMultipleIcon from "mdi-react/BookmarkBoxMultipleIcon";
import EthereumIcon from "mdi-react/EthereumIcon";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import DatePickerField from "@components/Shared/FormDatePickerField";
import Condition from "@easydev/form/Condition";
import { AORIST, OPEN_SEA } from "@constants/types";
import "react-datepicker/dist/react-datepicker.css";
import useStates from "@mappers/Shared/getState.mapper";
import { useTranslation } from "react-i18next";
import { IAsset } from "@interfaces/Clients/Reisinger/asset";
import IFormProps from "@interfaces/Shared/formProps";
import "./ModifyForm.scss";

interface IModifyForm extends IFormProps {
  data: IAsset;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm) => {
  const { t } = useTranslation(["core", "reisinger"]);
  const { GetState, GetStates } = useStates();
  const [initialStateValue] = useState(GetState(data?.config.show));

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.type")}</span>
        <div className="form__form-group-field">
          <Field
            name="type"
            component="input"
            type="text"
            initialValue={data?.config?.type ?? OPEN_SEA}
            disabled
          />
        </div>
      </div>

      <Condition when="type" is={OPEN_SEA}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.name")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component="input"
              type="text"
              initialValue={data?.name}
              disabled
            />
          </div>
        </div>
      </Condition>

      <Condition when="type" is={OPEN_SEA}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.link")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="url"
              component="input"
              type="text"
              initialValue={data?.permalink}
              disabled
            />
            <a href={data?.permalink} target="_blank" rel="noopener noreferrer">
              <div className="form__form-group-icon">
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>
      </Condition>

      <Condition when="type" is={AORIST}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.title")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="title"
              component={FormTextField}
              type="text"
              initialValue={data?.name}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <Condition when="type" is={AORIST}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.page_link")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="pageLink"
              component={FormTextField}
              type="text"
              initialValue={data?.permalink}
              isAboveError
            />
            <a href={data?.permalink} target="_blank" rel="noopener noreferrer">
              <div className="form__form-group-icon">
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>
      </Condition>

      <Condition when="type" is={AORIST}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.piece_link")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="pieceLink"
              component={FormTextField}
              type="text"
              initialValue={data?.image_url}
              isAboveError
            />
            <a href={data?.image_url} target="_blank" rel="noopener noreferrer">
              <div className="form__form-group-icon">
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>
      </Condition>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.release_date")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarRangeIcon />
          </div>

          <Field
            name="releaseDate"
            component={DatePickerField}
            type="text"
            isAboveError
            initialValue={data?.config.release_date}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.edition")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <BookmarkBoxMultipleIcon />
          </div>
          <Field
            name="edition"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.edition")}
            initialValue={data?.config.description}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.price")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <EthereumIcon />
          </div>
          <Field
            name="price"
            component={FormTextField}
            type="number"
            initialValue={data?.config.price}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.order")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OrderNumericAscendingIcon />
          </div>
          <Field
            name="order"
            component={FormTextField}
            type="number"
            initialValue={data?.config.order}
            min={0}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={initialStateValue}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
