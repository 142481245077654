import { ReactElement } from "react";
import { Col } from "reactstrap";
import ButtonContainer from "@components/ButtonContainer";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import TextLongIcon from "mdi-react/TextLongIcon";
import ImageFilterDramaIcon from "mdi-react/ImageFilterDramaIcon";
import BookmarkBoxMultipleIcon from "mdi-react/BookmarkBoxMultipleIcon";
import { useTranslation } from "react-i18next";
import BoxStatus from "@components/BoxStatus";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import IHotv from "@interfaces/Clients/WhaleShark/hotv/hotv";
import BoxImage from "@components/BoxImage";

interface IDetailForm {
  data: IHotv;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);

  return (
    <form className="form">
      <Col md={5}>{data && <BoxImage urlImage={data.imageUrl} />}</Col>

      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.title")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <FormatTitleIcon />
            </div>
            <input type="text" value={data?.title} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.collection_name")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <BookmarkBoxMultipleIcon />
            </div>
            <input type="text" value={data?.collectionName} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.description")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <TextLongIcon />
            </div>
            <textarea value={data?.description} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.url_image")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <ImageFilterDramaIcon />
            </div>
            <input type="text" value={data?.imageUrl} disabled />
            <a href={data?.imageUrl} target="_blank" rel="noopener noreferrer">
              <div className="form__form-group-icon">
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.opensea_url")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <input type="text" value={data?.openSeaUrl} disabled />
            <a
              href={data?.openSeaUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="form__form-group-icon">
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.state ?? true} />
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
