import { API_URL } from "@constants/env";
import axios from "axios";

const resetPasswordConfirm = (token: string, uuid: String, password: String) =>
  axios({
    baseURL: API_URL,
    url: "/users/reset-password",
    method: "put",
    data: { uuid: uuid, token: token, password: password },
  });

export default resetPasswordConfirm;
