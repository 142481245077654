import { ReactElement } from "react";
import Modify from "@shared/FormTemplates/Modify";
import ModifyForm from "./ModifyForm";
import updateItem from "@services/Core/ProjectManager/updateProject";
import useGetDetailItem from "@hooks/Security/Projects/useGetProject";
import validator from "@validators/Security/Projects/project.validate";
import formMapper from "@mappers/Security/Projects/Project.mapper";

const ModifyItem = (): ReactElement => (
  <Modify
    CustomForm={ModifyForm}
    modifyHook={updateItem}
    detailHook={useGetDetailItem}
    validator={validator}
    formMapper={formMapper}
  />
);

export default ModifyItem;
