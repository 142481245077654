import { API_URL_WHALESHARK_REPORT } from "@constants/Clients/whaleshark/whaleshark.env";
import IReport from "@interfaces/Clients/WhaleShark/report/report";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetReport = (uuid: string) =>
  useApiCall<IReport>({
    url: API_URL_WHALESHARK_REPORT + uuid,
  });

export default useGetReport;
