import { ISelect } from "@interfaces/Shared/select";
import { IProject } from "@interfaces/Core/Project/project";
import apiInstance from "@services/Shared/instanceAxios";

const selectProjects = (clientId: string) =>
  apiInstance({
    url: "/projects/",
    params: { clientId },
    transformResponse: [
      (data) => {
        let projectValues: ISelect[] = [];
        const resp = JSON.parse(data);
        resp.map((project: IProject) =>
          projectValues.push({
            value: project.uuid,
            label: project.name,
          })
        );
        return projectValues;
      },
    ],
  });

export default selectProjects;
