import { API_URL_HACKATAO_GRID_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TGridAvatarsItem } from "types/Clients/Hackatao/gridAvatars/TGridAvatarsItem";

const updateItem = (uuid: string, editedItem: TGridAvatarsItem) =>
  apiInstance({
    url: API_URL_HACKATAO_GRID_AVATARS + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
