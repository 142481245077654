import IBenefitApi from "@interfaces/Clients/WhaleShark/benefits/benefit";
import {
  BENEFIT_MODIFY,
  BENEFIT_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const list = (listFromApi: IBenefitApi[], handleDelete: Function) =>
  listFromApi.map(({ uuid, title, createdAt, updatedAt, state }, index) => ({
    order: index + 1,
    uuid,
    title,
    createdAt: timestampToString(createdAt),
    updatedAt: timestampToString(updatedAt),
    state: <BoxStatus state={state} />,
    actions: (
      <BoxListButton
        uuid={uuid}
        description={title}
        detailRoute={BENEFIT_DETAIL}
        modifyRoute={BENEFIT_MODIFY}
        handleDelete={handleDelete}
      />
    ),
  }));

export default list;
