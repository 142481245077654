import React, { memo, useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { emojiArray } from "@helpers/emojis";
import { debounce } from "debounce";
import { createDraft } from "@helpers/draft";

const DEBOUNCE_TIME = 400;

const ToolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "image",
    "history",
    "emoji",
  ],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough", "monospace"],
  },
  colorPicker: {
    colors: [
      "rgb(199,151,79)",
      "rgb(209,213,216)",
      "rgb(212,175,55)",
      "rgb(150,48,90)",
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)",
    ],
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Montserrat",
      "Stolzl",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
  },
  emoji: {
    emojis: emojiArray,
  },
};

interface ITextEditor {
  input: any;
  props?: {
    draftEditorState: EditorState;
  };
}

const TextEditorTwo = memo(({ input, props }: ITextEditor) => {
  let draftEditorState: EditorState | undefined = undefined;

  if (props) {
    draftEditorState = props.draftEditorState;
  }

  const blocksFromHtml = htmlToDraft(input.value);
  const { contentBlocks, entityMap } = blocksFromHtml;

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    )
  );

  useEffect(() => {
    if (draftEditorState) {
      setEditorState(draftEditorState);
    }
  }, [draftEditorState]);

  const storeDraft = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    createDraft(rawContent);
  };

  const debounceDraftSaving = debounce(() => storeDraft(), DEBOUNCE_TIME);

  const onEditorStateChange = (items: EditorState) => {
    setEditorState(items);
    input.onChange(draftToHtml(convertToRaw(items.getCurrentContent())));
    debounceDraftSaving();
  };

  return (
    <div className="text-editor">
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={ToolbarOptions}
      />
    </div>
  );
});

export default TextEditorTwo;
