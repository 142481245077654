import { ReactElement, useState } from "react";
import { Field, useFormState } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import FormTextField from "@components/Shared/FormTextField";
import { useTranslation } from "react-i18next";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import DatePickerField from "@components/Shared/FormDatePickerField";
import IFormProps from "@interfaces/Shared/formProps";
import useStates from "@mappers/Shared/getState.mapper";
import renderSelectField from "@easydev/form/Select";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import ITimeline from "@interfaces/Clients/RocketFactory/timeline/timeline";
import { TIMELINE_CATEGORIES } from "@constants/Clients/rocketFactory/timeline";
import useGetSocials from "@hooks/Clients/RocketFactory/timeline/useGetSocials";
import TwitterIcon from "mdi-react/TwitterIcon";
import InstagramIcon from "mdi-react/InstagramIcon";
import DiscordIcon from "mdi-react/DiscordIcon";
import { typeSocial } from "@constants/enums/shared/enum";
import Condition from "@components/EasyDev/form/Condition";
import { timelineCategory } from "@constants/Clients/rocketFactory/enum";
import useFormRules from "@hooks/Clients/RocketFactory/timeline/useFormRules";
import TextLongIcon from "mdi-react/TextLongIcon";
import FormatListBulletedTypeIcon from "mdi-react/FormatListBulletedTypeIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import { getTimelineCategory } from "@helpers/Clients/RocketFactory/getTimelineCategory";
import FormTextareaField from "@components/Shared/FormTextareaField/FormTextareaField";
import { timestampToDate } from "@helpers/dates.format";

interface IModifyForm extends IFormProps {
  data: ITimeline;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "rocketFactory"]);
  const { GetState, GetStates } = useStates();
  const [status] = useState(GetState(data?.state));
  const [publishDate] = useState(timestampToDate(data?.date));
  const [closingDate] = useState(timestampToDate(data?.dateEnd));
  const initialCategory = getTimelineCategory(data?.category);
  const { socialInstagram, socialTwitter, socialDiscord } = useGetSocials(data);
  const { values } = useFormState();
  const { showDescription, showLinkImage, showLink } = useFormRules(values);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.category")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatListBulletedTypeIcon />
          </div>
          <Field
            name="category"
            component={renderSelectField}
            type="text"
            options={TIMELINE_CATEGORIES}
            initialValue={initialCategory}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <ViewHeadlineIcon />
          </div>
          <Field
            name="title"
            component={FormTextField}
            type="text"
            initialValue={data?.title}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.publish_date")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarMonthIcon />
          </div>
          <Field
            name="publishDate"
            component={DatePickerField}
            initialValue={publishDate}
            type="text"
            isAboveError
          />
        </div>
      </div>

      <Condition when="category.value" is={timelineCategory.irlEvents}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.closing_date")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarMonthIcon />
            </div>
            <Field
              name="closingDate"
              component={DatePickerField}
              initialValue={closingDate}
              type="text"
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <Condition when="category.value" is={timelineCategory.press}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.subtitle")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <ViewHeadlineIcon />
            </div>
            <Field
              name="subtitle"
              component={FormTextField}
              type="text"
              initialValue={data?.subtitle}
              placeholder="My Timeline Subtitle"
              isAboveError
            />
          </div>
        </div>
      </Condition>

      {showLinkImage && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.image_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="linkImage"
              component={FormTextField}
              type="text"
              initialValue={data?.image}
              placeholder={`${t("core:common.add_link_placeholder")} ${t(
                "core:common.image_link"
              )}`}
              isAboveError
            />
          </div>
        </div>
      )}

      <Condition when="category.value" is={timelineCategory.movies}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.video_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="linkVideo"
              component={FormTextField}
              type="text"
              initialValue={data?.linkVideo}
              placeholder={`${t("core:common.select_placeholder")} ${t(
                "core:common.video_link"
              )}`}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      {showLink && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.external_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="link"
              component={FormTextField}
              type="text"
              initialValue={data?.link ?? undefined}
              placeholder={`${t("core:common.add_link_placeholder")} ${t(
                "core:common.external_link"
              )}`}
              isAboveError
            />
          </div>
        </div>
      )}

      {showDescription && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.description")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <TextLongIcon />
            </div>
            <Field
              name="body"
              component={FormTextareaField}
              initialValue={data?.description}
              placeholder="Type your description here."
              isAboveError
            />
          </div>
        </div>
      )}

      <Condition when="category.value" is={timelineCategory.irlEvents}>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <InstagramIcon />
            </div>
            <Field
              name="instagram"
              component={FormTextField}
              type="text"
              initialValue={socialInstagram?.link}
              placeholder={`${t("core:common.add_link_placeholder")} ${
                typeSocial.instagram
              }`}
              isAboveError
            />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.socials")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <TwitterIcon />
            </div>
            <Field
              name="twitter"
              component={FormTextField}
              type="text"
              initialValue={socialTwitter?.link}
              placeholder={`${t("core:common.add_link_placeholder")} ${
                typeSocial.twitter
              }`}
              isAboveError
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <DiscordIcon />
            </div>
            <Field
              name="discord"
              component={FormTextField}
              type="text"
              initialValue={socialDiscord?.link}
              placeholder={`${t("core:common.add_link_placeholder")} ${
                typeSocial.discord
              }`}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <EyeOutlineIcon />
          </div>
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
