import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AxiosPromise } from "axios";
import TObjectFilledWithStrings from "types/Shared/objectFilledWithStrings";
import { EUiStates } from "@constants/enum";
import { showNotification } from "@easydev/Notification";

type THook = (uuid: string) => AxiosPromise<any>;

interface IUseDeleteItem {
  isModalOpen: boolean;
  isDeleting: boolean;
  handleDelete: (APIDelete: THook) => void;
  handleIsModalOpen: () => void;
}

const useDeleteItem = (
  basicData: TObjectFilledWithStrings | undefined
): IUseDeleteItem => {
  const { t } = useTranslation(["core"]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");

  const handleDelete = (APIDelete: THook) => {
    setIsDeleting(true);

    APIDelete(itemToDelete)
      .then(() => {
        setIsModalOpen(!isModalOpen);
        setIsDeleting(false);
        showNotification(
          t("core:common.success"),
          t("core:notifications.delete_success"),
          EUiStates.SUCCESS
        );
      })
      .catch((error) => {
        showNotification(
          "Error",
          `${t("core:notifications.delete_error")} | ${error}`,
          EUiStates.DANGER
        );
      });
  };

  const handleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (basicData?.uuid) {
      setItemToDelete(basicData?.uuid);
      handleIsModalOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicData]);

  return {
    isModalOpen,
    isDeleting,
    handleDelete,
    handleIsModalOpen,
  };
};

export default useDeleteItem;
