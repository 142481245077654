export const HOME = "/";
export const LOGIN = "/log-in";
export const LOGOUT = "/log-out";
export const ACCOUNT = "/account";
export const DASHBOARD = `${ACCOUNT}/dashboard`;
export const MY_PROFILE = `${ACCOUNT}/profile`;
export const UPDATE_PASSWORD = `${ACCOUNT}/update-password`;
export const FORGOT_PASSWORD = `${ACCOUNT}/forgot-password`;
export const RESET_PASSWORD = `${ACCOUNT}/reset-password/:token/:uuid`;
export const RESET_PASSWORD_CONFIRM = `${ACCOUNT}/reset-password-confirmation`;
export const PAGE404 = "/404";
export const PAGE401 = "/401";
export const LOCKSCREEN = "/lock-screen";
export const SECURITY = "/security";
export const USERS = `${SECURITY}/users/list`;
export const USERS_CREATE = `${USERS}/create`;
export const USERS_MODIFY = `${USERS}/:uuid/modify`;
export const USER_DETAIL = `${USERS}/:uuid/detail`;
export const CLIENTS = `${SECURITY}/clients/list`;
export const CLIENTS_CREATE = `${CLIENTS}/create`;
export const CLIENTS_MODIFY = `${CLIENTS}/:uuid/modify`;
export const CLIENTS_DETAIL = `${CLIENTS}/:uuid/detail`;
export const PROJECTS = `${SECURITY}/projects/list`;
export const PROJECTS_CREATE = `${PROJECTS}/create`;
export const PROJECTS_MODIFY = `${PROJECTS}/:uuid/modify`;
export const PROJECTS_DETAIL = `${PROJECTS}/:uuid/detail`;
export const MODULES = "/modules";
