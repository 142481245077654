import { IEditableArrayColumn } from "@interfaces/Shared/editableArrayColumn";
import RemoveIcon from "mdi-react/RemoveIcon";
import Button from "../Button";
import Cell from "../Cell";

interface IRow<T extends Record<string, any>> {
  columns: IEditableArrayColumn[];
  index: number;
  item: T;
  updateData: (itemIndex: number, field: string, value: unknown) => void;
  deleteRow: (itemIndex: number) => void;
  disabled?: boolean;
}

const Row = <T extends Record<string, any>>({
  columns,
  index,
  item,
  updateData,
  deleteRow,
  disabled,
}: IRow<T>) => {
  return (
    <div className="editable__array-row">
      {columns.map((y, j) => (
        <Cell
          key={`${y.accessor}-${index}`}
          index={index}
          column={y}
          updateEditableData={updateData}
          disabled={disabled}
          initialValue={item[y.accessor]}
        />
      ))}
      {!disabled && (
        <Button iconMdi={<RemoveIcon />} onClick={() => deleteRow(index)} />
      )}
    </div>
  );
};

export default Row;
