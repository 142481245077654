import { Link, generatePath } from "react-router-dom";
import { CLIENTS_MODIFY, CLIENTS_DETAIL } from "@constants/routes/routes";
import { Button } from "reactstrap";
import IClient from "@interfaces/Core/ClientManager/client";
import { timestampToString } from "@helpers/dates.format";

const listClient = (items: IClient[], handleDelete: Function) => {
  const listAsset = items.map(
    ({ uuid, name, key, createdAt, updatedAt }, index) => ({
      uuid,
      order: index + 1,
      name,
      key,
      createdAt: timestampToString(createdAt),
      updatedAt: timestampToString(updatedAt),
      actions: (
        <>
          <Link to={generatePath(CLIENTS_DETAIL, { uuid })}>
            <Button className="btn-sm btn-primary">
              <span className="lnr lnr-eye" />
            </Button>
          </Link>
          <Link to={generatePath(CLIENTS_MODIFY, { uuid })}>
            <Button className="btn-sm btn-warning">
              <span className="lnr lnr-pencil" />
            </Button>
          </Link>
          <Button
            className="btn-sm btn-danger"
            onClick={() => {
              handleDelete({
                uuid,
                title: name,
              });
            }}
          >
            <span className="lnr lnr-trash" />
          </Button>
        </>
      ),
    })
  );

  return listAsset;
};

export default listClient;
