import { ReactElement } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import AccountConvertIcon from "mdi-react/AccountConvertIcon";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import { useTranslation } from "react-i18next";
import {
  GetCategory,
  GetCategories,
} from "@mappers/Clients/WhaleShark/partner/getCategory.mapper";
import IFormProps from "@interfaces/Shared/formProps";

const CreateForm = ({ handleSubmit }: IFormProps): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);
  const initialStateValue = GetCategory(1);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder={`${t("core:common.name")} ${t(
              "whaleshark:common.partner"
            )}`}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.category")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountConvertIcon />
          </div>
          <Field
            name="category"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.category"
            )}`}
            options={GetCategories()}
            initialValue={initialStateValue}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.order")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OrderNumericAscendingIcon />
          </div>
          <Field name="order" component="input" type="number" />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <Field
            name="imageUrl"
            component={FormTextField}
            type="text"
            placeholder="https://...partnerImage.png"
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateForm;
