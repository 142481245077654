import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import styles from "./CreateButton.module.scss";

const CreateButton = (): ReactElement => {
  const { t } = useTranslation(["core"]);
  const { pathname } = useLocation();

  return (
    <div className={styles.btnContainer}>
      <Link to={`${pathname}/create`}>
        <Button color="success">{t("core:common.create")}</Button>
      </Link>
    </div>
  );
};

export default CreateButton;
