import { API_URL_HACKATAO_GRID_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TGridAvatarsItem } from "types/Clients/Hackatao/gridAvatars/TGridAvatarsItem";

const createItem = (newGridAvatar: TGridAvatarsItem) =>
  apiInstance({
    url: API_URL_HACKATAO_GRID_AVATARS,
    method: "post",
    data: newGridAvatar,
  });

export default createItem;
