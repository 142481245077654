import { API_URL_WHALESHARK_HIGHLIGHTS } from "@constants/Clients/whaleshark/whaleshark.env";
import IHotv from "@interfaces/Clients/WhaleShark/hotv/hotv";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetHotv = (uuid: string) =>
  useApiCall<IHotv>({
    url: API_URL_WHALESHARK_HIGHLIGHTS + uuid,
  });

export default useGetHotv;
