import { EN } from "@constants/countryCodes";
import resources from "./resources";

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: EN,
  fallbackLng: EN,
  resources,
};

export { resources };
