import { useState, ReactElement } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useTranslation } from "react-i18next";
import useSelectProjects from "@hooks/Security/Projects/useSelectProjects";
import useSelectClients from "@hooks/Security/Clients/useSelectClients";
import renderMultiSelectField from "@easydev/form/MultiSelect";
import renderSelectField from "@easydev/form/Select";
import FormTextField from "@components/Shared/FormTextField";
import selectProjectMapper from "@mappers/Security/Projects/selectProject.mapper";
import selectClientMapper from "@mappers/Security/Clients/selectClient.mapper";
import useStates from "@mappers/Shared/getState.mapper";
import AccountBoxOutlineIcon from "mdi-react/AccountBoxOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import { IUserDetail } from "@interfaces/Core/Users/userDetail";
import IFormProps from "@interfaces/Shared/formProps";
import ButtonContainer from "@components/ButtonContainer";
import useSuperusers from "@mappers/Shared/getSuperuser.mapper";
import Condition from "@components/EasyDev/form/Condition";
import WhenFieldChanges from "@components/Shared/FormTemplates/WhenFieldChanges";
import {
  backofficeSelection,
  superUserSelection,
} from "@constants/userFormHelpers";

interface IModifyForm extends IFormProps {
  data: IUserDetail;
}

const ModifyUserForm = ({
  handleSubmit,
  form,
  data,
}: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core"]);
  const { GetState, GetStates } = useStates();
  const { GetSuperuser, GetSuperusers } = useSuperusers();
  const [projects] = useState(selectProjectMapper(data?.projects));
  const [clients] = useState(
    data?.client?.uuid && selectClientMapper(data.client)
  );
  const [status] = useState(GetState(data?.state));
  const [superuser] = useState(GetSuperuser(data?.isSuperAdministrator));
  const { clientsValues } = useSelectClients();
  const { projectsValues, handleChangeSelect } = useSelectProjects();

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      {clientsValues && (
        <>
          <WhenFieldChanges
            field={"isSuperAdministrator"}
            becomes={superUserSelection}
            set={"client"}
            to={clientsValues[0]}
          />
          <WhenFieldChanges
            field={"isSuperAdministrator"}
            becomes={superUserSelection}
            set={"projects"}
            to={backofficeSelection}
          />
        </>
      )}

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common:name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder="John"
            initialValue={data?.name}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.last_name")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="lastname"
            component={FormTextField}
            type="text"
            placeholder="Doe"
            initialValue={data?.lastname}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.username")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="username"
            component={FormTextField}
            type="text"
            placeholder="jdoe"
            initialValue={data?.username}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.mail")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AlternateEmailIcon />
          </div>
          <Field
            name="mail"
            component={FormTextField}
            type="email"
            placeholder="john.doe@nftstudios.io"
            initialValue={data?.mail}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Avatar</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountBoxOutlineIcon />
          </div>
          <Field
            name="avatarUrl"
            component={FormTextField}
            type="text"
            placeholder="https://www.nftstudios.io/images/Avatar-Profile.png"
            initialValue={data?.avatar ?? ""}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.superuser")}
        </span>
        <div className="form__form-group-field">
          <Field
            name="isSuperAdministrator"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.superuser"
            )}`}
            options={GetSuperusers()}
            initialValue={superuser}
            isAboveError
          />
        </div>
      </div>

      <Condition when={"isSuperAdministrator.value"} is="0">
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.client")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="client"
              component={renderSelectField}
              type="text"
              placeholder={`${t("core:common.select_placeholder")} ${t(
                "core:common.client"
              )}`}
              options={clientsValues}
              initialValue={clients}
              isAboveError
            />
            <OnChange name="client">
              {(selection, previous) => {
                if (selection && selection.value !== previous.value) {
                  form.change("projects", null);
                  handleChangeSelect(selection.value);
                }
              }}
            </OnChange>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.projects")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="projects"
              component={renderMultiSelectField}
              placeholder={`${t("core:common.select_placeholder")} ${t(
                "core:common.projects"
              )}`}
              options={projectsValues}
              initialValue={projects}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyUserForm;
