import TGlanceItem from "types/Clients/WhaleShark/glance/glanceItem";

const glanceMapper = (formValues: any): TGlanceItem => ({
  title: formValues.title,
  value: formValues.description,
  order: formValues.order,
  state: Boolean(Number(formValues.state.value)),
});

export default glanceMapper;
