import apiInstance from "@services/Shared/instanceAxios";
import { API_URL_HACKATAO_LEARN_MORE } from "@constants/Clients/hackatao/hackatao.env";
import ILearnMoreUpdated from "@interfaces/Clients/Hackatao/learnMore/learnMoreUpdated";

const updateLearnMore = (uuid: string, editLearnMore: ILearnMoreUpdated) =>
  apiInstance({
    url: API_URL_HACKATAO_LEARN_MORE + uuid,
    method: "put",
    data: editLearnMore,
  });

export default updateLearnMore;
