import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ButtonContainer from "@components/ButtonContainer";
import BoxStatus from "@components/BoxStatus";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import TextLongIcon from "mdi-react/TextLongIcon";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import IGlance from "@interfaces/Clients/WhaleShark/glance/glance";

interface IDetailForm {
  data: IGlance;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);

  return (
    <form className="form form--horizontal">
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <input type="text" value={data?.title} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.description")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <TextLongIcon />
          </div>
          <textarea value={data?.value} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.order")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OrderNumericAscendingIcon />
          </div>
          <input type="number" value={data?.order ?? undefined} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <BoxStatus state={data?.state ?? true} />
        </div>
      </div>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
