import apiInstance from "@services/Shared/instanceAxios";
import { TCreateUserDto } from "types/Core/Users/createUserDto";

const updateUser = (uuid: string, editUser: TCreateUserDto) =>
  apiInstance({
    url: `/users/${uuid}`,
    method: "put",
    data: editUser,
  });

export default updateUser;
