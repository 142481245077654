import { ReactElement, useContext } from "react";
import { HOME } from "@constants/routes/routes";
import LogInForm from "./components/LogInForm";
import UserContext from "@context/UserContext";
import { TUserContext } from "types/Core/Authentication/userContext";
import { Redirect } from "react-router-dom";
import logo from "@images/shared/logo.png";
import Loader from "@components/Loader";

const LogIn = (): ReactElement => {
  const { authenticated, isLoading, errorAuthMessage, HandleLogin } =
    useContext<TUserContext>(UserContext);

  if (authenticated) {
    return <Redirect to={HOME} />;
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <img src={logo} alt="Backoffice logo" />
            </h3>
          </div>
          {isLoading ? (
            <Loader isInsideThing />
          ) : (
            <LogInForm onSubmit={HandleLogin} errorMessage={errorAuthMessage} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LogIn;
