import { ReactElement } from "react";
import Modify from "@shared/FormTemplates/Modify";
import ModifyForm from "./ModifyForm";
import updateItem from "@services/Clients/Hackatao/gridAvatars/updateItem";
import validator from "@validators/Clients/Hackatao/gridAvatars/GridAvatars.validate";
import formMapper from "@mappers/Clients/Hackatao/gridAvatars/GridAvatars.mapper";
import useGetGridAvatars from "@hooks/Clients/Hackatao/gridAvatars/useGetGridAvatars";

const ModifyItem = (): ReactElement => (
  <Modify
    CustomForm={ModifyForm}
    modifyHook={updateItem}
    detailHook={useGetGridAvatars}
    validator={validator}
    formMapper={formMapper}
  />
);

export default ModifyItem;
