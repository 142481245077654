import urlFieldValidate from "@validators/shared/url.validate";
import { t } from "i18next";
import emptyFieldValidate from "@validators/shared/empty.validate";

export type TErrors = {
  title: string | undefined;
  desktopLinkImage: string | undefined;
  mobileLinkImage: string | undefined;
  state: string | undefined;
};

const FormValidator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    title: undefined,
    desktopLinkImage: undefined,
    mobileLinkImage: undefined,
    state: undefined,
  };

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.desktopLinkImage = urlFieldValidate(
    FormValues.desktopLinkImage,
    t("hackatao:common:desktopImageLink"),
    true
  );

  errors.mobileLinkImage = urlFieldValidate(
    FormValues.mobileLinkImage,
    t("hackatao:common:mobileImageLink"),
    true
  );

  errors.state = emptyFieldValidate(FormValues.state, t("core:common:state"));

  return errors;
};

export default FormValidator;
