import { useTranslation } from "react-i18next";

const useListColumns = () => {
  const { t } = useTranslation(["core"]);
  return [
    {
      Header: "#",
      accessor: "order",
      disableSortBy: true,
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.name"),
      accessor: "name",
    },
    {
      Header: t("core:common.created_at"),
      accessor: "createdAt",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.updated_at"),
      accessor: "updatedAt",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.state"),
      accessor: "state",
      disableGlobalFilter: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableSortBy: true,
      disableGlobalFilter: true,
    },
  ];
};

export default useListColumns;
