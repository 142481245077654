import { IButtonCategory } from "@interfaces/Shared/buttonCategory";
import { ReactElement } from "react";
import { UncontrolledTooltip } from "reactstrap";

interface IButtonContent {
  uuid: string;
  category: IButtonCategory;
}

const ButtonContent = ({ uuid, category }: IButtonContent): ReactElement => {
  return (
    <>
      <span className={category.spanClass} />
      <UncontrolledTooltip
        className="table__collapse-item--tooltip"
        placement="bottom"
        target={`${category.tooltipTarget}${uuid}`}
      >
        {category.tooltipText}
      </UncontrolledTooltip>
    </>
  );
};

export default ButtonContent;
