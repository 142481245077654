import { API_URL_RF_TIMELINE } from "@constants/Clients/rocketFactory/rocketFactory.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditTimeline from "types/Clients/RocketFactory/timeline/createEditTimeline";

const createItem = (newItem: TCreateEditTimeline) =>
  apiInstance({
    url: API_URL_RF_TIMELINE,
    method: "post",
    data: newItem,
  });

export default createItem;
