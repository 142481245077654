import { ReactElement } from "react";
import Modify from "@shared/FormTemplates/Modify";
import ModifyForm from "./ModifyForm";
import updateItem from "@services/Clients/WhaleShark/vaultCarousel/updateItem";
import useGetDetailItem from "@hooks/Clients/WhaleShark/vault/useGetVault";
import validator from "@validators/Clients/WhaleShark/vaultCarousel/vault.validate";
import formMapper from "@mappers/Clients/WhaleShark/vaultCarousel/VaultItem.mapper";

const ModifyItem = (): ReactElement => (
  <Modify
    CustomForm={ModifyForm}
    modifyHook={updateItem}
    detailHook={useGetDetailItem}
    validator={validator}
    formMapper={formMapper}
  />
);

export default ModifyItem;
