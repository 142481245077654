import { useTranslation } from "react-i18next";

const useListColumns = () => {
  const { t } = useTranslation(["core"]);

  return [
    {
      Header: t("core:common.order"),
      accessor: "order",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.year"),
      accessor: "year",
    },
    {
      Header: t("core:common.month"),
      accessor: "month_name",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.index"),
      accessor: "index",
      disableGlobalFilter: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableSortBy: true,
      disableGlobalFilter: true,
    },
  ];
};

export default useListColumns;
