import { ReactElement } from "react";
import Detail from "@shared/FormTemplates/Detail";
import DetailForm from "./DetailForm";
import useGetClaimTraits from "@hooks/Clients/Hackatao/claimTraits/useGetClaimTrait";

const DetailItem = (): ReactElement => (
  <Detail CustomForm={DetailForm} detailHook={useGetClaimTraits} />
);

export default DetailItem;
