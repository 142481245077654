import { API_URL_WHALESHARK_VAULT_CAROUSEL } from "@constants/Clients/whaleshark/whaleshark.env";
import ICarouselItem from "@interfaces/Clients/WhaleShark/vaultCarousel/carouselItem";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetItem = (uuid: string) =>
  useApiCall<ICarouselItem>({
    url: API_URL_WHALESHARK_VAULT_CAROUSEL + uuid,
  });

export default useGetItem;
