import core_sources from "./source/core.sources";
import reisinger_sources from "./source/reisinger.sources";
import whaleshark_sources from "./source/whaleshark.sources";
import hackatao_sources from "./source/hackatao.sources";
import rocketFactory_sources from "./source/rocketFactory.sources";

const sources = {
  core: core_sources,
  reisinger: reisinger_sources,
  whaleshark: whaleshark_sources,
  hackatao: hackatao_sources,
  rocketFactory: rocketFactory_sources,
};

export default sources;
