import {
  NFT_MODIFY,
  NFT_DETAIL,
} from "@constants/routes/Clients/reisinger.routes";
import { IAsset } from "@interfaces/Clients/Reisinger/asset";
import BoxStatus from "@components/BoxStatus";
import BoxListButton from "@components/BoxListButton";
import { timestampToString } from "@helpers/dates.format";

const listAssetMapper = (usersFromApi: IAsset[], handleDelete: Function) => {
  const listAsset = usersFromApi.map(({ uuid, name, config }) => ({
    uuid,
    order: config.order,
    name,
    releaseDate: timestampToString(config.release_date?.getTime()),
    edition: config.description ?? "-",
    price: config.price?.toString() ?? "-",
    state: <BoxStatus state={config.show} />,
    actions: (
      <BoxListButton
        uuid={uuid}
        description={name}
        detailRoute={NFT_DETAIL}
        modifyRoute={NFT_MODIFY}
        handleDelete={handleDelete}
      />
    ),
  }));

  return listAsset;
};

export default listAssetMapper;
