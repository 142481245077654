import { ReactElement, useState } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import FormTextField from "@components/Shared/FormTextField";
import { useTranslation } from "react-i18next";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import DatePickerField from "@components/Shared/FormDatePickerField";
import IFormProps from "@interfaces/Shared/formProps";
import IArticle from "@interfaces/Clients/WhaleShark/articles/article";
import useStates from "@mappers/Shared/getState.mapper";
import renderSelectField from "@easydev/form/Select";
import {
  ARTICLE_TYPES,
  ARTICLE_CATEGORIES,
} from "@constants/Clients/whaleshark/articles";
import Condition from "@components/EasyDev/form/Condition";
import { EArticleCategories } from "@constants/Clients/whaleshark/enum";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import Documents from "@components/Documents/Documents";
import { timestampToDate } from "@helpers/dates.format";
import { defaultFontFamily } from "@utils/ckeditor/ckEditorConfig";
import { API_URL } from "@constants/env";
import localStorageService from "@utils/localStorage.service";
import { ClientEnum } from "@constants/enums/ClientEnum";
import { RichTextEditor } from "@nftstudios/rich-text-editor-component";

interface IModifyForm extends IFormProps {
  data: IArticle;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const { GetState, GetStates } = useStates();
  const [status] = useState(GetState(data?.state));
  const [valueDate] = useState(timestampToDate(data?.publishDate));
  const authToken = localStorageService.getLocalUserStorage()?.token;

  const [initialType] = useState(
    ARTICLE_TYPES.find(({ value }) => value === data?.type)
  );

  const [initialCategory] = useState(
    ARTICLE_CATEGORIES.find(({ value }) => value === data?.category)
  );

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <ViewHeadlineIcon />
          </div>
          <Field
            name="title"
            component={FormTextField}
            type="text"
            initialValue={data?.title}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.publish_date")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarMonthIcon />
          </div>
          <Field
            name="publishDate"
            component={DatePickerField}
            initialValue={valueDate}
            type="text"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.category")}
        </span>
        <div className="form__form-group-field">
          <Field
            name="category"
            component={renderSelectField}
            type="text"
            options={ARTICLE_CATEGORIES}
            initialValue={initialCategory}
            isAboveError
          />
        </div>
      </div>

      <Condition when="category.value" is={EArticleCategories.PUBLIC}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.type")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="type"
              component={renderSelectField}
              type="text"
              options={ARTICLE_TYPES}
              initialValue={initialType}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.image_link")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <Field
            name="coverImage"
            component={FormTextField}
            type="text"
            initialValue={data?.coverImage}
            placeholder={`${t("core:common.add_an_placeholder")} ${t(
              "core:common.image_link"
            )}`}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
          />
        </div>
      </div>

      <div className="form__form-group-editor">
        <span className="form__form-group-label">{t("core:common.body")}</span>
        <Field name="body">
          {({ input }) => (
            <RichTextEditor
              getData={(value: string) => input.onChange(value)}
              initialBody={data?.body}
              fontFamilyConfig={defaultFontFamily}
              clientForUpload={ClientEnum.whaleshark}
              apiUrlForUpload={API_URL}
              authTokenForUpload={authToken}
            />
          )}
        </Field>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.documents")}
        </span>
        <Field
          name="documents"
          component={Documents}
          initialValue={data?.documents}
          className="editable__array-vertical"
          isAboveError
        />
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
