import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import renderSelectField from "@easydev/form/Select";
import FormTextField from "@components/Shared/FormTextField";
import ButtonContainer from "@components/ButtonContainer";
import FormTextboxIcon from "mdi-react/FormTextboxIcon";
import IdentifierIcon from "mdi-react/IdentifierIcon";
import ImageFilterDramaIcon from "mdi-react/ImageFilterDramaIcon";
import WebIcon from "mdi-react/WebIcon";
import useSelectClients from "@hooks/Security/Clients/useSelectClients";
import IFormProps from "@interfaces/Shared/formProps";

const CreateProjectForm = ({ handleSubmit, form }: IFormProps) => {
  const { clientsValues } = useSelectClients();
  const { t } = useTranslation(["core"]);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.client")}
        </span>
        <div className="form__form-group-field">
          <Field
            name="client"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.client"
            )}`}
            options={clientsValues}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormTextboxIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder="Project name"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.key")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <IdentifierIcon />
          </div>
          <Field
            name="key"
            component={FormTextField}
            type="text"
            placeholder="PROJECTKEY"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <ImageFilterDramaIcon />
          </div>
          <Field
            name="logoUrl"
            component={FormTextField}
            type="text"
            placeholder="https://www.nftstudios.io/images/Avatar-Profile.png"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_website")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <WebIcon />
          </div>
          <Field
            name="websiteUrl"
            component={FormTextField}
            type="text"
            placeholder="https://www.nftstudios.io/"
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateProjectForm;
