import TCreateEditReport from "types/Clients/WhaleShark/report/createEditReport";

const reportItemMapper = (formValues: any): TCreateEditReport => ({
  title: formValues.title,
  reportUrl: formValues.reportUrl,
  imageUrl: formValues.imageUrl,
  state: Boolean(Number(formValues.state.value)),
});

export default reportItemMapper;
