import { t } from "i18next";
import emptyFieldValidate from "./empty.validate";

const urlFieldValidate = (
  field: string,
  name: string,
  validateEmptyField: boolean = false
) => {
  try {
    if (field) {
      Boolean(new URL(field));
    }

    return validateEmptyField ? emptyFieldValidate(field, name) : undefined;
  } catch (e) {
    return `${name} ${t("core:validators:valid-url")}`;
  }
};

export default urlFieldValidate;
