import { TCreateFearGreedDto } from "types/Clients/WhaleShark/fearGreed/createFearGreedDto";
import { format } from "date-fns";

const createFearGreedMapper = (formValues: any): TCreateFearGreedDto => {
  return {
    year: Number(format(formValues.year_month, "yyyy")),
    imageUrl: formValues.imageUrl,
    month: Number(format(formValues.year_month, "MM")),
    index: Number(formValues.index),
  };
};

export default createFearGreedMapper;
