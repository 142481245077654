import ISimpleAsset from "@interfaces/Clients/Reisinger/simpleAsset";

const orderAssets = (simpleAssets: ISimpleAsset[]) => {
  const listAsset = simpleAssets.map(({ uuid, order_new }) => ({
    uuid,
    order: order_new,
  }));

  return listAsset;
};

export default orderAssets;
