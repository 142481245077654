import { API_URL_WHALESHARK_VAULT_CAROUSEL } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateCarouselItem from "types/Clients/WhaleShark/vaultCarousel/carouselItem";

const createItem = (newItem: TCreateCarouselItem) =>
  apiInstance({
    url: API_URL_WHALESHARK_VAULT_CAROUSEL,
    method: "post",
    data: newItem,
  });

export default createItem;
