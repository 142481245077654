import { Link } from "react-router-dom";
import { HOME } from "@constants/routes/routes";
import { useTranslation } from "react-i18next";

const NotFound404 = () => {
  const { t } = useTranslation("core");

  return (
    <div className="not-found">
      <div className="not-found__content">
        <p className="code">404</p>
        <h3 className="not-found__info">
          Ooops! The page you are looking for could not be found :(
        </h3>
        <Link className="btn btn-primary" to={HOME}>
          <span className="lnr lnr-chevron-left" /> {t("core:common.back-home")}
        </Link>
      </div>
    </div>
  );
};

export default NotFound404;
