import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import ButtonContainer from "@components/ButtonContainer";
import BoxStatus from "@components/BoxStatus";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import IGridAvatars from "@interfaces/Clients/Hackatao/gridAvatars/gridAvatars";
import BoxImage from "@components/BoxImage";

interface IDetailForm {
  data: IGridAvatars;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["hackatao", "core"]);

  return (
    <form className="form">
      <Col md={5}>
        <BoxImage urlImage={data?.linkImage} />
      </Col>
      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.title")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <FormatTitleIcon />
            </div>
            <input type="text" value={data?.title} disabled />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.state ?? true} />
          </div>
        </div>
      </Col>

      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
