import { TUpdateAssetDto } from "types/Clients/Reisinger/updateAssetDto";

const updateAssetMapper = (formValues: any): TUpdateAssetDto => {
  return {
    type: formValues.type,
    link_nft: formValues.pieceLink,
    permalink: formValues.pageLink,
    title: formValues.title,
    show: Boolean(Number(formValues.state.value)),
    order: formValues.order ? Number(formValues.order) : null,
    price: formValues.price ? Number(formValues.price) : null,
    release_date: formValues.releaseDate,
    description: formValues.edition ? formValues.edition : null,
  };
};

export default updateAssetMapper;
