import { t } from "i18next";

const emptyFieldValidate = (field: string, name: string) => {
  let errMsg = undefined;

  if (!field) {
    errMsg = `${t("core:validators:the-field")}"${name}" ${t(
      "core:validators:field-shouldnt-empty"
    )}`;
  }

  if (Array.isArray(field) && field.length === 0) {
    errMsg = `${t("core:validators:select-at-least")} ${name}.`;
  }

  return errMsg;
};

export default emptyFieldValidate;
