import { useTranslation } from "react-i18next";

const useDocumentsColumns = () => {
  const { t } = useTranslation(["core"]);

  return [
    {
      accessor: "name",
      title: t("core:common.name"),
    },
    {
      accessor: "link",
      title: t("core:common.link"),
    },
  ];
};

export default useDocumentsColumns;
