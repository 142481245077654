import { API_URL_WHALESHARK_CALENDAR } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";

const getItems = () =>
  apiInstance({
    url: API_URL_WHALESHARK_CALENDAR,
    params: {
      order: "desc",
      sort: "createdAt",
    },
  });

export default getItems;
