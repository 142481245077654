import { useState, useEffect } from "react";
import { IBaseUse } from "@interfaces/Shared/baseUse";
import ILearnMore from "@interfaces/Clients/Hackatao/learnMore/learnMore";
import getLearnMore from "@services/Clients/Hackatao/learnMore/getLearnMore";

interface IUseLearnMore extends IBaseUse {
  data: ILearnMore | undefined;
  handleCallApi: () => void;
}

const useLearnMore = (): IUseLearnMore => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ILearnMore | undefined>(undefined);

  const handleCallApi = () => {
    setIsLoading(true);
    getLearnMore()
      .then(async (response: { data: ILearnMore[] }) => {
        setData(response.data[0]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleCallApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    isLoading,
    handleCallApi,
  };
};

export default useLearnMore;
