import passwordLengthValidate from "@validators/shared/passwordLength.validate";

export type TErrors = {
  password: string | undefined;
};

const resetPasswordValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = { password: undefined };

  errors.password = passwordLengthValidate(FormValues.password, "Password");

  return errors;
};

export default resetPasswordValidate;
