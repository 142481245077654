import useApiCall from "@hooks/Shared/useApiCall";
import { API_URL_HACKATAO_MAIN_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import IMainAvatar from "@interfaces/Clients/Hackatao/mainAvatars/mainAvatar";

const useGetMainAvatars = (uuid: string) =>
  useApiCall<IMainAvatar>({
    url: API_URL_HACKATAO_MAIN_AVATARS + uuid,
  });

export default useGetMainAvatars;
