import TCreateEditClient from "types/Core/ClientManager/createEditClient";

const clientMapper = (formValues: any): TCreateEditClient => {
  return {
    name: formValues.name,
    key: formValues.key,
    logoUrl: formValues.logoUrl,
  };
};

export default clientMapper;
