import { MODULES } from "../routes";

export const WW_KEY_PROJECT = "WHALESHARK|WW";
export const WHALESHARK_ROUTE = `${MODULES}/whale_shark`;

export const HOTV = `${WHALESHARK_ROUTE}/hotv/list`;
export const HOTV_MODIFY = `${HOTV}/:uuid/modify`;
export const HOTV_CREATE = `${HOTV}/create`;
export const HOTV_DETAIL = `${HOTV}/:uuid/detail`;

export const REPORT = `${WHALESHARK_ROUTE}/vault/report/list`;
export const REPORT_MODIFY = `${REPORT}/:uuid/modify`;
export const REPORT_CREATE = `${REPORT}/create`;
export const REPORT_DETAIL = `${REPORT}/:uuid/detail`;

export const FEARGREED = `${WHALESHARK_ROUTE}/fearGreedIndex/list`;
export const FEARGREED_MODIFY = `${FEARGREED}/:uuid/modify`;
export const FEARGREED_CREATE = `${FEARGREED}/create`;
export const FEARGREED_DETAIL = `${FEARGREED}/:uuid/detail`;

export const PARTNER = `${WHALESHARK_ROUTE}/partner/list`;
export const PARTNER_MODIFY = `${PARTNER}/:uuid/modify`;
export const PARTNER_CREATE = `${PARTNER}/create`;
export const PARTNER_DETAIL = `${PARTNER}/:uuid/detail`;

export const VAULT_CAROUSEL = `${WHALESHARK_ROUTE}/vault/list`;
export const VAULT_CAROUSEL_MODIFY = `${VAULT_CAROUSEL}/:uuid/modify`;
export const VAULT_CAROUSEL_CREATE = `${VAULT_CAROUSEL}/create`;
export const VAULT_CAROUSEL_DETAIL = `${VAULT_CAROUSEL}/:uuid/detail`;

export const GLANCE = `${WHALESHARK_ROUTE}/glance/list`;
export const GLANCE_MODIFY = `${GLANCE}/:uuid/modify`;
export const GLANCE_CREATE = `${GLANCE}/create`;
export const GLANCE_DETAIL = `${GLANCE}/:uuid/detail`;

export const BENEFITS = `${WHALESHARK_ROUTE}/benefits/list`;
export const BENEFIT_MODIFY = `${BENEFITS}/:uuid/modify`;
export const BENEFIT_CREATE = `${BENEFITS}/create`;
export const BENEFIT_DETAIL = `${BENEFITS}/:uuid/detail`;

export const ARTICLES = `${WHALESHARK_ROUTE}/articles/list`;
export const ARTICLES_MODIFY = `${ARTICLES}/:uuid/modify`;
export const ARTICLES_CREATE = `${ARTICLES}/create`;
export const ARTICLES_DETAIL = `${ARTICLES}/:uuid/detail`;

export const CALENDAR = `${WHALESHARK_ROUTE}/calendar`;

export const WEB_ARTICLE_DETAIL = "/whale-tales/:uuid/article-detail";
