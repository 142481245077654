import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import useStates from "@mappers/Shared/getState.mapper";
import TextLongIcon from "mdi-react/TextLongIcon";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import IFormProps from "@interfaces/Shared/formProps";

const CreateForm = ({ handleSubmit }: IFormProps): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const { GetState, GetStates } = useStates();
  const [status] = useState(GetState(true));

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <ViewHeadlineIcon />
          </div>
          <Field
            name="title"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.title")}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.description")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <TextLongIcon />
          </div>
          <Field
            name="description"
            component={FormTextField}
            type="text"
            placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.order")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OrderNumericAscendingIcon />
          </div>
          <Field
            name="order"
            component={FormTextField}
            type="number"
            placeholder={0}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateForm;
