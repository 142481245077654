import { ReactElement, useState } from "react";
import { Col } from "reactstrap";
import ButtonContainer from "@components/ButtonContainer";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import { useTranslation } from "react-i18next";
import IArticles from "@interfaces/Clients/WhaleShark/articles/article";
import BoxStatus from "@components/BoxStatus";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import {
  ARTICLE_TYPES,
  ARTICLE_CATEGORIES,
} from "@constants/Clients/whaleshark/articles";
import BoxEditor from "@components/BoxEditor";
import { timestampToString } from "@helpers/dates.format";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import { EArticleCategories } from "@constants/Clients/whaleshark/enum";
import { WHALE_URL } from "@constants/env";
import { WEB_ARTICLE_DETAIL } from "@constants/routes/Clients/whale.routes";
import Documents from "@components/Documents/Documents";

interface IDetailForm {
  data: IArticles;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);

  const [initialType] = useState(
    ARTICLE_TYPES.find(({ value }) => value === data?.type)
  );

  const [initialCategory] = useState(
    ARTICLE_CATEGORIES.find(({ value }) => value === data?.category)
  );

  const pathPreview = `${WHALE_URL}${WEB_ARTICLE_DETAIL}/preview`;

  return (
    <form className="form">
      <Col md={4}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.title")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <ViewHeadlineIcon />
            </div>
            <input type="text" value={data?.title} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.publish_date")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarMonthIcon />
            </div>
            <input
              type="text"
              value={timestampToString(data?.publishDate)}
              disabled
            />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.category")}
          </span>
          <div className="form__form-group-field">
            <input type="text" value={initialCategory?.label} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.type")}
          </span>
          <div className="form__form-group-field">
            <input
              type="text"
              value={
                initialCategory?.label === EArticleCategories.PUBLIC
                  ? initialType?.label
                  : "-"
              }
              disabled
            />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.image_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            {data?.coverImage === null ? (
              <input type="text" value={"-"} disabled />
            ) : (
              <a
                className="form__form-group-link"
                href={data?.coverImage}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.coverImage}
              </a>
            )}
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.created_at")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarPlusIcon />
            </div>
            <input value={timestampToString(data?.createdAt)} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.updated_at")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarEditIcon />
            </div>
            <input value={timestampToString(data?.updatedAt)} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.state} />
          </div>
        </div>
      </Col>

      {data?.body && (
        <Col md={8}>
          <BoxEditor
            body={data?.body}
            id={data?.uuid}
            showPreview={true}
            pathPreview={pathPreview}
          />
        </Col>
      )}

      {data?.documents && data.documents.length > 0 && (
        <Col md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.documents")}
            </span>
            <Documents input={{ value: data.documents }} disabled />
          </div>
        </Col>
      )}

      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
