import { ReactElement } from "react";
import ButtonContainer from "@components/ButtonContainer";
import FileSignIcon from "mdi-react/FileSignIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { useTranslation } from "react-i18next";
import BoxStatus from "@components/BoxStatus";
import IReport from "@interfaces/Clients/WhaleShark/report/report";

interface IDetailForm {
  data: IReport;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);

  return (
    <form className="form form--horizontal">
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FileSignIcon />
          </div>
          <input type="text" value={data?.title} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.url")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <input type="text" value={data?.reportUrl} disabled />
          <a href={data?.reportUrl} target="_blank" rel="noopener noreferrer">
            <div className="form__form-group-icon">
              <OpenInNewIcon />
            </div>
          </a>
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <input type="text" value={data?.imageUrl} disabled />
          <a href={data?.imageUrl} target="_blank" rel="noopener noreferrer">
            <div className="form__form-group-icon">
              <OpenInNewIcon />
            </div>
          </a>
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <BoxStatus state={data?.state ?? true} />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
