import hackatao from "../Clients/hackatao.modules";
import reisinger from "../Clients/reisinger.modules";
import whale_shark from "../Clients/whaleshark.modules";
import security from "../Core/security.modules";
import rocket_factory from "../Clients/rocketFactory.modules";

const MODULES = {
  security,
  whale_shark,
  reisinger,
  hackatao,
  rocket_factory,
};

export default MODULES;
