import {
  ARTICLES_MODIFY,
  ARTICLES_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import IArticle from "@interfaces/Clients/WhaleShark/articles/article";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listMapper = (itemsFromAPI: IArticle[], handleDelete: Function) =>
  itemsFromAPI.map(
    ({ uuid, title, publishDate, type, category, state }, itemIndex) => ({
      uuid,
      order: itemIndex + 1,
      title,
      publishDate: timestampToString(publishDate),
      type: type ?? "-",
      category,
      state: <BoxStatus state={state} />,
      actions: (
        <BoxListButton
          uuid={uuid}
          description={title}
          detailRoute={ARTICLES_DETAIL}
          modifyRoute={ARTICLES_MODIFY}
          handleDelete={handleDelete}
        />
      ),
    })
  );

export default listMapper;
