import { TGridAvatarsItem } from "types/Clients/Hackatao/gridAvatars/TGridAvatarsItem";

const GridAvatarMapper = (formValues: any): TGridAvatarsItem => {
  return {
    title: formValues.title,
    linkImage: formValues.linkImage,
    state: Boolean(Number(formValues.state.value)),
  };
};

export default GridAvatarMapper;
