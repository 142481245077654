import { API_URL_WHALESHARK_HIGHLIGHTS } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditHighlight from "types/Clients/WhaleShark/hotv/createEditHighlight";

const createItem = (newItem: TCreateEditHighlight) =>
  apiInstance({
    url: API_URL_WHALESHARK_HIGHLIGHTS,
    method: "post",
    data: newItem,
  });

export default createItem;
