import { ReactElement, MouseEventHandler, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarLanguage from "./TopbarLanguage";
import TopbarProfile from "./TopbarProfile";
import { HOME } from "@constants/routes/routes";
import { TUserContext } from "types/Core/Authentication/userContext";
import UserContext from "@context/UserContext";

interface ITopbar {
  changeMobileSidebarVisibility: MouseEventHandler<HTMLButtonElement>;
  changeSidebarVisibility: MouseEventHandler<HTMLButtonElement>;
}

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}: ITopbar): ReactElement => {
  const { currentUser } = useContext<TUserContext>(UserContext);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
          <Link
            className="topbar__logo"
            style={{ backgroundImage: `url(${currentUser?.clientLogo})` }}
            to={HOME}
          ></Link>
        </div>
        <div className="topbar__right">
          <TopbarLanguage />
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
