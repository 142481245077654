import { IButtonCategory } from "@interfaces/Shared/buttonCategory";
import { ReactElement } from "react";
import { Link, generatePath } from "react-router-dom";
import { Button } from "reactstrap";
import ButtonContent from "../ButtonContent";

export interface ILinkButton {
  route: string;
  uuid: string;
  category: IButtonCategory;
}

const LinkButton = ({ route, uuid, category }: ILinkButton): ReactElement => {
  return (
    <Link
      to={generatePath(route, { uuid: uuid })}
      id={`${category.tooltipTarget}${uuid}`}
    >
      <Button className={category.buttonClass}>
        <ButtonContent uuid={uuid} category={category} />
      </Button>
    </Link>
  );
};

export default LinkButton;
