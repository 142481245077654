import TCreateEditHighlight from "types/Clients/WhaleShark/hotv/createEditHighlight";

const hotvItemMapper = (formValues: any): TCreateEditHighlight => ({
  title: formValues.title,
  collectionName: formValues.collectionName,
  description: formValues.description,
  imageUrl: formValues.imageUrl,
  openSeaUrl: formValues.openSeaUrl,
  state: Boolean(Number(formValues.state.value)),
});

export default hotvItemMapper;
