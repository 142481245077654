import { t } from "i18next";
import emptyFieldValidate from "../../shared/empty.validate";
import usernameValidate from "../../shared/username.validate";

export type TErrors = {
  username: string | undefined;
  name: string | undefined;
  lastname: string | undefined;
  avatarUrl: string | undefined;
};

const updateProfile = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    username: undefined,
    name: undefined,
    lastname: undefined,
    avatarUrl: undefined,
  };

  errors.username = usernameValidate(FormValues.username);

  errors.name = emptyFieldValidate(FormValues.name, t("core:common:username"));

  errors.lastname = emptyFieldValidate(
    FormValues.lastname,
    t("core:common:last_name")
  );

  errors.avatarUrl = emptyFieldValidate(FormValues.avatarUrl, "Avatar");

  return errors;
};

export default updateProfile;
