import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";

export type TErrors = {
  name: string | undefined;
  imageUrl: string | undefined;
  order: string | undefined;
};

const PartnerValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    imageUrl: undefined,
    order: undefined,
  };

  errors.imageUrl = emptyFieldValidate(
    FormValues.imageUrl,
    t("core:common:url_image")
  );
  errors.name = emptyFieldValidate(FormValues.name, t("core:common:name"));
  errors.order = emptyFieldValidate(FormValues.order, t("core:common:order"));

  return errors;
};

export default PartnerValidate;
