import TCreateEditArticle from "types/Clients/WhaleShark/articles/createEditArticle";

const articleItemMapper = (formValues: any): TCreateEditArticle => ({
  title: formValues.title,
  body: formValues.body,
  publishDate: Number(formValues.publishDate) / 1000,
  type: formValues.type?.value,
  category: formValues.category.value,
  coverImage: formValues.coverImage,
  state: Boolean(Number(formValues.state.value)),
  documents: formValues.documents,
});

export default articleItemMapper;
