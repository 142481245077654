import { t } from "i18next";
import emptyFieldValidate from "./empty.validate";

const usernameValidate = (username: string) => {
  let errMsg = undefined;

  errMsg = emptyFieldValidate(username, t("core:common:username"));

  if (username && username.includes(" ")) {
    errMsg = t("core:validators:spaces-not-allowed");
  }

  if (username) {
    if (username.length < 6 || username.length > 20) {
      errMsg = t("core:validators:username-characters");
    }
  }

  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(username)) {
    errMsg = t("core:validators:symbols-not-allowed");
  }

  return errMsg;
};

export default usernameValidate;
