import { typeSocial } from "@constants/enums/shared/enum";
import ITimeline from "@interfaces/Clients/RocketFactory/timeline/timeline";

const useGetSocials = (data: ITimeline) => {
  const socialInstagram = data?.socials?.find(
    ({ type }) => type === typeSocial.instagram
  );

  const socialTwitter = data?.socials?.find(
    ({ type }) => type === typeSocial.twitter
  );

  const socialDiscord = data?.socials?.find(
    ({ type }) => type === typeSocial.discord
  );

  return {
    socialInstagram,
    socialTwitter,
    socialDiscord,
  };
};

export default useGetSocials;
