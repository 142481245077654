import { API_URL_HACKATAO_MAIN_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";

const deleteItem = (uuid: string) =>
  apiInstance({
    url: API_URL_HACKATAO_MAIN_AVATARS + uuid,
    method: "delete",
  });

export default deleteItem;
