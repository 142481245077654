import { ReactElement } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import useChangeOrder from "@hooks/Clients/Reisinger/nftPieces/useChangeOrder";
import ImageIcon from "mdi-react/ImageIcon";
import TableDraggeable from "@easydev/tables/TableDraggeable";
import ModifyConfirmation from "@shared/Modals/ModifyConfirmation";
import Loader from "@components/Loader";
import { NFT_PIECES } from "@constants/routes/Clients/reisinger.routes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "../ListNft/ListItems.module.scss";

const ChangeOrderList = (): ReactElement => {
  const { t } = useTranslation(["core", "reisinger"]);

  const {
    columns,
    modalStatus,
    isLoading,
    assets,
    handleModalStatus,
    handleConfirmModify,
    updateIndexes,
  } = useChangeOrder();

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">
              <ImageIcon />
              Reisinger | {t("reisinger:common.nft_pieces")}|{" "}
              {t("core:common.reorder")}
            </h3>
          </Col>
        </Row>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <ModifyConfirmation
        title={`Reisinger | ${t("reisinger:common.nft_pieces")} | ${t(
          "core:common.modify_confirm"
        )}`}
        body={t("core:notifications.reorder_confirm")}
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirmModify={handleConfirmModify}
      />
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            <ImageIcon />
            Reisinger | {t("reisinger:common.nft_pieces")} |{" "}
            {t("core:common.reorder")}
          </h3>
        </Col>
      </Row>

      <div className={styles.btnContainer}>
        <div className={styles.absolute}>
          <Link to={NFT_PIECES}>
            <Button>
              <span className="lnr lnr-chevron-left" />{" "}
              {t("core:common.goback")}
            </Button>
          </Link>

          <Button
            onClick={handleModalStatus}
            color="primary"
            className="btn-warning"
          >
            <span className="lnr lnr-pencil" /> {t("core:common.modify")}
          </Button>
        </div>
      </div>

      <TableDraggeable
        columns={columns}
        items={assets}
        updateIndexes={updateIndexes}
      />
    </Container>
  );
};

export default ChangeOrderList;
