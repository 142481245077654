import IGlanceApi from "@interfaces/Clients/WhaleShark/glance/glance";
import {
  GLANCE_MODIFY,
  GLANCE_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listMapper = (listFromApi: IGlanceApi[], handleDelete: Function) =>
  listFromApi.map(({ uuid, title, createdAt, updatedAt, state }, index) => ({
    order: index + 1,
    uuid,
    title,
    createdAt: timestampToString(createdAt),
    updatedAt: timestampToString(updatedAt),
    state: <BoxStatus state={state} />,
    actions: (
      <BoxListButton
        uuid={uuid}
        description={title}
        detailRoute={GLANCE_DETAIL}
        modifyRoute={GLANCE_MODIFY}
        handleDelete={handleDelete}
      />
    ),
  }));

export default listMapper;
