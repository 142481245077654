import { MODULES } from "../routes";

export const HACKATAO_KEY_PROJECT = "HACKATAO|HK";
export const HACKATAO_ROUTE = `${MODULES}/hackatao`;

export const CLAIMTRAIT = `${HACKATAO_ROUTE}/claimtraits/list`;
export const CLAIMTRAIT_MODIFY = `${CLAIMTRAIT}/:uuid/modify`;
export const CLAIMTRAIT_CREATE = `${CLAIMTRAIT}/create`;
export const CLAIMTRAIT_DETAIL = `${CLAIMTRAIT}/:uuid/detail`;

export const LEARNMORE = `${HACKATAO_ROUTE}/learnmore/modify`;

export const GRIDAVATARS = `${HACKATAO_ROUTE}/gridavatar/list`;
export const GRIDAVATARS_MODIFY = `${GRIDAVATARS}/:uuid/modify`;
export const GRIDAVATARS_CREATE = `${GRIDAVATARS}/create`;
export const GRIDAVATARS_DETAIL = `${GRIDAVATARS}/:uuid/detail`;

export const MAINAVATARS = `${HACKATAO_ROUTE}/mainavatar/list`;
export const MAINAVATARS_MODIFY = `${MAINAVATARS}/:uuid/modify`;
export const MAINAVATARS_CREATE = `${MAINAVATARS}/create`;
export const MAINAVATARS_DETAIL = `${MAINAVATARS}/:uuid/detail`;
