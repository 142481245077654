import { ReactElement, useState } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import FormTextField from "@components/Shared/FormTextField";
import { useTranslation } from "react-i18next";
import CounterIcon from "mdi-react/CounterIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import renderDateMonthPickerField from "@easydev/form/DateMonthPicker";
import IFormProps from "@interfaces/Shared/formProps";
import IFearGreed from "@interfaces/Clients/WhaleShark/fearGreed/fearGreed";

interface IModifyForm extends IFormProps {
  data: IFearGreed;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const [valueDate] = useState(new Date(data?.year, data?.month - 1, 1));

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.month")} / {t("core:common.year")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarMonthIcon />
          </div>
          <Field
            name="year_month"
            component={renderDateMonthPickerField}
            placeholder="MM/yyyy"
            initialValue={valueDate}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.index")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CounterIcon />
          </div>
          <Field
            name="index"
            component={FormTextField}
            type="number"
            placeholder={0}
            initialValue={data?.index}
            isAboveError
          />
        </div>
      </div>

      {data?.imageUrl && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.url_image")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="imageUrl"
              component={FormTextField}
              type="text"
              placeholder="https://.../chart.png"
              initialValue={data?.imageUrl}
              isAboveError
            />
          </div>
        </div>
      )}

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
