import { IProject } from "@interfaces/Core/Project/project";
import { ISelect } from "@interfaces/Shared/select";

const selectProjectMapper = (projects: IProject[]): ISelect[] =>
  projects?.map((project) => ({
    value: project.uuid,
    label: project.name,
  }));

export default selectProjectMapper;
