import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";

export type TErrors = {
  year_month: string | undefined;
  imageUrl: string | undefined;
  index: string | undefined;
};

const validator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    year_month: undefined,
    imageUrl: undefined,
    index: undefined,
  };

  errors.year_month = emptyFieldValidate(
    FormValues.year_month,
    `${t("core:common:month")} / ${t("core:common:year")}`
  );

  errors.imageUrl = emptyFieldValidate(
    FormValues.imageUrl,
    t("core:common:url_image")
  );
  errors.index = emptyFieldValidate(FormValues.index, t("core:common:index"));

  return errors;
};

export default validator;
