import {
  PARTNER_MODIFY,
  PARTNER_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import IPartner from "@interfaces/Clients/WhaleShark/partner/partner";
import { GetCategory } from "@mappers/Clients/WhaleShark/partner/getCategory.mapper";
import BoxListButton from "@components/BoxListButton";

const listReportMapper = (items: IPartner[], handleDelete: Function) =>
  items.map(({ uuid, name, category, imageUrl, order }) => ({
    uuid,
    order,
    category: GetCategory(category).label,
    name,
    imageUrl,
    actions: (
      <BoxListButton
        uuid={uuid}
        description={name}
        detailRoute={PARTNER_DETAIL}
        modifyRoute={PARTNER_MODIFY}
        handleDelete={handleDelete}
      />
    ),
  }));

export default listReportMapper;
