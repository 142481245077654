import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import EditIcon from "mdi-react/PencilCircleOutlineIcon";
import ListIcon from "mdi-react/FormatListBulletedIcon";
import PlusIcon from "mdi-react/PlusCircleOutlineIcon";
import EyeIcon from "mdi-react/EyeOutlineIcon";
import MODULES from "@config/Modules/Shared/modules";
import getModuleName from "@helpers/modules";

type TTitleInfo = {
  [key: string]: {
    icon: JSX.Element;
    state: string;
  };
};

const Title = (): ReactElement => {
  const { t } = useTranslation(["hackatao", "whaleshark", "reisinger", "core"]);
  const { pathname } = useLocation();

  const getClientName = () =>
    Object.keys(MODULES)
      .filter((client) => pathname.includes(client))[0]
      .replace("_", " ");

  const getAction = () => {
    const actions = ["create", "detail", "modify", "list"];

    const titleInfo: TTitleInfo = {
      list: {
        icon: <ListIcon />,
        state: "",
      },
      create: {
        icon: <PlusIcon />,
        state: "core:common.create",
      },
      detail: {
        icon: <EyeIcon />,
        state: "core:common.detail",
      },
      modify: {
        icon: <EditIcon />,
        state: "core:common.modify",
      },
    };

    const state = actions.filter((state) => pathname.includes(state));

    return titleInfo[state[0]];
  };

  return (
    <Row>
      <Col>
        <h3 className="page-title">
          {getAction().icon} {getClientName()} | {t(getModuleName(pathname))}
          {getAction().state !== "" && ` | ${t(getAction().state)}`}
        </h3>
      </Col>
    </Row>
  );
};

export default Title;
