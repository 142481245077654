import { API_URL_WHALESHARK_FGINDEX } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TUpdateFearGreedDto } from "types/Clients/WhaleShark/fearGreed/updateFearGreedDto";

const updateItem = (uuid: string, editFearGreed: TUpdateFearGreedDto) =>
  apiInstance({
    url: API_URL_WHALESHARK_FGINDEX + uuid,
    method: "put",
    data: editFearGreed,
  });

export default updateItem;
