import { Link } from "react-router-dom";
import { Container, Col } from "reactstrap";
import { HOME } from "@constants/routes/routes";

const NotAccess401 = () => (
  <Container>
    <Col>
      <div className="not-found__content">
        <p className="code">401</p>
        <h3 className="not-found__info">
          Ooops! The page you are looking for could not be access :(
        </h3>
        <Link className="btn btn-primary" to={HOME}>
          Back Home
        </Link>
      </div>
    </Col>
  </Container>
);

export default NotAccess401;
