import { API_URL_WHALESHARK_VAULT_CAROUSEL } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCarouselItem from "types/Clients/WhaleShark/vaultCarousel/carouselItem";

const updateItem = (uuid: string, editedItem: TCarouselItem) =>
  apiInstance({
    url: API_URL_WHALESHARK_VAULT_CAROUSEL + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
