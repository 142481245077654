import { Col, Card, CardBody, Button, Row, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { Field, Form } from "react-final-form";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AccountBoxOutlineIcon from "mdi-react/AccountBoxOutlineIcon";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import FormField from "@easydev/form/FormField";
import EditIcon from "mdi-react/EditIcon";
import { UPDATE_PASSWORD } from "@constants/routes/routes";
import Loader from "@components/Loader";
import { useTranslation } from "react-i18next";

const ProfileSettings = ({
  onSubmit,
  validateForms,
  profile,
  isLoading,
  isLoadingUpdate,
}) => {
  const { t } = useTranslation(["core"]);

  return (
    <Col xs={12} md={12} lg={12} xl={8}>
      <Card>
        <CardBody>
          {isLoading ? (
            <Loader isInsideThing />
          ) : (
            <>
              <Row>
                <Col md={12}>
                  <h4 className="page-title">
                    <EditIcon />
                    {`${t("core:profile.edit_profile")}`}
                  </h4>
                </Col>
              </Row>
              <Form
                onSubmit={(FormValues) => {
                  onSubmit(FormValues);
                }}
                validate={validateForms}
              >
                {({ handleSubmit }) => (
                  <form
                    className="form form--horizontal"
                    onSubmit={handleSubmit}
                  >
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.mail")}
                      </span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AlternateEmailIcon />
                        </div>
                        <Field
                          name="email"
                          component="input"
                          type="email"
                          placeholder={profile?.mail}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.username")}
                      </span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AccountOutlineIcon />
                        </div>
                        <Field
                          name="username"
                          component={FormField}
                          type="text"
                          initialValue={profile?.username}
                          isAboveError
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.name")}
                      </span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AccountOutlineIcon />
                        </div>
                        <Field
                          name="name"
                          component={FormField}
                          type="text"
                          initialValue={profile?.name}
                          isAboveError
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("core:common.last_name")}
                      </span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AccountOutlineIcon />
                        </div>
                        <Field
                          name="lastname"
                          component={FormField}
                          type="text"
                          initialValue={profile?.lastname}
                          isAboveError
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Avatar</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AccountBoxOutlineIcon />
                        </div>
                        <Field
                          name="avatarUrl"
                          component={FormField}
                          type="text"
                          initialValue={profile?.avatar}
                          isAboveError
                        />
                      </div>
                    </div>
                    {isLoadingUpdate ? (
                      <Loader isInsideThing />
                    ) : (
                      <ButtonToolbar className="form__button-toolbar">
                        <Link to={UPDATE_PASSWORD}>
                          <Button color="primary" type="submit" outline>
                            {t("core:common.modify")}{" "}
                            {t("core:common.password")}
                          </Button>
                        </Link>
                        <Button color="success" type="submit">
                          {t("core:common.modify")} {t("core:common.profile")}
                        </Button>
                      </ButtonToolbar>
                    )}
                  </form>
                )}
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileSettings;
