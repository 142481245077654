import { ISelect } from "@interfaces/Shared/select";

export const backofficeSelection: ISelect[] = [
  {
    value: "e627e177-b0ac-4d79-a69f-fbcec587005f",
    label: "Backoffice",
  },
];

export const superUserSelection: ISelect = { label: "Yes", value: "1" };
