import {
  HOTV_MODIFY,
  HOTV_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import IHotv from "@interfaces/Clients/WhaleShark/hotv/hotv";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listHotvMapper = (items: IHotv[], handleDelete: Function) =>
  items.map(
    ({ uuid, title, collectionName, createdAt, updatedAt, state }, index) => ({
      uuid,
      order: index + 1,
      title,
      collectionName,
      createdAt: timestampToString(createdAt),
      updatedAt: timestampToString(updatedAt),
      state: <BoxStatus state={state} />,
      actions: (
        <BoxListButton
          uuid={uuid}
          description={title}
          detailRoute={HOTV_DETAIL}
          modifyRoute={HOTV_MODIFY}
          handleDelete={handleDelete}
        />
      ),
    })
  );

export default listHotvMapper;
