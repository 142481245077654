import { API_URL_WHALESHARK_BENEFITS } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TGlanceAPI from "types/Clients/WhaleShark/glance/glanceApi";

const createItem = (newAsset: TGlanceAPI) =>
  apiInstance({
    url: API_URL_WHALESHARK_BENEFITS,
    method: "post",
    data: newAsset,
  });

export default createItem;
