import { API_URL_WHALESHARK_REPORT } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditReport from "types/Clients/WhaleShark/report/createEditReport";

const createItem = (newItem: TCreateEditReport) =>
  apiInstance({
    url: API_URL_WHALESHARK_REPORT,
    method: "post",
    data: newItem,
  });

export default createItem;
