import { ReactElement } from "react";
import ReactPlayer from "react-player/lazy";
import "./Player.scss";

const Player = ({
  media,
  title,
  className,
}: {
  media?: string;
  title: string;
  className?: string;
}): ReactElement =>
  media?.indexOf(".mp4") !== -1 ? (
    <div className="react-player-container">
      <ReactPlayer
        controls={false}
        volume={0.5}
        muted={true}
        loop={true}
        playing
        url={media}
      />
    </div>
  ) : (
    <img src={media} alt={title} className={className} />
  );

export default Player;
