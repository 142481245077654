import { ReactElement } from "react";
import { Col } from "reactstrap";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { IUserDetail } from "@interfaces/Core/Users/userDetail";
import { useTranslation } from "react-i18next";
import BoxStatus from "@components/BoxStatus";
import BoxSuperuser from "@components/BoxSuperuser";
import avatar_logo from "@images/account/avatar.png";
import ButtonContainer from "@components/ButtonContainer";
import { timestampToString } from "@helpers/dates.format";
import BoxImage from "@components/BoxImage";

interface IDetailUserForm {
  data: IUserDetail;
}

const DetailUserForm = ({ data }: IDetailUserForm): ReactElement => {
  const { t } = useTranslation(["core"]);

  return (
    <form className="form">
      <Col md={5}>
        <BoxImage altImage="avatar" urlImage={data?.avatar ?? avatar_logo} />

        {!data?.isSuperAdministrator && (
          <>
            {data?.client && (
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("core:common.client")}
                </span>
                <div className="form__form-group-field">
                  <span className="badge badge-success">
                    {data.client.name}
                  </span>
                </div>
              </div>
            )}

            {data?.projects && (
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("core:common.projects")}
                </span>
                <div className="form__form-group-field">
                  {data?.projects.map(({ name, uuid }) => (
                    <p key={uuid} className="profile__stat-title">
                      <span className="badge badge-success">{name}</span>
                    </p>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </Col>

      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.name")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input type="text" value={data?.name} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.last_name")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input type="text" value={data?.lastname} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.username")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input type="text" value={data?.username} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.mail")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AlternateEmailIcon />
            </div>
            <input type="text" value={data?.mail} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.created_at")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarPlusIcon />
            </div>
            <input value={timestampToString(data?.createdAt)} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.updated_at")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarEditIcon />
            </div>
            <input value={timestampToString(data?.updatedAt)} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.state ?? true} />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.superuser")}
          </span>
          <div className="form__form-group-field">
            <BoxSuperuser value={data?.isSuperAdministrator ?? false} />
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailUserForm;
