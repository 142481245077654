import { TClaimTraitsItem } from "types/Clients/Hackatao/claimTraits/TClaimTraitsItem";

const claimTraitMapper = (formValues: any): TClaimTraitsItem => ({
  name: formValues.name,
  connectText: formValues.connectText,
  winnerText: formValues.winnerText,
  notWinnerText: formValues.notWinnerText,
  alreadyClaimedText: formValues.alreadyClaimedText,
  loadingText: formValues.loadingText,
  successText: formValues.successText,
  linkImageLeft: formValues.linkImageLeft,
  linkImageRight: formValues.linkImageRight,
  traitsPerWallet: formValues.traitsPerWallet,
  whitelist: formValues.whitelist?.split("\n"),
  buttonText: formValues.buttonText,
  state: Boolean(Number(formValues.state.value)),
});

export default claimTraitMapper;
