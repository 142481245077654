import { NavLink } from "react-router-dom";
import { ButtonToolbar, Card, CardBody, Col, Container, Row } from "reactstrap";
import EmailIcon from "mdi-react/EmailIcon";
import CheckboxMarkedCircleIcon from "mdi-react/CheckboxMarkedCircleIcon";
import { LOGIN } from "@constants/routes/routes";
import { useTranslation } from "react-i18next";

const ResetPasswordConfirmation = () => {
  const { t } = useTranslation(["core"]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="email-confirmation">
                <div className="email-confirmation__icon">
                  <EmailIcon className="email-confirmation__mail" />
                  <CheckboxMarkedCircleIcon className="email-confirmation__check" />
                </div>
                <h3 className="email-confirmation__title">
                  Your password has been successfully changed
                </h3>
                <ButtonToolbar className="email-confirmation__buttons">
                  <NavLink className="btn btn-primary" to={LOGIN}>
                    {t("core:profile.login")}
                  </NavLink>
                </ButtonToolbar>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordConfirmation;
