import { ReactElement } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import FormTextField from "@components/Shared/FormTextField";
import IdentifierIcon from "mdi-react/IdentifierIcon";
import FormTextboxIcon from "mdi-react/FormTextboxIcon";
import ImageFilterDramaIcon from "mdi-react/ImageFilterDramaIcon";
import IFormProps from "@interfaces/Shared/formProps";
import IClient from "@interfaces/Core/ClientManager/client";
import ButtonContainer from "@components/ButtonContainer";

interface IModifyForm extends IFormProps {
  data: IClient;
}
const ModifyClientForm = ({
  handleSubmit,
  data,
}: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core"]);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormTextboxIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder={`${t("core:common.client")} ${t("core:common.name")}`}
            initialValue={data?.name}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.key")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <IdentifierIcon />
          </div>
          <Field
            name="key"
            component={FormTextField}
            type="text"
            placeholder="CLIENTKEY"
            initialValue={data?.key}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <ImageFilterDramaIcon />
          </div>
          <Field
            name="logoUrl"
            component={FormTextField}
            type="text"
            placeholder="https://www.nftstudios.io/images/Avatar-Profile.png"
            initialValue={data?.logoUrl}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyClientForm;
