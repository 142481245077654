import { TUserContext } from "types/Core/Authentication/userContext";
import { createContext } from "react";

const UserContext = createContext<TUserContext>({
  authenticated: false,
  locked: false,
  isLoading: false,
  isInitialized: false,
  errorAuthMessage: undefined,
  currentUser: null,
  setCurrentUser: () => {},
  HandleLogin: () => {},
  HandleLogout: () => {},
  HandleCheckLogin: () => {},
  HandleLockScreen: () => {},
  HandleUnLockScreen: () => {},
});

export default UserContext;
