import { useState } from "react";
import { useHistory, generatePath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { THookUpdate } from "types/Shared/hooks";
import { EUiStates } from "@constants/enum";
import { showNotification } from "@easydev/Notification";
import { removeDraft } from "@helpers/draft";

interface IUseUpdateItem {
  isModalOpen: boolean;
  isUpdating: boolean;
  handleUpdate: (
    APIUpdate: THookUpdate,
    uuid: string,
    callAfterUpdate?: () => void
  ) => void;
  handleIsModalOpen: () => void;
  handleSaveForm: (formValues: any, mapper: any) => void;
}

const useUpdateItem = (): IUseUpdateItem => {
  const { t } = useTranslation(["core"]);
  const { pathname } = useLocation();
  const history = useHistory();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(undefined);

  const handleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSaveForm = (formValues: any, mapper: any) => {
    handleIsModalOpen();
    const formattedValues = mapper(formValues);
    setFormValues(formattedValues);
  };

  const handleUpdate = (
    APIUpdate: THookUpdate,
    uuid: string,
    callAfterUpdate?: () => void
  ) => {
    setIsUpdating(true);
    handleIsModalOpen();
    removeDraft();

    APIUpdate(uuid, formValues)
      .then(() => {
        showNotification(
          t("core:common.success"),
          t("core:notifications.update_success"),
          EUiStates.SUCCESS
        );

        !callAfterUpdate &&
          history.push(
            generatePath(pathname.replace("modify", "detail"), { uuid: uuid })
          );
      })
      .catch((error) => {
        showNotification(
          "Error",
          `${t("core:notifications.update_error")} | ${error}`,
          EUiStates.DANGER
        );
      })
      .finally(() => {
        setIsUpdating(false);
        handleIsModalOpen();
        callAfterUpdate && callAfterUpdate();
      });
  };

  return {
    isModalOpen,
    isUpdating,
    handleUpdate,
    handleIsModalOpen,
    handleSaveForm,
  };
};

export default useUpdateItem;
