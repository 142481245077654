import { ReactNode } from "react";

interface IMainWrapper {
  children: ReactNode;
}

const MainWrapper = ({ children }: IMainWrapper) => (
  <div className="theme-light">
    <div className="wrapper">{children}</div>
  </div>
);

export default MainWrapper;
