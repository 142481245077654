import { MODULES } from "../routes";

export const NFT_KEY_PROJECT = "REISINGER|RW";
export const NFT_ROUTE = `${MODULES}/reisinger`;

export const NFT_PIECES = `${NFT_ROUTE}/nfts/list`;
export const NFT_CHANGE_ORDER = `${NFT_PIECES}/modify-order`;
export const NFT_MODIFY = `${NFT_PIECES}/:uuid/modify`;
export const NFT_CREATE = `${NFT_PIECES}/create`;
export const NFT_DETAIL = `${NFT_PIECES}/:uuid/detail`;
