import { ISelect } from "@interfaces/Shared/select";
import { Field, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

interface IWhenFieldChanges {
  field: string;
  becomes: {
    label: string;
    value: string;
  };
  set: string;
  to: ISelect | ISelect[];
}

const WhenFieldChanges = ({ field, becomes, set, to }: IWhenFieldChanges) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {(value) => {
              if (value.value === becomes.value) {
                onChange(to);
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export default WhenFieldChanges;
