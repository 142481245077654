import {
  HOTV,
  REPORT,
  FEARGREED,
  PARTNER,
  VAULT_CAROUSEL,
  GLANCE,
  CALENDAR,
  BENEFITS,
  ARTICLES,
} from "@constants/routes/Clients/whale.routes";

const WHALESHARK = [
  { route: ARTICLES, name: "whaleshark:common.articles" },
  { route: BENEFITS, name: "whaleshark:common.benefits" },
  { route: CALENDAR, name: "whaleshark:common.calendar" },
  { route: FEARGREED, name: "whaleshark:common.fearGreed" },
  { route: HOTV, name: "whaleshark:common.hotv" },
  { route: PARTNER, name: "whaleshark:common.partner" },
  { route: REPORT, name: "whaleshark:common.report" },
  {
    route: VAULT_CAROUSEL,
    name: "whaleshark:common.vault_carousel",
  },
  { route: GLANCE, name: "whaleshark:common.glance" },
];

export default WHALESHARK;
