import { t } from "i18next";

const textLegth = (text: string, name: string, number: number) => {
  let errMsg = undefined;

  if (text) {
    if (text.length > number) {
      errMsg = `${t("core:validators:text-characters")}`;
    }
  } else {
    errMsg = `${t("core:validators:the-field")} ${name} ${t(
      "core:validators:field-shouldnt-empty"
    )}`;
  }

  return errMsg;
};

export default textLegth;
