import { boxListButtonClass } from "@enums/BoxListButtonCategories/boxListButtonCategories";
import { boxListButtonSpanClass } from "@enums/BoxListButtonCategories/boxListButtonCategories";
import { boxListButtonTooltipTarget } from "@enums/BoxListButtonCategories/boxListButtonCategories";
import { boxListButtonTooltipText } from "@enums/BoxListButtonCategories/boxListButtonCategories";

export const categoryMap = {
  create: {
    buttonClass: boxListButtonClass.Create,
    spanClass: boxListButtonSpanClass.Create,
    tooltipTarget: boxListButtonTooltipTarget.Create,
    tooltipText: boxListButtonTooltipText.Create,
  },
  modify: {
    buttonClass: boxListButtonClass.Modify,
    spanClass: boxListButtonSpanClass.Modify,
    tooltipTarget: boxListButtonTooltipTarget.Modify,
    tooltipText: boxListButtonTooltipText.Modify,
  },
  delete: {
    buttonClass: boxListButtonClass.Delete,
    spanClass: boxListButtonSpanClass.Delete,
    tooltipTarget: boxListButtonTooltipTarget.Delete,
    tooltipText: boxListButtonTooltipText.Delete,
  },
};
