import { ReactElement, useContext, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import AccountIcon from "mdi-react/AccountIcon";
import ProfileMain from "./components/ProfileMain";
import ProfileSettings from "./components/ProfileSettings";
import ModifyConfirmation from "@shared/Modals/ModifyConfirmation";
import UpdateProfile from "@validators/Security/Account/UpdateProfile.validate";
import { TUserContext } from "types/Core/Authentication/userContext";
import UserContext from "@context/UserContext";
import useGetProfile from "@hooks/Security/Account/useGetProfile";
import { useTranslation } from "react-i18next";
import { showNotification } from "@easydev/Notification";
import updateAccount from "@services/Core/Account/editAccount";
import updateAccountMapper from "@mappers/Security/Account/UpdateAccount.mapper";

const Profile = (): ReactElement => {
  const { currentUser, setCurrentUser } = useContext<TUserContext>(UserContext);
  const [modalStatus, setModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<Record<string, any> | undefined>(
    undefined
  );
  const { t } = useTranslation(["core"]);
  const getProfile = useGetProfile(currentUser?.uuid ?? "");
  const profile = getProfile?.data ?? null;

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  const handleUpdateProfile = (FormValues: Record<string, any>) => {
    setFormValues(FormValues);
    handleModalStatus();
  };

  const handleConfirmModify = () => {
    setIsLoading(true);
    const userDto = updateAccountMapper(formValues);

    currentUser &&
      updateAccount(currentUser.uuid, userDto)
        .then(async () => {
          showNotification(
            `${t("core:profile.my_profile")} | ${t("core:common.modify")}`,
            t("core:notifications.user_edit_success"),
            t("core:common.success")
          );

          currentUser.avatar = userDto.avatarUrl;
          currentUser.userName = userDto.username;
          setCurrentUser(currentUser);
        })
        .catch((error: { response: { data: { error: any } } }) => {
          showNotification(
            `${t("core:profile.my_profile")} | ${t("core:common.modify")}`,
            `${t("core:notifications.user_err")} ${
              error?.response?.data?.error
            }`,
            t("core:common.danger")
          );
        })
        .finally(() => {
          setIsLoading(false);
          handleModalStatus();
        });
  };

  return (
    <Container>
      <ModifyConfirmation
        title={`${t("core:profile.my_profile")} | ${t(
          "core:common.modify_confirm"
        )}`}
        body={`${t("core:profile.p_modify_confirm")}`}
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirmModify={handleConfirmModify}
      />
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            <AccountIcon />
            {`${t("core:profile.my_profile")}`}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={4}>
          <Row>
            <ProfileMain profile={profile} isLoading={getProfile.loading} />
          </Row>
        </Col>
        <ProfileSettings
          profile={profile}
          isLoading={getProfile.loading}
          isLoadingUpdate={isLoading}
          onSubmit={handleUpdateProfile}
          validateForms={UpdateProfile}
        />
      </Row>
    </Container>
  );
};

export default Profile;
