import { ElementType } from "react";
import classNames from "classnames";
import Error from "@easydev/form/Error";
import MaskedInput from "react-text-mask";

interface IFormTextareaField {
  input: any;
  meta: {
    touched: boolean;
    error: string;
  };
  Component: ElementType;
  isAboveError: boolean;
  wrapperClassName: string;
}

const FormTextareaField = ({
  input = null,
  meta: { touched = false, error = "" },
  Component = "textarea",
  isAboveError = false,
  wrapperClassName = "",
  ...props
}: IFormTextareaField) => (
  <div
    className={classNames(
      "form__form-group-input-wrap",
      { "form__form-group-input-wrap--error-above": isAboveError },
      wrapperClassName
    )}
  >
    <Component {...props} {...input} />
    {touched && error && <Error error={error} />}
  </div>
);

export const renderComponentField = (component: ElementType) => (props: any) =>
  <FormTextareaField component={component} {...props} />;

export const renderMaskedField = renderComponentField(MaskedInput);

export default FormTextareaField;
