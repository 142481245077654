import { ISelect } from "@interfaces/Shared/select";

const CATEGORIES = {
  PARTNERS: { label: "Chief Partners", value: "1" },
  INVESTORS: { label: "Token Investors", value: "2" },
  WHALE_TEAM: { label: "Whale Team", value: "3" },
};

export const GetCategory = (category: number): ISelect => {
  if (category === 1) {
    return CATEGORIES.PARTNERS;
  }

  if (category === 2) {
    return CATEGORIES.INVESTORS;
  }

  return CATEGORIES.WHALE_TEAM;
};

export const GetCategories = (): ISelect[] => [
  CATEGORIES.PARTNERS,
  CATEGORIES.INVESTORS,
  CATEGORIES.WHALE_TEAM,
];
