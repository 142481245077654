import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";
import urlFieldValidate from "../../../shared/url.validate";

export type TErrors = {
  title: string | undefined;
  reportUrl: string | undefined;
  imageUrl: string | undefined;
};

const ReportValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    title: undefined,
    reportUrl: undefined,
    imageUrl: undefined,
  };

  errors.reportUrl = urlFieldValidate(
    FormValues.reportUrl,
    t("core:common:url"),
    true
  );

  errors.imageUrl = urlFieldValidate(
    FormValues.imageUrl,
    t("core:common:url_image"),
    true
  );

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  return errors;
};

export default ReportValidate;
