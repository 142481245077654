import { API_URL_WHALESHARK_GLANCE } from "@constants/Clients/whaleshark/whaleshark.env";
import IGlanceApi from "@interfaces/Clients/WhaleShark/glance/glance";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetGlance = (uuid: string) =>
  useApiCall<IGlanceApi>({
    url: API_URL_WHALESHARK_GLANCE + uuid,
  });

export default useGetGlance;
