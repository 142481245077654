import { API_URL_REISINGER } from "@constants/Clients/reisinger/reisinger.env";
import apiInstance from "@services/Shared/instanceAxios";
import storageService from "@utils/localStorage.service";

const userLocalStorage = storageService.getLocalUserStorage();

const getAssets = () =>
  apiInstance({
    url: API_URL_REISINGER,
    params: {
      order: "desc",
      sort: "config.order",
      "client.uuid": userLocalStorage?.clientId,
    },
  });

export default getAssets;
