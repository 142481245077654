import { IUser } from "@interfaces/Core/Users/user";
import { Link, generatePath } from "react-router-dom";
import { USERS_MODIFY, USER_DETAIL } from "@constants/routes/routes";
import { ICurrentUser } from "@interfaces/Core/Authentication/currentUser";
import { Button } from "reactstrap";
import ShieldAccountIcon from "mdi-react/ShieldAccountIcon";
import ShieldCrownIcon from "mdi-react/ShieldCrownIcon";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";

const listUserMapper = (
  usersFromApi: IUser[],
  handleActionDeleteUser: Function,
  currentUser: ICurrentUser | null
) => {
  const listUser = usersFromApi.map(
    (
      {
        uuid,
        name,
        lastname,
        username,
        mail,
        createdAt,
        state,
        clientId,
        isSuperAdministrator,
      },
      index
    ) => ({
      order: index + 1,
      uuid,
      name,
      lastname,
      username,
      mail,
      createDate: timestampToString(createdAt),
      clientName: clientId,
      state: <BoxStatus state={state} />,
      superAdmin: isSuperAdministrator ? (
        <ShieldCrownIcon />
      ) : (
        <ShieldAccountIcon />
      ),
      actions: (
        <>
          <Link to={generatePath(USER_DETAIL, { uuid: uuid })}>
            <Button className="btn-sm btn-primary">
              <span className="lnr lnr-user" />
            </Button>
          </Link>
          <Link to={generatePath(USERS_MODIFY, { uuid: uuid })}>
            <Button className="btn-sm btn-warning">
              <span className="lnr lnr-pencil" />
            </Button>
          </Link>
          <Button
            className={`btn-sm ${
              currentUser?.uuid === uuid
                ? "btn-secondary btn-disabled"
                : "btn-danger"
            }`}
            onClick={() => {
              currentUser?.uuid !== uuid &&
                handleActionDeleteUser({
                  uuid,
                  description: mail,
                });
            }}
          >
            <span className="lnr lnr-trash" />
          </Button>
        </>
      ),
    })
  );

  return listUser;
};

export default listUserMapper;
