import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "@components/ModalConfirmation";
import {
  NFT_CREATE,
  NFT_CHANGE_ORDER,
} from "@constants/routes/Clients/reisinger.routes";
import deleteAsset from "@services/Clients/Reisinger/nftPieces/deleteAsset";
import Loader from "@components/Loader";
import Table from "@components/Table";
import Title from "@components/Title";
import useDeleteItem from "@hooks/Shared/useDeleteItem";
import useListColumns from "@hooks/Clients/Reisinger/nftPieces/useListColumns";
import { EUiStates } from "@constants/enum";
import styles from "./ListItems.module.scss";
import useListItems from "@hooks/Shared/useListItems";
import getAssets from "@services/Clients/Reisinger/nftPieces/getAssets";
import listAssetMapper from "@mappers/Clients/Reisinger/nftPieces/ListAsset.mapper";

const ListItems = (): ReactElement => {
  const { t } = useTranslation(["core", "reisinger"]);
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const { isLoading, basicData, tableItems } = useListItems(
    isDeletingItem,
    getAssets,
    listAssetMapper,
    true
  );
  const { isModalOpen, isDeleting, handleDelete, handleIsModalOpen } =
    useDeleteItem(basicData);
  const COLUMNS = useListColumns();

  useEffect(() => {
    setIsDeletingItem(isDeleting);
  }, [isDeleting]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <ModalConfirmation
        icon="trash"
        title={`${t("reisinger:common.nft_pieces")} | ${t(
          "core:common.delete_confirm"
        )}`}
        body={`${t("core:notifications.delete_message")} "${
          basicData?.description
        }" ?`}
        handleConfirm={() => {
          handleDelete(deleteAsset);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.DANGER}
      />
      <Title />

      <div className={styles.btnContainer}>
        <div className={styles.absolute}>
          <Link to={NFT_CHANGE_ORDER}>
            <Button color="warning">{t("core:common.reorder")}</Button>
          </Link>

          <Link to={NFT_CREATE}>
            <Button color="success">{t("core:common.create")} NFT</Button>
          </Link>
        </div>
      </div>

      <Table columns={COLUMNS} items={tableItems} />
    </Container>
  );
};

export default ListItems;
