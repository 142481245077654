import { timelineCategory } from "@constants/Clients/rocketFactory/enum";
import { useEffect, useState } from "react";

const useFormRules = (formValues: Record<string, any>) => {
  const [showDescription, setShowDescription] = useState(false);
  const [showLinkImage, setShowLinkImage] = useState(false);
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    setShowDescription(
      formValues.category?.value === timelineCategory.press ||
        formValues.category?.value === timelineCategory.irlEvents
    );
    setShowLinkImage(formValues.category?.value !== timelineCategory.movies);
    setShowLink(formValues.category?.value !== timelineCategory.launches);
  }, [formValues.category?.value]);

  return {
    showDescription,
    showLinkImage,
    showLink,
  };
};

export default useFormRules;
