import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";
import ITimeline from "@interfaces/Clients/RocketFactory/timeline/timeline";
import {
  TIMELINE_DETAIL,
  TIMELINE_MODIFY,
} from "@constants/routes/Clients/rocketFactory.routes";

const listMapper = (itemsFromAPI: ITimeline[], handleDelete: Function) =>
  itemsFromAPI.map(
    ({ uuid, title, date, category, state, createdAt }, itemIndex) => ({
      uuid,
      order: itemIndex + 1,
      title,
      publishDate: timestampToString(date),
      category,
      createdAt: timestampToString(createdAt),
      state: <BoxStatus state={state} />,
      actions: (
        <BoxListButton
          uuid={uuid}
          description={title}
          detailRoute={TIMELINE_DETAIL}
          modifyRoute={TIMELINE_MODIFY}
          handleDelete={handleDelete}
        />
      ),
    })
  );

export default listMapper;
