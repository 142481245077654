import { IProfile } from "@interfaces/Core/Account/profile";
import { ReactElement } from "react";
import { Card, CardBody, Col } from "reactstrap";
import Loader from "@components/Loader";
import { useTranslation } from "react-i18next";
import avatar_logo from "@images/account/avatar.png";

interface IProfileMain {
  profile: IProfile | null;
  isLoading: boolean;
}

const ProfileMain = ({ profile, isLoading }: IProfileMain): ReactElement => {
  const { t } = useTranslation("core");

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          {isLoading ? (
            <Loader isInsideThing />
          ) : (
            <>
              <div className="profile__information">
                <div className="profile__avatar">
                  <img src={profile?.avatar ?? avatar_logo} alt="avatar" />
                </div>
                <div className="profile__data">
                  <p className="profile__name">
                    {profile?.name} {profile?.lastname}
                  </p>
                  <p className="profile__work">@{profile?.username}</p>
                  <p className="profile__contact">
                    <a href={`mailto:${profile?.mail}`}>{profile?.mail}</a>
                  </p>
                </div>
              </div>

              <div className="profile__stats">
                <div className="profile__stat">
                  <p className="profile__stat-number">
                    {t("core:common.client")}
                  </p>
                  <p className="profile__stat-title">
                    <span className="panel__label badge badge-secondary">
                      {profile?.client.name}
                    </span>
                  </p>
                </div>
                <div className="profile__stat">
                  <p className="profile__stat-number">
                    {t("core:common.projects")}
                  </p>
                  {profile?.projects.map(({ name, uuid }) => (
                    <p key={uuid} className="profile__stat-title">
                      <span className="panel__label badge badge-secondary">
                        {name}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;
