import { API_URL_RF_TIMELINE } from "@constants/Clients/rocketFactory/rocketFactory.env";
import apiInstance from "@services/Shared/instanceAxios";

const getItems = () =>
  apiInstance({
    url: API_URL_RF_TIMELINE,
    params: {
      order: "desc",
      sort: "date",
    },
  });

export default getItems;
