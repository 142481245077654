import { API_URL_REISINGER } from "@constants/Clients/reisinger/reisinger.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TUpdateAssetDto } from "types/Clients/Reisinger/updateAssetDto";

const updateAsset = (uuid: string, editAsset: TUpdateAssetDto) =>
  apiInstance({
    url: API_URL_REISINGER + uuid,
    method: "put",
    data: editAsset,
  });

export default updateAsset;
