import { ReactElement } from "react";
import Modify from "@shared/FormTemplates/Modify";
import ModifyUserForm from "./ModifyForm";
import updateUser from "@services/Core/Users/updateUser";
import useGetUser from "@hooks/Security/Users/useGetUser";
import validator from "@validators/Security/Users/EditUser.validate";
import formMapper from "@mappers/Security/Users/EditUser.mapper";

const ModifyUser = (): ReactElement => (
  <Modify
    CustomForm={ModifyUserForm}
    modifyHook={updateUser}
    detailHook={useGetUser}
    validator={validator}
    formMapper={formMapper}
  />
);

export default ModifyUser;
