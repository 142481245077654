import TCreateCarouselItem from "types/Clients/WhaleShark/vaultCarousel/carouselItem";

const vaultItemMapper = (formValues: any): TCreateCarouselItem => ({
  title: formValues.title,
  imageUrl: formValues.imageUrl,
  openSeaUrl: formValues.openSeaUrl,
  state: Boolean(Number(formValues.state.value)),
});

export default vaultItemMapper;
