import { useEffect, useState } from "react";
import { ISelect } from "@interfaces/Shared/select";
import selectClients from "@services/Core/ClientManager/selectClients";

interface IUseSelectClients {
  isLoading: boolean;
  clientsValues: ISelect[];
}

const useSelectClients = (): IUseSelectClients => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientsValues, setClientsValues] = useState<ISelect[]>([]);

  useEffect(() => {
    setIsLoading(true);

    selectClients()
      .then(async ({ data }) => {
        setClientsValues(data);
      })
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    clientsValues,
  };
};

export default useSelectClients;
