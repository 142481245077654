import { ReactElement } from "react";
import Create from "@shared/FormTemplates/Create";
import CreateClientForm from "./CreateForm";
import createUser from "@services/Core/ClientManager/createClient";
import validator from "@validators/Security/Clients/client.validate";
import formMapper from "@mappers/Security/Clients/Client.mapper";

const CreateClient = (): ReactElement => (
  <Create
    CustomForm={CreateClientForm}
    createHook={createUser}
    validator={validator}
    formMapper={formMapper}
  />
);

export default CreateClient;
