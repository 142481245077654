import apiInstance from "@services/Shared/instanceAxios";
import { TUpdateAccountDto } from "types/Core/Account/updateAccountDto";

const updateAccount = (uuid: string, editAccount: TUpdateAccountDto) =>
  apiInstance({
    url: "/account/" + uuid,
    method: "put",
    data: editAccount,
  });

export default updateAccount;
