import { ReactElement } from "react";
import { generatePath } from "react-router-dom";
import { Button } from "reactstrap";
import styles from "./BoxEditor.module.scss";
import { RichTextView } from "@nftstudios/rich-text-editor-component";

export interface IBoxEditor {
  body: string;
  id?: string;
  showPreview?: boolean;
  pathPreview?: string;
}

const BoxEditor = ({
  body,
  id,
  showPreview,
  pathPreview,
}: IBoxEditor): ReactElement => {
  return (
    <div className={styles.box__container}>
      <div className={styles.box__editor}>
        <RichTextView className={styles.box__body}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </RichTextView>
      </div>
      {showPreview && pathPreview && (
        <a
          className={styles.box_button}
          href={generatePath(pathPreview, { uuid: id })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button size="sm" color="primary" type="button">
            <span className="lnr lnr-screen" /> Preview
          </Button>
        </a>
      )}
    </div>
  );
};

export default BoxEditor;
