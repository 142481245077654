import { API_URL_REISINGER } from "@constants/Clients/reisinger/reisinger.env";
import apiInstance from "@services/Shared/instanceAxios";

const deleteAsset = (uuid: string) =>
  apiInstance({
    url: API_URL_REISINGER + uuid,
    method: "delete",
  });

export default deleteAsset;
