import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { ACCOUNT, LOCKSCREEN, LOGIN, SECURITY } from "@constants/routes/routes";
import UserContext from "@context/UserContext";
import { TUserContext } from "types/Core/Authentication/userContext";
import Layout from "@pages/Layout/Layout";
import Security from "./Security/Security";
import Account from "./Security/Account";
import HackataoModules from "./Clients/HackataoModules";
import ReisingerModules from "./Clients/ReisingerModules";
import WhaleSharkModules from "./Clients/WhaleSharkModules";
import PrivateRoute from "../PrivateRoute";
import { KEY_PROJECT } from "@constants/env";
import {
  HACKATAO_KEY_PROJECT,
  HACKATAO_ROUTE,
} from "@constants/routes/Clients/hackatao.routes";
import {
  NFT_KEY_PROJECT,
  NFT_ROUTE,
} from "@constants/routes/Clients/reisinger.routes";
import {
  WHALESHARK_ROUTE,
  WW_KEY_PROJECT,
} from "@constants/routes/Clients/whale.routes";
import Loader from "@components/Loader";
import {
  RF_KEY_PROJECT,
  RF_ROUTE,
} from "@constants/routes/Clients/rocketFactory.routes";
import RocketFactoryModules from "./Clients/RocketFactoryModules";

const WrappedRoutes = () => {
  const { authenticated, isInitialized, isLoading, locked } =
    useContext<TUserContext>(UserContext);

  if (!authenticated && !isInitialized) {
    return <Redirect to={LOGIN} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (locked) {
    return <Redirect to={LOCKSCREEN} />;
  }

  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route path={ACCOUNT} component={Account} />

        <PrivateRoute
          resourceId={KEY_PROJECT}
          path={SECURITY}
          component={Security}
        />

        <PrivateRoute
          resourceId={NFT_KEY_PROJECT}
          path={NFT_ROUTE}
          component={ReisingerModules}
        />

        <PrivateRoute
          resourceId={WW_KEY_PROJECT}
          path={WHALESHARK_ROUTE}
          component={WhaleSharkModules}
        />

        <PrivateRoute
          resourceId={HACKATAO_KEY_PROJECT}
          path={HACKATAO_ROUTE}
          component={HackataoModules}
        />

        <PrivateRoute
          resourceId={RF_KEY_PROJECT}
          path={RF_ROUTE}
          component={RocketFactoryModules}
        />
      </div>
    </div>
  );
};

export default WrappedRoutes;
