import emptyFieldValidate from "@validators/shared/empty.validate";

export type TErrors = {
  name: string | undefined;
  key: string | undefined;
  client: string | undefined;
};

const projectValidator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    key: undefined,
    client: undefined,
  };

  errors.name = emptyFieldValidate(FormValues.name, "Name");

  errors.key = emptyFieldValidate(FormValues.key, "Key");

  errors.client = emptyFieldValidate(FormValues.client, "Client");

  return errors;
};

export default projectValidator;
