import { IAsset } from "@interfaces/Clients/Reisinger/asset";

const orderListAssets = (usersFromApi: IAsset[]) => {
  const listAsset = usersFromApi.map(({ uuid, name, config }) => ({
    uuid,
    name,
    order_old: config.order,
    order_new: config.order,
  }));

  return listAsset;
};

export default orderListAssets;
