import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";

export type TErrors = {
  [key: string]: string | undefined;
};

const formValidator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    title: undefined,
    linkImage: undefined,
  };

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.linkImage = emptyFieldValidate(
    FormValues.linkImage,
    t("core:common:url_image")
  );

  return errors;
};

export default formValidator;
