import { API_URL } from "@constants/env";
import axios, { AxiosRequestConfig } from "axios";
import storageService from "@utils/localStorage.service";

const defaultOptions = {
  baseURL: API_URL,
};

// Create instance
let apiInstance = axios.create(defaultOptions);

apiInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  const userLocalStorage = storageService.getLocalUserStorage();

  if (config.headers && userLocalStorage?.token) {
    config.headers.Authorization = userLocalStorage.token;
  }

  return config;
});

export default apiInstance;
