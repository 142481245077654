import { useContext } from "react";
import UserContext from "@context/UserContext";
import { TUserContext } from "types/Core/Authentication/userContext";
import LockScreenForm from "./components/LockScreenForm";
import { Redirect } from "react-router-dom";
import { HOME } from "@constants/routes/routes";
import Loader from "@components/Loader";
import { useTranslation } from "react-i18next";

const LockScreen = () => {
  const { t } = useTranslation("core");
  const {
    locked,
    currentUser,
    isLoading,
    errorAuthMessage,
    HandleUnLockScreen,
  } = useContext<TUserContext>(UserContext);

  if (!locked) {
    return <Redirect to={HOME} />;
  }
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__profile">
            <img
              className="account__avatar"
              src={currentUser?.avatar}
              alt="avatar logo"
            />
            <p className="account__name">{currentUser?.userName}</p>
            <p className="account__sub">{t("core:profile.p_unlock_message")}</p>
          </div>
          {isLoading ? (
            <Loader isInsideThing />
          ) : (
            <LockScreenForm
              onSubmit={HandleUnLockScreen}
              errorMessage={errorAuthMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LockScreen;
