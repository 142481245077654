import { useState } from "react";
import { ISelect } from "@interfaces/Shared/select";
import selectProjects from "@services/Core/ProjectManager/selectProjects";

interface IUseSelectProjects {
  isLoading: boolean;
  projectsValues: ISelect[];
  handleChangeSelect: (val: string) => void;
}

const useSelectProjects = (): IUseSelectProjects => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectsValues, setProjectsValues] = useState<ISelect[]>([]);

  const handleChangeSelect = (selectedOption: string) => {
    if (selectedOption !== null) {
      setIsLoading(true);
      selectProjects(selectedOption)
        .then(async (response) => {
          setProjectsValues(response.data);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return {
    isLoading,
    projectsValues,
    handleChangeSelect,
  };
};

export default useSelectProjects;
