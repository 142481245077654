import { ReactElement } from "react";
import Detail from "@shared/FormTemplates/Detail";
import DetailUserForm from "./DetailForm";
import useGetUser from "@hooks/Security/Users/useGetUser";

const DetailUser = (): ReactElement => (
  <Detail CustomForm={DetailUserForm} detailHook={useGetUser} />
);

export default DetailUser;
