import { ISelect } from "@interfaces/Shared/select";
import { useTranslation } from "react-i18next";

interface IUseSuperusers {
  GetSuperuser: (state: boolean) => ISelect;
  GetSuperusers: () => ISelect[];
}

const useSuperusers = (): IUseSuperusers => {
  const { t } = useTranslation(["core"]);

  const GetSuperuser = (state: boolean) => {
    if (state) {
      return { label: t("core:common.yes"), value: "1" };
    }

    return { label: t("core:common.no"), value: "0" };
  };

  const GetSuperusers = () => {
    return [
      { label: t("core:common.yes"), value: "1" },
      { label: t("core:common.no"), value: "0" },
    ];
  };

  return { GetSuperuser, GetSuperusers };
};

export default useSuperusers;
