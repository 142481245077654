import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";
import textLegthValidate from "../../../shared/textLegth.validate";

export type TErrors = {
  [key: string]: string | undefined;
};

const formValidator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    title: undefined,
    description: undefined,
    order: undefined,
  };

  errors.title = textLegthValidate(
    FormValues.title,
    t("core:common:title"),
    50
  );

  errors.description = textLegthValidate(
    FormValues.description,
    t("core:common:description"),
    50
  );

  errors.order = emptyFieldValidate(FormValues.order, t("core:common:order"));

  return errors;
};

export default formValidator;
