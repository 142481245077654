import { ReactElement } from "react";
import { Col } from "reactstrap";
import ButtonContainer from "@components/ButtonContainer";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import AccountConvertIcon from "mdi-react/AccountConvertIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { useTranslation } from "react-i18next";
import { GetCategory } from "@mappers/Clients/WhaleShark/partner/getCategory.mapper";
import IPartner from "@interfaces/Clients/WhaleShark/partner/partner";
import BoxImage from "@components/BoxImage";

interface IDetailForm {
  data: IPartner;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);
  const categoryName = data && GetCategory(data.category).label;

  return (
    <form className="form">
      {data && (
        <Col md={5}>
          <BoxImage urlImage={data.imageUrl} />
        </Col>
      )}
      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.name")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <FormatTitleIcon />
            </div>
            <input type="text" value={data?.name} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.category")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountConvertIcon />
            </div>
            <input type="text" value={categoryName} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.order")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <OrderNumericAscendingIcon />
            </div>
            <input type="text" value={data?.order} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.url_image")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <input type="text" value={data?.imageUrl} disabled />
            <a href={data?.imageUrl} target="_blank" rel="noopener noreferrer">
              <div className="form__form-group-icon">
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
