import { ReactElement, MouseEventHandler } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import burger_logo from "@images/button/burger.svg";

interface IButton {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className: string;
}

const Button = ({ onClick, className }: IButton): ReactElement => (
  <button
    onClick={onClick}
    className={classnames("topbar__button", className)}
    type="button"
  >
    <img src={burger_logo} alt="button" className="topbar__button-icon" />
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default Button;
