import classNames from "classnames";
import { useState } from "react";
import Error from "@components/EasyDev/form/Error";
import EditableArrayHeader from "./components/EditableArrayHeader";
import EditableArrayBody from "./components/EditableArrayBody";
import { IEditableArrayColumn } from "@interfaces/Shared/editableArrayColumn";
import "./EditableArray.scss";

export interface IEditableArray<T> {
  isAboveError?: boolean;
  wrapperClassName?: string;
  input: {
    onChange?: (event: T[]) => void;
    value: T[];
  };
  meta?: { error?: any };
  columns: IEditableArrayColumn[];
  maxLength?: number;
  disabled?: boolean;
  className?: string;
}

const EditableArray = <T extends Record<string, any>>({
  isAboveError,
  wrapperClassName,
  input,
  meta,
  columns,
  maxLength,
  disabled,
  className,
}: IEditableArray<T>) => {
  const [rows, setRows] = useState(input.value);

  const setNewArray = (newRows: T[]) => {
    setRows(newRows);
    input.onChange && input.onChange(newRows);
  };

  return (
    <div
      className={classNames(
        "editable__array-container",
        {
          "form__form-group-field": !className,
        },
        className
      )}
    >
      <div
        className={classNames(
          "form__form-group-input-wrap",
          { "form__form-group-input-wrap--error-above": isAboveError },
          wrapperClassName
        )}
      >
        <EditableArrayHeader
          rows={rows}
          columns={columns}
          setNewArray={setNewArray}
          maxLength={maxLength}
          disabled={disabled}
        />
        <EditableArrayBody
          columns={columns}
          rows={rows}
          setNewArray={setNewArray}
          disabled={disabled}
        />
        {meta?.error && <Error error={meta.error} />}
      </div>
    </div>
  );
};

export default EditableArray;
