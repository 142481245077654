import useApiCall from "@hooks/Shared/useApiCall";
import IGridAvatars from "@interfaces/Clients/Hackatao/gridAvatars/gridAvatars";
import { API_URL_HACKATAO_GRID_AVATARS } from "@constants/Clients/hackatao/hackatao.env";

const useGetGridAvatars = (uuid: string) =>
  useApiCall<IGridAvatars>({
    url: API_URL_HACKATAO_GRID_AVATARS + uuid,
  });

export default useGetGridAvatars;
