import apiInstance from "@services/Shared/instanceAxios";
import { TCreateEditProject } from "types/Core/ProjectManager/createEditProject";

const updateProject = (uuid: string, newItem: TCreateEditProject) =>
  apiInstance({
    url: `/projects/${uuid}`,
    method: "put",
    data: newItem,
  });

export default updateProject;
