import { ISelect } from "@interfaces/Shared/select";
import { useTranslation } from "react-i18next";

interface IUseStates {
  GetState: (state: boolean) => ISelect;
  GetStates: () => ISelect[];
}

const useStates = (): IUseStates => {
  const { t } = useTranslation(["core"]);

  const GetState = (state: boolean) => {
    if (state) {
      return { label: t("core:common.enabled"), value: "1" };
    }

    return { label: t("core:common.disabled"), value: "0" };
  };

  const GetStates = () => {
    return [
      { label: t("core:common.enabled"), value: "1" },
      { label: t("core:common.disabled"), value: "0" },
    ];
  };

  return { GetState, GetStates };
};

export default useStates;
