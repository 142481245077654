import { useEffect, useState, ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import { UserProvider } from "@context/UserProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { config as i18nextConfig } from "../../translations";
import "bootstrap/dist/css/bootstrap.css";
import "@/scss/app.scss";
import storageService from "@utils/localStorage.service";

i18n.init(i18nextConfig);
i18n.changeLanguage(storageService.getI18nStorage());

const App = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener("load", onLoad);

    return () => window.removeEventListener("load", onLoad);
  }, []);

  const onLoad = () => {
    setIsLoading(false);
    setTimeout(() => setIsLoaded(true), 500);
  };

  return (
    <UserProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ScrollToTop>
            <>
              {!isLoaded && (
                <div className={`load${isLoading ? "" : " loaded"}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path
                        fill="#4ce1b6"
                        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div>
                <Router />
              </div>
            </>
          </ScrollToTop>
        </I18nextProvider>
      </BrowserRouter>
    </UserProvider>
  );
};

export default App;
