import { t } from "i18next";
import passwordLengthValidate from "../../shared/passwordLength.validate";

export type TErrors = {
  password: string | undefined;
};

const loginValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = { password: undefined };

  errors.password = passwordLengthValidate(
    FormValues.password,
    t("core:common:password")
  );

  return errors;
};

export default loginValidate;
