import useStates from "@mappers/Shared/getState.mapper";
import { ReactElement } from "react";
import styles from "./BoxStatus.module.scss";

interface IBoxStatus {
  state: boolean;
}

const badgeClassname = {
  true: "badge-success",
  false: "badge-danger",
};

const BoxStatus = ({ state }: IBoxStatus): ReactElement => {
  const { GetState } = useStates();
  return (
    <span
      className={`badge ${badgeClassname[`${state}`]} ${styles.badgeCustom}`}
    >
      {GetState(state).label}
    </span>
  );
};

export default BoxStatus;
