import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditClient from "types/Core/ClientManager/createEditClient";

const updateClient = (uuid: string, newItem: TCreateEditClient) =>
  apiInstance({
    url: `/clients/${uuid}`,
    method: "put",
    data: newItem,
  });

export default updateClient;
