import { ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";
import ResetPasswordForm from "@easydev/reset_password/ResetPasswordForm";
import forgotPasswordValidate from "@validators/Security/Account/ForgotPassword.validate";
import { LOGIN } from "@constants/routes/routes";
import logo from "@images/shared/logo.png";
import { useTranslation } from "react-i18next";
import resetPassword from "@services/Core/Account/resetPassword";
import Loader from "@components/Loader";

const ForgotPassword = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageReset, setErrorMessageReset] = useState("");
  const [successMessageReset, setSuccessMessageReset] = useState("");
  const { t } = useTranslation(["core"]);

  const handleForgotPassword = (FormValues: Record<string, any>) => {
    setIsLoading(true);

    resetPassword(FormValues.email)
      .then(async (result) => {
        setSuccessMessageReset(t("core:profile.success_remember_password"));
        setErrorMessageReset("");
      })
      .catch((error) => {
        setErrorMessageReset(error?.response?.data?.error);
        setSuccessMessageReset("");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <img src={logo} alt="logo" />
            </h3>
          </div>
          {isLoading ? (
            <Loader isInsideThing />
          ) : (
            <ResetPasswordForm
              onSubmit={(FormValues) => {
                handleForgotPassword(FormValues);
              }}
              validate={forgotPasswordValidate}
              errorMessage={errorMessageReset}
              successMessage={successMessageReset}
            />
          )}

          <div className="account__have-account">
            <p>
              {t("core:profile.remember_password")}{" "}
              <NavLink to={LOGIN}>{t("core:profile.login")}</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
