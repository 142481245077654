import { ReactElement } from "react";
import Detail from "@shared/FormTemplates/Detail";
import DetailForm from "./DetailForm";
import useGetMainAvatars from "@hooks/Clients/Hackatao/mainAvatars/useGetMainAvatars";

const DetailItem = (): ReactElement => (
  <Detail CustomForm={DetailForm} detailHook={useGetMainAvatars} />
);

export default DetailItem;
