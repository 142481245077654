import { useState, useEffect, useContext } from "react";
import TObjectFilledWithStrings from "types/Shared/objectFilledWithStrings";
import reorderList from "@mappers/Shared/reorderList.mapper";
import UserContext from "@context/UserContext";

const useListItems = <T>(
  isDeletingItem: boolean,
  getListFunction: Function,
  mapper: any,
  skipReorder = false
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableItems, setTableItems] = useState<T[] | undefined>(undefined);
  const [basicData, setBasicData] = useState<
    TObjectFilledWithStrings | undefined
  >(undefined);
  const { currentUser } = useContext(UserContext);

  const handleSetBasicData = ({
    uuid,
    description,
  }: TObjectFilledWithStrings) => {
    setBasicData({
      uuid,
      description,
    });
  };

  useEffect(() => {
    if (tableItems) {
      const listFiltered = tableItems?.filter(
        ({ uuid }: any) => uuid !== basicData?.uuid
      );
      !skipReorder
        ? setTableItems(reorderList(listFiltered))
        : setTableItems(listFiltered);
    } else {
      setIsLoading(true);
      getListFunction()
        .then(async (response: { data: T[] }) => {
          setTableItems(mapper(response.data, handleSetBasicData, currentUser));
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletingItem]);

  return {
    isLoading,
    basicData,
    tableItems,
  };
};

export default useListItems;
