import TCreateEditProject from "types/Core/ProjectManager/createEditProject";

const projectMapper = (formValues: any): TCreateEditProject => {
  return {
    name: formValues.name,
    key: formValues.key,
    clientId: formValues.client?.value,
    logoUrl: formValues.logoUrl,
    websiteUrl: formValues.websiteUrl,
  };
};

export default projectMapper;
