import { API_URL_WHALESHARK_FGINDEX } from "@constants/Clients/whaleshark/whaleshark.env";
import IFearGreed from "@interfaces/Clients/WhaleShark/fearGreed/fearGreed";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetFearGreed = (uuid: string) =>
  useApiCall<IFearGreed>({
    url: API_URL_WHALESHARK_FGINDEX + uuid,
  });

export default useGetFearGreed;
