import { IClient } from "@interfaces/Core/Client/client";
import { ISelect } from "@interfaces/Shared/select";
import apiInstance from "@services/Shared/instanceAxios";

const selectClients = () =>
  apiInstance({
    url: "/clients/",
    transformResponse: [
      (data) => {
        let res = JSON.parse(data);

        const clientValues: ISelect[] = res.map((client: IClient) => ({
          value: client.uuid,
          label: client.name,
        }));

        return clientValues;
      },
    ],
  });

export default selectClients;
