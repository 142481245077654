import { API_URL_REISINGER_ORDER } from "@constants/Clients/reisinger/reisinger.env";
import { IOrderAsset } from "@interfaces/Clients/Reisinger/orderAsset";
import apiInstance from "@services/Shared/instanceAxios";

const reorderAssets = (newAssets: IOrderAsset[]) =>
  apiInstance({
    url: API_URL_REISINGER_ORDER,
    method: "patch",
    data: newAssets,
  });

export default reorderAssets;
