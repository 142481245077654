import { ReactElement } from "react";
import ButtonContainer from "@components/ButtonContainer";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import { useTranslation } from "react-i18next";
import BoxStatus from "@components/BoxStatus";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import { timestampToString } from "@helpers/dates.format";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import ITimeline from "@interfaces/Clients/RocketFactory/timeline/timeline";
import useGetSocials from "@hooks/Clients/RocketFactory/timeline/useGetSocials";
import TwitterIcon from "mdi-react/TwitterIcon";
import InstagramIcon from "mdi-react/InstagramIcon";
import DiscordIcon from "mdi-react/DiscordIcon";
import TextLongIcon from "mdi-react/TextLongIcon";
import FormatListBulletedTypeIcon from "mdi-react/FormatListBulletedTypeIcon";
import timelineRules from "@helpers/Clients/RocketFactory/timelineRules.form";
import { getTimelineCategory } from "@helpers/Clients/RocketFactory/getTimelineCategory";

interface IDetailForm {
  data: ITimeline;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["rocketFactory", "core"]);
  const initialCategory = getTimelineCategory(data?.category);
  const { socialInstagram, socialTwitter, socialDiscord } = useGetSocials(data);
  const {
    showSubtitle,
    showLinkImage,
    showLinkVideo,
    showLink,
    showSocials,
    showDateEnd,
  } = timelineRules(data);

  return (
    <form className="form form--horizontal">
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.category")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatListBulletedTypeIcon />
          </div>
          <input type="text" value={initialCategory?.label} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <ViewHeadlineIcon />
          </div>
          <input type="text" value={data?.title} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.publish_date")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarMonthIcon />
          </div>
          <input type="text" value={timestampToString(data?.date)} disabled />
        </div>
      </div>

      {showDateEnd && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.closing_date")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarMonthIcon />
            </div>
            <input
              type="text"
              value={timestampToString(data?.dateEnd)}
              disabled
            />
          </div>
        </div>
      )}

      {showSubtitle && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.subtitle")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <ViewHeadlineIcon />
            </div>
            <input type="text" value={data?.subtitle ?? "-"} disabled />
          </div>
        </div>
      )}

      {showLinkImage && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.image_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            {data?.image === null ? (
              <input type="text" value={"-"} disabled />
            ) : (
              <a
                className="form__form-group-link"
                href={data?.image}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.image}
              </a>
            )}
          </div>
        </div>
      )}

      {showLinkVideo && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.video_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            {data?.linkVideo === null ? (
              <input type="text" value={"-"} disabled />
            ) : (
              <a
                className="form__form-group-link"
                href={data?.linkVideo}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.linkVideo}
              </a>
            )}
          </div>
        </div>
      )}

      {showLink && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.external_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            {data?.link === null ? (
              <input type="text" value={"-"} disabled />
            ) : (
              <a
                className="form__form-group-link"
                href={data?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.link}
              </a>
            )}
          </div>
        </div>
      )}

      {data?.description && (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.description")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <TextLongIcon />
            </div>
            {data?.description !== null ? (
              <textarea value={data?.description} disabled />
            ) : (
              <input type="text" value={"-"} disabled />
            )}
          </div>
        </div>
      )}

      {showSocials && (
        <>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <InstagramIcon />
              </div>
              {socialInstagram ? (
                <a
                  className="form__form-group-link"
                  href={socialInstagram.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialInstagram.link}
                </a>
              ) : (
                <input type="text" value={"-"} disabled />
              )}
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.socials")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <TwitterIcon />
              </div>
              {socialTwitter ? (
                <a
                  className="form__form-group-link"
                  href={socialTwitter.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialTwitter.link}
                </a>
              ) : (
                <input type="text" value={"-"} disabled />
              )}
            </div>
          </div>

          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <DiscordIcon />
              </div>
              {socialDiscord ? (
                <a
                  className="form__form-group-link"
                  href={socialDiscord.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialDiscord.link}
                </a>
              ) : (
                <input type="text" value={"-"} disabled />
              )}
            </div>
          </div>
        </>
      )}

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.created_at")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarPlusIcon />
          </div>
          <input value={timestampToString(data?.createdAt)} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.updated_at")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarEditIcon />
          </div>
          <input value={timestampToString(data?.updatedAt)} disabled />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <BoxStatus state={data?.state} />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
