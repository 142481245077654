import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import FormField from "@easydev/form/FormField";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";
import { Alert, Button } from "reactstrap";

const ResetPasswordForm = ({
  onSubmit,
  fieldUser,
  validate,
  errorMessage,
  successMessage,
}) => (
  <Form
    onSubmit={(FormValues) => onSubmit(FormValues)}
    validate={(FormValues) => validate(FormValues)}
  >
    {({ handleSubmit }) => (
      <form className="form reset-password-form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <div>
            <span className="form__form-group-label">{fieldUser}</span>
          </div>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AlternateEmailIcon />
            </div>
            <Field
              name="email"
              component={FormField}
              type="email"
              placeholder="example@mail.com"
              isAboveError
              data-testid="forgot-password-email-input"
            />
          </div>
        </div>
        <Alert
          className="account__btn"
          color="danger"
          isOpen={!!errorMessage}
          data-testid="error-alert"
        >
          <AlertCircleOutlineIcon /> {errorMessage}
        </Alert>
        <Alert
          className="account__btn"
          color="success"
          isOpen={!!successMessage}
          data-testid="success-alert"
        >
          <AlertCircleOutlineIcon /> {successMessage}
        </Alert>
        <Button
          className="account__btn"
          type="submit"
          color="primary"
          data-testid="reset-password-btn"
        >
          Reset Password
        </Button>
      </form>
    )}
  </Form>
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
};

ResetPasswordForm.defaultProps = {
  fieldUser: null,
};

export default ResetPasswordForm;
