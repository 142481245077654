import { TUpdateAccountDto } from "types/Core/Account/updateAccountDto";

const updateAccountMapper = (formValues: any): TUpdateAccountDto => {
  return {
    username: formValues.username,
    name: formValues.name,
    lastname: formValues.lastname,
    avatarUrl: formValues.avatarUrl === "" ? null : formValues.avatarUrl,
  };
};

export default updateAccountMapper;
