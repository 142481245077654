import {
  CLAIMTRAIT,
  GRIDAVATARS,
  LEARNMORE,
  MAINAVATARS,
} from "@constants/routes/Clients/hackatao.routes";

const HACKATAO = [
  { route: CLAIMTRAIT, name: "hackatao:common.claimTrait" },
  { route: GRIDAVATARS, name: "hackatao:common.gridAvatars" },
  { route: LEARNMORE, name: "hackatao:common.learnMore" },
  { route: MAINAVATARS, name: "hackatao:common.mainAvatars" },
];

export default HACKATAO;
