import { API_URL_REISINGER } from "@constants/Clients/reisinger/reisinger.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TCreateAssetDto } from "types/Clients/Reisinger/createAssetDto";

const createAsset = (newAsset: TCreateAssetDto) =>
  apiInstance({
    url: API_URL_REISINGER,
    method: "post",
    data: newAsset,
  });

export default createAsset;
