import { ReactElement } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  ButtonToolbar,
  Button,
} from "reactstrap";
import ModalConfirmation from "@components/ModalConfirmation";
import FormTextField from "@components/Shared/FormTextField";
import Loader from "@components/Loader";
import calendarMapper from "@mappers/Clients/WhaleShark/calendar/calendar.mapper";
import formValidator from "@validators/Clients/WhaleShark/calendar/Calendar.validate";
import updateItem from "@services/Clients/WhaleShark/calendar/updateItem";
import useCalendar from "@hooks/Clients/WhaleShark/calendar/useCalendar";
import useUpdateItem from "@hooks/Shared/useUpdateItem";
import { EUiStates } from "@constants/enum";
import TitleIcon from "mdi-react/FormatTitleIcon";
import CalendarIcon from "mdi-react/CalendarIcon";
import LinkIcon from "mdi-react/LinkVariantIcon";
import BoxImage from "@components/BoxImage";
import { timestampToString } from "@helpers/dates.format";

const Calendar = (): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const { isLoading, data, handleCallApi } = useCalendar();

  const {
    isUpdating,
    isModalOpen,
    handleSaveForm,
    handleUpdate,
    handleIsModalOpen,
  } = useUpdateItem();

  if (isLoading || isUpdating) {
    return <Loader />;
  }

  return (
    <Container>
      <ModalConfirmation
        icon="pencil"
        title={`${t("whaleshark:common.calendar")} | ${t(
          "core:common.modify_confirm"
        )}`}
        body={t("core:notifications.modify_confirm")}
        handleConfirm={() => {
          data?.uuid && handleUpdate(updateItem, data.uuid, handleCallApi);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.WARNING}
      />

      <Row>
        <Col>
          <h3 className="page-title">
            <CalendarIcon />
            Whale Shark | {t("whaleshark:common.calendar")}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Form
                onSubmit={(FormValues) => {
                  handleSaveForm(FormValues, calendarMapper);
                }}
                validate={formValidator}
              >
                {({ handleSubmit }) => (
                  <form
                    className="form form--horizontal"
                    onSubmit={handleSubmit}
                  >
                    {data && (
                      <Col md={6}>
                        <BoxImage urlImage={data.linkImage} />
                      </Col>
                    )}
                    <Col md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t("core:common.title")}
                        </span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <TitleIcon />
                          </div>
                          <Field
                            name="title"
                            component={FormTextField}
                            type="text"
                            placeholder={t("core:common.title")}
                            isAboveError
                            initialValue={data?.title}
                          />
                        </div>
                      </div>

                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t("core:common.url")}
                        </span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <LinkIcon />
                          </div>
                          <Field
                            name="linkImage"
                            component={FormTextField}
                            type="text"
                            placeholder="https://...calendar.png"
                            initialValue={data?.linkImage}
                            isAboveError
                          />
                        </div>
                      </div>

                      {data?.updatedAt && (
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t("core:common.updated_at")}
                          </span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <CalendarIcon />
                            </div>
                            <input
                              type="text"
                              value={timestampToString(data?.updatedAt)}
                              disabled
                            />
                          </div>
                        </div>
                      )}

                      <ButtonToolbar className="form__button-toolbar">
                        <Button className="btn-warning">
                          <span className="lnr lnr-pencil" />{" "}
                          {t("core:common.modify")}
                        </Button>
                      </ButtonToolbar>
                    </Col>
                  </form>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Calendar;
