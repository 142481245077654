import { t } from "i18next";
import emptyFieldValidate from "@validators/shared/empty.validate";

type TErrors = {
  name: string | undefined;
  key: string | undefined;
  logoUrl: string | undefined;
};

const ClientValidator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    key: undefined,
    logoUrl: undefined,
  };

  errors.name = emptyFieldValidate(FormValues.name, t("core:common:name"));

  errors.key = emptyFieldValidate(FormValues.key, t("core:common:key"));

  errors.logoUrl = emptyFieldValidate(
    FormValues.logoUrl,
    t("core:common:url_image")
  );

  return errors;
};

export default ClientValidator;
