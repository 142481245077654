import UserContext from "@context/UserContext";
import { useContext } from "react";
import { TUserContext } from "types/Core/Authentication/userContext";
import { Buffer } from "buffer";

export const IsValidResource = (resourceId: string): boolean => {
  const { currentUser } = useContext<TUserContext>(UserContext);

  return (
    (currentUser?.resources.includes(
      Buffer.from(resourceId).toString("base64")
    ) ||
      (currentUser?.isSuperAdministrator &&
        currentUser.isSuperAdministrator)) ??
    false
  );
};
