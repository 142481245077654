import { t } from "i18next";

const passwordLengthValidate = (password: string, name: string) => {
  let errMsg = undefined;

  if (password) {
    if (password.length < 6 || password.length > 20) {
      errMsg = `${t("core:validators:password-characters")}`;
    }
  } else {
    errMsg = `${t("core:validators:the-field")}"${name}" ${t(
      "core:validators:field-shouldnt-empty"
    )}`;
  }

  return errMsg;
};

export default passwordLengthValidate;
