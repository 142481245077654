import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button, ButtonToolbar } from "reactstrap";
import { EUiStates } from "@constants/enum";

const ButtonContainer = (): ReactElement => {
  const { t } = useTranslation(["core"]);
  const { pathname } = useLocation();
  const isDetail = pathname.includes("/detail") ?? false;

  let buttonText = "core:common.modify";
  let icon = "lnr lnr-pencil";
  let buttonColor = EUiStates.WARNING;

  if (pathname.includes("/create")) {
    buttonText = "core:common.create";
    icon = "lnr lnr-plus-circle";
    buttonColor = EUiStates.SUCCESS;
  }

  return (
    <ButtonToolbar className="form__button-toolbar">
      <Link to={`${pathname.substring(0, pathname.lastIndexOf("/list"))}/list`}>
        <Button type="button">
          <span className="lnr lnr-chevron-left" />
          {t("core:common.goback")}
        </Button>
      </Link>

      {isDetail ? (
        <Link to={pathname.replace("detail", "modify")}>
          <Button color={buttonColor} type="submit">
            <span className="lnr lnr-pencil" /> {t(buttonText)}
          </Button>
        </Link>
      ) : (
        <Button color={buttonColor} type="submit">
          <span className={icon} /> {t(buttonText)}
        </Button>
      )}
    </ButtonToolbar>
  );
};

export default ButtonContainer;
