import { ReactElement } from "react";
import Create from "@shared/FormTemplates/Create";
import CreateForm from "./CreateForm";
import createItemAPI from "@services/Core/ProjectManager/createProject";
import validator from "@validators/Security/Projects/project.validate";
import formMapper from "@mappers/Security/Projects/Project.mapper";

const CreateItem = (): ReactElement => (
  <Create
    CustomForm={CreateForm}
    createHook={createItemAPI}
    validator={validator}
    formMapper={formMapper}
  />
);

export default CreateItem;
