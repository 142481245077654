import { t } from "i18next";
import emptyFieldValidate from "../../shared/empty.validate";
import matchValidate from "../../shared/match.validate";
import mailValidate from "../../shared/mail.validate";
import usernameValidate from "../../shared/username.validate";
import passwordLengthValidate from "../../shared/passwordLength.validate";

export type TErrors = {
  name: string | undefined;
  lastname: string | undefined;
  username: string | undefined;
  isSuperAdministrator: boolean | undefined;
  mail: string | undefined;
  avatarUrl: string | undefined;
  client: string | undefined;
  projects: string | undefined;
  password: string | undefined;
  confirmpassword: string | undefined;
};

const createUser = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    lastname: undefined,
    username: undefined,
    isSuperAdministrator: undefined,
    mail: undefined,
    avatarUrl: undefined,
    client: undefined,
    projects: undefined,
    password: undefined,
    confirmpassword: undefined,
  };

  errors.name = emptyFieldValidate(FormValues.name, t("core:common:name"));

  errors.lastname = emptyFieldValidate(
    FormValues.lastname,
    t("core:common:last_name")
  );

  errors.username = usernameValidate(FormValues.username);

  errors.mail = mailValidate(FormValues.mail);

  errors.client = emptyFieldValidate(
    FormValues.client,
    t("core:common:client")
  );

  errors.projects = emptyFieldValidate(
    FormValues.projects,
    t("core:common:projects")
  );

  errors.password = passwordLengthValidate(
    FormValues.password,
    t("core:common:password")
  );

  errors.confirmpassword = passwordLengthValidate(
    FormValues.confirmpassword,
    `${t("core:common:confirm")} ${t("core:common:password")}`
  );

  errors.confirmpassword = matchValidate(
    FormValues.password,
    FormValues.confirmpassword
  );

  return errors;
};

export default createUser;
