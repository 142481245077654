// This file exists to add the change language button in topBar
import { ES, EN } from "@constants/countryCodes";
import image_es from "@images/language/es.png";
import image_en from "@images/language/en.png";
import { translationItem } from "@interfaces/Shared/translationItem";
import { es, enUS } from "date-fns/locale";

const translationLanguages: translationItem[] = [
  {
    default: true,
    code: EN,
    flag_image: image_en,
    id: 0,
    locale: enUS,
  },
  {
    default: false,
    code: ES,
    flag_image: image_es,
    id: 1,
    locale: es,
  },
];

export default translationLanguages;
