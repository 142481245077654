import { API_URL_WHALESHARK_PARTNER } from "@constants/Clients/whaleshark/whaleshark.env";
import IPartner from "@interfaces/Clients/WhaleShark/partner/partner";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetPartner = (uuid: string) =>
  useApiCall<IPartner>({
    url: API_URL_WHALESHARK_PARTNER + uuid,
  });

export default useGetPartner;
