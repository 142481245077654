import { API_URL_WHALESHARK_VAULT_CAROUSEL } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";

const deleteItem = (uuid: string) =>
  apiInstance({
    url: API_URL_WHALESHARK_VAULT_CAROUSEL + uuid,
    method: "delete",
  });

export default deleteItem;
