import { Route, Switch } from "react-router-dom";
import {
  HOTV_DETAIL,
  HOTV_CREATE,
  HOTV_MODIFY,
  HOTV,
  REPORT,
  REPORT_CREATE,
  REPORT_MODIFY,
  REPORT_DETAIL,
  FEARGREED,
  FEARGREED_DETAIL,
  FEARGREED_CREATE,
  FEARGREED_MODIFY,
  PARTNER,
  PARTNER_DETAIL,
  PARTNER_MODIFY,
  PARTNER_CREATE,
  VAULT_CAROUSEL,
  VAULT_CAROUSEL_CREATE,
  VAULT_CAROUSEL_DETAIL,
  VAULT_CAROUSEL_MODIFY,
  GLANCE,
  GLANCE_CREATE,
  GLANCE_DETAIL,
  GLANCE_MODIFY,
  CALENDAR,
  BENEFITS,
  BENEFIT_CREATE,
  BENEFIT_DETAIL,
  BENEFIT_MODIFY,
  ARTICLES,
  ARTICLES_CREATE,
  ARTICLES_DETAIL,
  ARTICLES_MODIFY,
} from "@constants/routes/Clients/whale.routes";
import ListHighlights from "@pages/Clients/WhaleShark/hotv/ListHighlights";
import DetailHighlight from "@pages/Clients/WhaleShark/hotv/DetailHighlight";
import ModifyHighlight from "@pages/Clients/WhaleShark/hotv/ModifyHighlight";
import CreateHighlight from "@pages/Clients/WhaleShark/hotv/CreateHighlight";
import ListFearGreed from "@pages/Clients/WhaleShark/fearGreed/ListFearGreed";
import DetailFearGreed from "@pages/Clients/WhaleShark/fearGreed/DetailFearGreed";
import CreateFearGreed from "@pages/Clients/WhaleShark/fearGreed/CreateFearGreed";
import ModifyFearGreed from "@pages/Clients/WhaleShark/fearGreed/ModifyFearGreed";
import ListReports from "@pages/Clients/WhaleShark/report/ListReport";
import CreateReport from "@pages/Clients/WhaleShark/report/CreateReport";
import ModifyReport from "@pages/Clients/WhaleShark/report/ModifyReport";
import DetailReport from "@pages/Clients/WhaleShark/report/DetailReport";
import ListPartners from "@pages/Clients/WhaleShark/partner/ListPartner";
import DetailPartner from "@pages/Clients/WhaleShark/partner/DetailPartner";
import ModifyPartner from "@pages/Clients/WhaleShark/partner/ModifyPartner";
import CreatePartner from "@pages/Clients/WhaleShark/partner/CreatePartner";
import ListVaultCarousel from "@pages/Clients/WhaleShark/vaultCarousel/ListVaultCarousel";
import DetailVaultCarousel from "@pages/Clients/WhaleShark/vaultCarousel/DetailVaultCarousel";
import CreateVaultCarousel from "@pages/Clients/WhaleShark/vaultCarousel/CreateVaultCarousel";
import ModifyVaultCarousel from "@pages/Clients/WhaleShark/vaultCarousel/ModifyVaultCarousel";
import ListGlance from "@pages/Clients/WhaleShark/whaleAtAGlance/ListGlanceItem";
import DetailGlance from "@pages/Clients/WhaleShark/whaleAtAGlance/DetailGlanceItem";
import CreateGlance from "@pages/Clients/WhaleShark/whaleAtAGlance/CreateGlanceItem";
import ModifyGlance from "@pages/Clients/WhaleShark/whaleAtAGlance/ModifyGlanceItem";
import Calendar from "@pages/Clients/WhaleShark/calendar";
import ListBenefits from "@pages/Clients/WhaleShark/whaleBenefits/ListBenefits";
import CreateBenefit from "@pages/Clients/WhaleShark/whaleBenefits/CreateBenefit";
import DetailBenefit from "@pages/Clients/WhaleShark/whaleBenefits/DetailBenefit";
import ModifyBenefit from "@pages/Clients/WhaleShark/whaleBenefits/ModifyBenefit";
import ListArticles from "@pages/Clients/WhaleShark/articles/ListArticles";
import CreateArticle from "@pages/Clients/WhaleShark/articles/CreateArticle";
import DetailArticle from "@pages/Clients/WhaleShark/articles/DetailArticle";
import ModifyArticle from "@pages/Clients/WhaleShark/articles/ModifyArticle";

const WhaleSharkModules = () => (
  <Switch>
    <Route exact path={HOTV} component={ListHighlights} />
    <Route exact path={HOTV_MODIFY} component={ModifyHighlight} />
    <Route exact path={HOTV_CREATE} component={CreateHighlight} />
    <Route exact path={HOTV_DETAIL} component={DetailHighlight} />
    <Route exact path={FEARGREED} component={ListFearGreed} />
    <Route exact path={FEARGREED_DETAIL} component={DetailFearGreed} />
    <Route exact path={FEARGREED_CREATE} component={CreateFearGreed} />
    <Route exact path={FEARGREED_MODIFY} component={ModifyFearGreed} />
    <Route exact path={REPORT} component={ListReports} />
    <Route exact path={REPORT_CREATE} component={CreateReport} />
    <Route exact path={REPORT_MODIFY} component={ModifyReport} />
    <Route exact path={REPORT_DETAIL} component={DetailReport} />
    <Route exact path={PARTNER} component={ListPartners} />
    <Route exact path={PARTNER_DETAIL} component={DetailPartner} />
    <Route exact path={PARTNER_CREATE} component={CreatePartner} />
    <Route exact path={PARTNER_MODIFY} component={ModifyPartner} />
    <Route exact path={VAULT_CAROUSEL} component={ListVaultCarousel} />
    <Route exact path={VAULT_CAROUSEL_DETAIL} component={DetailVaultCarousel} />
    <Route exact path={VAULT_CAROUSEL_CREATE} component={CreateVaultCarousel} />
    <Route exact path={VAULT_CAROUSEL_MODIFY} component={ModifyVaultCarousel} />
    <Route exact path={GLANCE} component={ListGlance} />
    <Route exact path={GLANCE_DETAIL} component={DetailGlance} />
    <Route exact path={GLANCE_CREATE} component={CreateGlance} />
    <Route exact path={GLANCE_MODIFY} component={ModifyGlance} />
    <Route exact path={CALENDAR} component={Calendar} />
    <Route exact path={BENEFITS} component={ListBenefits} />
    <Route exact path={BENEFIT_CREATE} component={CreateBenefit} />
    <Route exact path={BENEFIT_DETAIL} component={DetailBenefit} />
    <Route exact path={BENEFIT_MODIFY} component={ModifyBenefit} />
    <Route exact path={ARTICLES} component={ListArticles} />
    <Route exact path={ARTICLES_CREATE} component={CreateArticle} />
    <Route exact path={ARTICLES_DETAIL} component={DetailArticle} />
    <Route exact path={ARTICLES_MODIFY} component={ModifyArticle} />
  </Switch>
);

export default WhaleSharkModules;
