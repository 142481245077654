import { API_URL } from "@constants/env";
import axios from "axios";

const checkToken = (refreshToken: string) =>
  axios({
    baseURL: API_URL,
    method: "post",
    url: "/authentication/refresh-token",
    data: {
      refreshToken: refreshToken,
    },
  });

export default checkToken;
