import ITimeline from "@interfaces/Clients/RocketFactory/timeline/timeline";
import useApiCall from "@hooks/Shared/useApiCall";
import { API_URL_RF_TIMELINE } from "@constants/Clients/rocketFactory/rocketFactory.env";

const useGetTimeline = (uuid: string) =>
  useApiCall<ITimeline>({
    url: API_URL_RF_TIMELINE + uuid,
  });

export default useGetTimeline;
