import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { showNotification } from "@easydev/Notification";
import { useTranslation } from "react-i18next";
import { THookCreate } from "types/Shared/hooks";
import { EUiStates } from "@constants/enum";
import { TApiResult } from "types/Shared/apiResult";
import { removeDraft } from "@helpers/draft";

interface IUseCreateItem {
  isModalOpen: boolean;
  isCreating: boolean;
  handleCreate: (APICreate: THookCreate) => void;
  handleIsModalOpen: () => void;
  handleSaveForm: (formValues: any, mapper: any) => void;
}

const useCreateItem = (): IUseCreateItem => {
  const { t } = useTranslation(["core"]);
  const { pathname } = useLocation();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(undefined);

  const handleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSaveForm = (formValues: any, mapper: any) => {
    handleIsModalOpen();
    const formattedValues = mapper(formValues);
    setFormValues(formattedValues);
  };

  const handleCreate = (APICreate: THookCreate) => {
    setIsCreating(true);
    handleIsModalOpen();
    removeDraft();

    APICreate(formValues)
      .then((result: TApiResult) => {
        showNotification(
          t("core:common.success"),
          t("core:notifications.creation_success"),
          EUiStates.SUCCESS
        );

        history.push(
          `${pathname.replace("/create", "")}/${result.data.uuid}/detail`
        );
      })
      .catch((error) => {
        showNotification(
          "Error",
          `${t("core:notifications.creation_error")} | ${error}`,
          EUiStates.DANGER
        );
      })
      .finally(() => {
        setIsCreating(false);
        handleIsModalOpen();
      });
  };

  return {
    isModalOpen,
    isCreating,
    handleCreate,
    handleIsModalOpen,
    handleSaveForm,
  };
};

export default useCreateItem;
