import { ReactElement } from "react";
import LinkButton from "@components/BoxListButton/components/LinkButton";
import { categoryMap } from "@helpers/boxListButtonCategoryMap";
import DeleteButton from "./components/DeleteButton";

interface IBoxListButton {
  uuid: string;
  description?: string;
  detailRoute?: string;
  modifyRoute?: string;
  handleDelete?: Function;
}

const BoxListButton = ({
  uuid,
  description,
  detailRoute,
  modifyRoute,
  handleDelete,
}: IBoxListButton): ReactElement => {
  return (
    <>
      {detailRoute && (
        <LinkButton
          route={detailRoute}
          uuid={uuid}
          category={categoryMap.create}
        />
      )}

      {modifyRoute && (
        <LinkButton
          route={modifyRoute}
          uuid={uuid}
          category={categoryMap.modify}
        />
      )}

      {handleDelete && description && (
        <DeleteButton
          handleDelete={handleDelete}
          uuid={uuid}
          description={description}
          category={categoryMap.delete}
        />
      )}
    </>
  );
};

export default BoxListButton;
