import apiInstance from "@services/Shared/instanceAxios";
import { TCreateEditProject } from "types/Core/ProjectManager/createEditProject";

const createProject = (newProject: TCreateEditProject) =>
  apiInstance({
    url: "/projects/",
    method: "post",
    data: newProject,
  });

export default createProject;
