import { API_URL_HACKATAO_CLAIM_TRAIT } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TClaimTraitsItem } from "types/Clients/Hackatao/claimTraits/TClaimTraitsItem";

const updateItem = (uuid: string, updateClaim: TClaimTraitsItem) =>
  apiInstance({
    url: API_URL_HACKATAO_CLAIM_TRAIT + uuid,
    method: "put",
    data: updateClaim,
  });

export default updateItem;
