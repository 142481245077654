import {
  REPORT_MODIFY,
  REPORT_DETAIL,
} from "@constants/routes/Clients/whale.routes";
import BoxStatus from "@components/BoxStatus";
import IReport from "@interfaces/Clients/WhaleShark/report/report";
import BoxListButton from "@components/BoxListButton";

const listReportMapper = (items: IReport[], handleDelete: Function) =>
  items.map(({ uuid, title, reportUrl, state }, index) => ({
    uuid,
    order: index + 1,
    title,
    reportUrl,
    state: <BoxStatus state={state} />,
    actions: (
      <BoxListButton
        uuid={uuid}
        description={title}
        detailRoute={REPORT_DETAIL}
        modifyRoute={REPORT_MODIFY}
        handleDelete={handleDelete}
      />
    ),
  }));

export default listReportMapper;
