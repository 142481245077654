import { API_URL } from "@constants/env";
import axios from "axios";

const resetPassword = (mail: string) =>
  axios({
    baseURL: API_URL,
    url: `users/reset-password/${mail}`,
  });

export default resetPassword;
