import { API_URL_WHALESHARK_ARTICLES } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditArticle from "types/Clients/WhaleShark/articles/createEditArticle";

const createItem = (newItem: TCreateEditArticle) =>
  apiInstance({
    url: API_URL_WHALESHARK_ARTICLES,
    method: "post",
    data: newItem,
  });

export default createItem;
