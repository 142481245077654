import { ReactElement } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import CounterIcon from "mdi-react/CounterIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import FormTextField from "@components/Shared/FormTextField";
import { useTranslation } from "react-i18next";
import renderDateMonthPickerField from "@easydev/form/DateMonthPicker";
import IFormProps from "@interfaces/Shared/formProps";

const CreateForm = ({ handleSubmit }: IFormProps): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.month")} / {t("core:common.year")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarMonthIcon />
          </div>
          <Field
            name="year_month"
            component={renderDateMonthPickerField}
            placeholder="MM/yyyy"
            type="text"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.index")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CounterIcon />
          </div>
          <Field
            name="index"
            component={FormTextField}
            type="number"
            placeholder={0}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <Field
            name="imageUrl"
            component={FormTextField}
            type="text"
            placeholder="https://.../chart.png"
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateForm;
