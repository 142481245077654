import { API_URL_WHALESHARK_BENEFITS } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TGlanceAPI from "types/Clients/WhaleShark/benefits/benefitApi";

const updateItem = (uuid: string, editItem: TGlanceAPI) =>
  apiInstance({
    url: API_URL_WHALESHARK_BENEFITS + uuid,
    method: "put",
    data: editItem,
  });

export default updateItem;
