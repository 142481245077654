import { API_URL_WHALESHARK_REPORT } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditReport from "types/Clients/WhaleShark/report/createEditReport";

const updateItem = (uuid: string, editedItem: TCreateEditReport) =>
  apiInstance({
    url: API_URL_WHALESHARK_REPORT + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
