import { API_URL_HACKATAO_GRID_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";

const getItem = () =>
  apiInstance({
    url: API_URL_HACKATAO_GRID_AVATARS,
    params: {
      order: "desc",
      sort: "config.order",
    },
  });

export default getItem;
