import { ReactElement, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import deleteArticle from "@services/Clients/RocketFactory/timeline/deleteItem";
import useListColumns from "@hooks/Clients/RocketFactory/timeline/useListColumns";
import useDeleteItem from "@hooks/Shared/useDeleteItem";
import ModalConfirmation from "@components/ModalConfirmation";
import CreateBtn from "@components/CreateButton";
import Loader from "@components/Loader";
import Table from "@components/Table";
import Title from "@components/Title";
import { EUiStates } from "@constants/enum";
import useListItems from "@hooks/Shared/useListItems";
import getItems from "@services/Clients/RocketFactory/timeline/getItems";
import listMapper from "@mappers/Clients/RocketFactory/timeline/listTimeline.mapper";

const ListTimeline = (): ReactElement => {
  const { t } = useTranslation(["core", "rocketFactory"]);
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const { isLoading, basicData, tableItems } = useListItems(
    isDeletingItem,
    getItems,
    listMapper
  );
  const { isModalOpen, isDeleting, handleDelete, handleIsModalOpen } =
    useDeleteItem(basicData);
  const COLUMNS = useListColumns();

  useEffect(() => {
    setIsDeletingItem(isDeleting);
  }, [isDeleting]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <ModalConfirmation
        icon="trash"
        title={`Rocket Factory | ${t("rocketFactory:common.timeline")} | ${t(
          "core:common.delete_confirm"
        )}`}
        body={`${t("core:notifications.delete_message")} "${
          basicData?.description
        }" ?`}
        handleConfirm={() => {
          handleDelete(deleteArticle);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.DANGER}
      />

      <Title />
      <CreateBtn />
      <Table columns={COLUMNS} items={tableItems} />
    </Container>
  );
};

export default ListTimeline;
