import { API_URL_HACKATAO_MAIN_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TMainAvatarsItem } from "types/Clients/Hackatao/mainAvatars/TMainAvatarsItem";

const updateItem = (uuid: string, editedItem: TMainAvatarsItem) =>
  apiInstance({
    url: API_URL_HACKATAO_MAIN_AVATARS + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
