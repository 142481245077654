import { IEditableArrayColumn } from "@interfaces/Shared/editableArrayColumn";
import EmptyRow from "../EmptyRow";
import Row from "../Row";

interface IEditableArrayBody<T> {
  rows: T[];
  columns: IEditableArrayColumn[];
  setNewArray: (newRows: T[]) => void;
  disabled?: boolean;
}

const EditableArrayBody = <T extends Record<string, any>>({
  rows,
  columns,
  setNewArray,
  disabled,
}: IEditableArrayBody<T>) => {
  const updateData = (itemIndex: number, field: string, value: unknown) => {
    setNewArray([
      ...rows.slice(0, itemIndex),
      { ...rows[itemIndex], [field]: value },
      ...rows.slice(itemIndex + 1),
    ]);
  };

  const deleteRow = (itemIndex: number) => {
    setNewArray(rows.filter((r, i) => i !== itemIndex));
  };

  return (
    <>
      {(!rows || rows.length === 0) && <EmptyRow />}
      {rows &&
        rows.map((x, i) => (
          <Row
            key={`row-${i}`}
            columns={columns}
            index={i}
            item={x}
            deleteRow={deleteRow}
            updateData={updateData}
            disabled={disabled}
          />
        ))}
    </>
  );
};

export default EditableArrayBody;
