import { TUpdatePasswordAccountDto } from "types/Core/Account/updatePasswordAccountDto";

const updatePasswordAccountMapper = (
  formValues: any
): TUpdatePasswordAccountDto => ({
  oldPassword: formValues.oldpassword,
  newPassword: formValues.password,
  confirmedPassword: formValues.confirmpassword,
});

export default updatePasswordAccountMapper;
