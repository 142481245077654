import { TUpdateUserDto } from "types/Core/Users/updateUserDto";

const editUserMapper = (formValues: any): TUpdateUserDto => ({
  username: formValues.username,
  name: formValues.name,
  lastname: formValues.lastname,
  isSuperAdministrator: Boolean(Number(formValues.isSuperAdministrator.value)),
  mail: formValues.mail,
  state: Boolean(Number(formValues.state.value)),
  avatarUrl: formValues.avatarUrl === "" ? null : formValues.avatarUrl,
  clientId: formValues.client.value,
  projects: formValues.projects.map((elem: { value: any }) => ({
    projectId: elem.value,
    rolesId: ["1"],
  })),
});

export default editUserMapper;
