import { useState, useEffect } from "react";
import getItems from "@services/Clients/WhaleShark/calendar/getItems";
import ICalendarApi from "@interfaces/Clients/WhaleShark/calendar/calendar";
import { IBaseUse } from "@interfaces/Shared/baseUse";

interface IUseCalendar extends IBaseUse {
  data: ICalendarApi | undefined;
  handleCallApi: () => void;
}

const useCalendar = (): IUseCalendar => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ICalendarApi | undefined>(undefined);

  const handleCallApi = () => {
    setIsLoading(true);
    getItems()
      .then(async (response: { data: ICalendarApi[] }) => {
        setData(response.data[0]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleCallApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    isLoading,
    handleCallApi,
  };
};

export default useCalendar;
