import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import BoxStatus from "@components/BoxStatus";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import IClaimTraits from "@interfaces/Clients/Hackatao/claimTraits/claimTraits";
import BoxImage from "@components/BoxImage";
import ButtonContainer from "@components/ButtonContainer";

interface IDetailForm {
  data: IClaimTraits;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["hackatao", "core"]);

  return (
    <form className="form">
      <Col md={5}>
        <BoxImage title="Image Left" urlImage={data?.linkImageLeft} />

        <BoxImage title="Image Right" urlImage={data?.linkImageRight} />
      </Col>
      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.name")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <FormatTitleIcon />
            </div>
            <input type="textbox" value={data?.name} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.connectText")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.connectText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.winnerText")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.winnerText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.notWinnerText")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.notWinnerText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.alreadyClaimedText")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.alreadyClaimedText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.loadingText")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.loadingText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.successText")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.successText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.traitsPerWallet")}
          </span>
          <div className="form__form-group-field">
            <input type="textbox" value={data?.traitsPerWallet} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.whiteList")}
          </span>
          <div className="form__form-group-field">
            <textarea value={data?.whitelist.join("\n")} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("hackatao:common.buttonText")}
          </span>
          <div className="form__form-group-field">
            <input type="buttonText" value={data?.buttonText} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.state ?? true} />
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
