import { ReactElement } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import AccountConvertIcon from "mdi-react/AccountConvertIcon";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import {
  GetCategory,
  GetCategories,
} from "@mappers/Clients/WhaleShark/partner/getCategory.mapper";
import { useTranslation } from "react-i18next";
import IFormProps from "@interfaces/Shared/formProps";
import IPartner from "@interfaces/Clients/WhaleShark/partner/partner";

interface IModifyForm extends IFormProps {
  data: IPartner;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const initialStateValue = GetCategory(data?.category);

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder={`${t("core:common.name")} ${t(
              "whaleshark:common.partner"
            )}`}
            initialValue={data?.name}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.category")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountConvertIcon />
          </div>
          <Field
            name="category"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.category"
            )}`}
            options={GetCategories()}
            initialValue={initialStateValue}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.order")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OrderNumericAscendingIcon />
          </div>
          <Field
            name="order"
            component={FormTextField}
            type="number"
            initialValue={data?.order}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <Field
            name="imageUrl"
            component={FormTextField}
            type="text"
            placeholder="https://...partnerImage.png"
            initialValue={data?.imageUrl}
            isAboveError
          />
        </div>
      </div>
      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
