import apiInstance from "@services/Shared/instanceAxios";
import { TCreateUserDto } from "types/Core/Users/createUserDto";

const createUser = (newUser: TCreateUserDto) =>
  apiInstance({
    url: "/users/",
    method: "post",
    data: newUser,
  });

export default createUser;
