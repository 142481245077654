interface IBaseItem {
  order: number;
}

const reorderList = (list: any) =>
  list.map((item: IBaseItem, itemIndex: number) => ({
    ...item,
    order: itemIndex + 1,
  }));

export default reorderList;
