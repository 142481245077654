import { t } from "i18next";

const matchValidate = (value: string, wannabeValue: string) => {
  let errMsg = undefined;

  if (value !== wannabeValue) {
    errMsg = t("core:validators:password-not-match");
  }

  return errMsg;
};

export default matchValidate;
