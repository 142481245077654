import urlFieldValidate from "@validators/shared/url.validate";
import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";

export type TErrors = {
  name: string | undefined;
  connectText: string | undefined;
  winnerText: string | undefined;
  notWinnerText: string | undefined;
  alreadyClaimedText: string | undefined;
  loadingText: string | undefined;
  successText: string | undefined;
  linkImageLeft: string | undefined;
  linkImageRight: string | undefined;
  traitsPerWallet: string | undefined;
  whitelist: string | undefined;
  buttonText: string | undefined;
  state: string | undefined;
};

const ClaimTraitValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    connectText: undefined,
    winnerText: undefined,
    notWinnerText: undefined,
    alreadyClaimedText: undefined,
    loadingText: undefined,
    successText: undefined,
    linkImageLeft: undefined,
    linkImageRight: undefined,
    traitsPerWallet: undefined,
    whitelist: undefined,
    buttonText: undefined,
    state: undefined,
  };

  errors.name = emptyFieldValidate(FormValues.name, t("core:common:name"));

  errors.connectText = emptyFieldValidate(
    FormValues.connectText,
    t("hackatao:common:connectText")
  );

  errors.winnerText = emptyFieldValidate(
    FormValues.winnerText,
    t("hackatao:common:winnerText")
  );

  errors.notWinnerText = emptyFieldValidate(
    FormValues.notWinnerText,
    t("hackatao:common:notWinnerText")
  );

  errors.alreadyClaimedText = emptyFieldValidate(
    FormValues.alreadyClaimedText,
    t("hackatao:common:alreadyClaimedText")
  );

  errors.loadingText = emptyFieldValidate(
    FormValues.loadingText,
    t("hackatao:common:loadingText")
  );

  errors.successText = emptyFieldValidate(
    FormValues.successText,
    t("hackatao:common:successText")
  );

  errors.linkImageLeft = urlFieldValidate(
    FormValues.linkImageLeft,
    t("hackatao:common:linkImageLeft"),
    true
  );

  errors.linkImageRight = urlFieldValidate(
    FormValues.linkImageRight,
    t("hackatao:common:linkImageRight"),
    true
  );

  errors.traitsPerWallet = emptyFieldValidate(
    FormValues.traitsPerWallet,
    t("hackatao:common:traitsPerWallet")
  );

  errors.whitelist = emptyFieldValidate(
    FormValues.whitelist,
    t("hackatao:common:whiteList")
  );

  errors.buttonText = emptyFieldValidate(
    FormValues.buttonText,
    t("hackatao:common:buttonText")
  );

  errors.state = emptyFieldValidate(FormValues.state, t("core:common:state"));

  return errors;
};

export default ClaimTraitValidate;
