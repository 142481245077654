import TCreateEditPartner from "types/Clients/WhaleShark/partner/createEditPartner";

const partnerItemMapper = (formValues: any): TCreateEditPartner => ({
  name: formValues.name,
  category: Number(formValues.category.value),
  imageUrl: formValues.imageUrl,
  order: Number(formValues.order),
});

export default partnerItemMapper;
