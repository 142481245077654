interface IButton {
  onClick: () => void;
  disabled?: boolean;
  iconMdi: JSX.Element;
}

const Button = ({ onClick, disabled, iconMdi }: IButton) => {
  return (
    <button
      className={`form__form-group-button`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {iconMdi}
    </button>
  );
};

export default Button;
