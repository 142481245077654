import urlFieldValidate from "@validators/shared/url.validate";
import { t } from "i18next";
import emptyFieldValidate from "@validators/shared/empty.validate";

export type TErrors = {
  title: string | undefined;
  linkImage: string | undefined;
  state: string | undefined;
};

const FormValidator = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    title: undefined,
    linkImage: undefined,
    state: undefined,
  };

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.linkImage = urlFieldValidate(
    FormValues.linkImage,
    t("core:common:url_image"),
    true
  );

  errors.state = emptyFieldValidate(FormValues.state, t("core:common:state"));

  return errors;
};

export default FormValidator;
