import { ILogin } from "@interfaces/Core/Authentication/login";
import { API_URL } from "@constants/env";
import axios from "axios";

const getLogin = ({ email, password }: ILogin) =>
  axios({
    baseURL: API_URL,
    method: "post",
    url: "/authentication/token",
    data: {
      mail: email,
      password: password,
    },
  });

export default getLogin;
