import PropTypes from "prop-types";
import { Card, CardBody, Col } from "reactstrap";
import ReactTableBase from "../ReactTableBase";
import { useTranslation } from "react-i18next";

const DataReactTable = ({
  reactTableData,
  withDragAndDrop,
  withPagination,
  updateIndexes,
  manualPageSize,
  defaultTableSize,
}) => {
  const columns = reactTableData.tableHeaderData;
  const rows = reactTableData.tableRowsData;
  const withSearchEngine = true;
  const isSortable = true;
  const isEditable = false;
  const isResizable = false;
  const { t } = useTranslation("core");

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize,
    defaultTableSize,
    placeholder: t("core:common.search_message"),
  };

  const formatIndexes = (result) => {
    updateIndexes({
      destination: result.destination.index,
      source: result.source.index,
    });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ReactTableBase
            key={
              withSearchEngine || isResizable || isEditable
                ? "modified"
                : "common"
            }
            columns={columns}
            data={rows}
            updateEditableData={() => {}}
            updateDraggableData={formatIndexes}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DataReactTable;
