import useSuperusers from "@mappers/Shared/getSuperuser.mapper";
import { ReactElement } from "react";
import styles from "./BoxSuperuser.module.scss";

interface IBoxSuperuser {
  value: boolean;
}

const badgeClassname = {
  true: "badge-success",
  false: "badge-danger",
};

const BoxSuperuser = ({ value }: IBoxSuperuser): ReactElement => {
  const { GetSuperuser } = useSuperusers();
  return (
    <span
      className={`badge ${badgeClassname[`${value}`]} ${styles.badgeCustom}`}
    >
      {GetSuperuser(value).label}
    </span>
  );
};

export default BoxSuperuser;
