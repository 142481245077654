import { API_URL_HACKATAO_MAIN_AVATARS } from "@constants/Clients/hackatao/hackatao.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TMainAvatarsItem } from "types/Clients/Hackatao/mainAvatars/TMainAvatarsItem";

const createItem = (newMainAvatar: TMainAvatarsItem) =>
  apiInstance({
    url: API_URL_HACKATAO_MAIN_AVATARS,
    method: "post",
    data: newMainAvatar,
  });

export default createItem;
