import IFearGreed from "@interfaces/Clients/WhaleShark/fearGreed/fearGreed";
import { format } from "date-fns";
import BoxListButton from "@components/BoxListButton";
import {
  FEARGREED_DETAIL,
  FEARGREED_MODIFY,
} from "@constants/routes/Clients/whale.routes";

const listMapper = (itemsFromAPI: IFearGreed[], handleDelete: Function) =>
  itemsFromAPI.map(({ uuid, index, month, year }, itemIndex) => ({
    uuid,
    order: itemIndex + 1,
    index,
    month_name: format(new Date(year, month - 1, 1), "LLLL"),
    year,
    actions: (
      <BoxListButton
        uuid={uuid}
        detailRoute={FEARGREED_DETAIL}
        modifyRoute={FEARGREED_MODIFY}
        handleDelete={() =>
          handleDelete({
            uuid,
            description:
              year +
              "-" +
              format(new Date(year, month - 1, 1), "LLLL") +
              "-" +
              index,
          })
        }
      />
    ),
  }));

export default listMapper;
