import { timelineCategory } from "@constants/Clients/rocketFactory/enum";
import { typeSocial } from "@constants/enums/shared/enum";
import emptyFieldValidate from "@validators/shared/empty.validate";
import urlFieldValidate from "@validators/shared/url.validate";
import { t } from "i18next";
import { TObjStrUnd } from "types/Shared/objectFilledWithStrings";

const validator = (FormValues: Record<string, any>) => {
  let errors: TObjStrUnd = {
    title: undefined,
    subtitle: undefined,
    publishDate: undefined,
    category: undefined,
    linkImage: undefined,
    linkVideo: undefined,
    link: undefined,
    instagram: undefined,
    twitter: undefined,
    discord: undefined,
    body: undefined,
  };

  const isMovieCategory =
    FormValues?.category?.value === timelineCategory.movies;
  const isPressCategory =
    FormValues?.category?.value === timelineCategory.press;

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.linkImage = !isMovieCategory
    ? urlFieldValidate(FormValues.linkImage, t("core:common:image_link"), true)
    : undefined;

  errors.body = isPressCategory
    ? emptyFieldValidate(FormValues.body, t("core:common:description"))
    : undefined;

  errors.subtitle = isPressCategory
    ? emptyFieldValidate(FormValues.subtitle, t("core:common:subtitle"))
    : undefined;

  errors.linkVideo = isMovieCategory
    ? urlFieldValidate(FormValues.linkVideo, t("core:common:video_link"), true)
    : undefined;

  errors.publishDate = emptyFieldValidate(
    FormValues.publishDate,
    t("core:common:publish_date")
  );

  errors.category = emptyFieldValidate(
    FormValues.category,
    t("core:common:category")
  );

  errors.link = urlFieldValidate(
    FormValues.link,
    t("core:common:external_link"),
    false
  );

  errors.instagram = urlFieldValidate(
    FormValues.instagram,
    typeSocial.instagram,
    false
  );

  errors.twitter = urlFieldValidate(
    FormValues.twitter,
    typeSocial.twitter,
    false
  );

  errors.discord = urlFieldValidate(
    FormValues.discord,
    typeSocial.discord,
    false
  );

  return errors;
};

export default validator;
