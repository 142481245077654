import { API_URL_RF_TIMELINE } from "@constants/Clients/rocketFactory/rocketFactory.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditTimeline from "types/Clients/RocketFactory/timeline/createEditTimeline";

const updateItem = (uuid: string, editedItem: TCreateEditTimeline) =>
  apiInstance({
    url: API_URL_RF_TIMELINE + uuid,
    method: "put",
    data: editedItem,
  });

export default updateItem;
