import { ReactElement } from "react";
import Modify from "@shared/FormTemplates/Modify";
import ModifyForm from "./ModifyForm";
import updateItem from "@services/Clients/Hackatao/claimTrait/updateItem";
import validator from "@validators/Clients/Hackatao/claimTrait/ClaimTrait.validate";
import formMapper from "@mappers/Clients/Hackatao/claimTrait/ClaimTrait.mapper";
import useGetClaimTraits from "@hooks/Clients/Hackatao/claimTraits/useGetClaimTrait";

const ModifyItem = (): ReactElement => (
  <Modify
    CustomForm={ModifyForm}
    modifyHook={updateItem}
    detailHook={useGetClaimTraits}
    validator={validator}
    formMapper={formMapper}
  />
);

export default ModifyItem;
