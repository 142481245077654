import { ReactElement } from "react";
import Create from "@shared/FormTemplates/Create";
import CreateUserForm from "./CreateForm";
import createUser from "@services/Core/Users/createUser";
import validator from "@validators/Security/Users/CreateUser.validate";
import formMapper from "@mappers/Security/Users/CreateUser.mapper";

const CreateUser = (): ReactElement => (
  <Create
    CustomForm={CreateUserForm}
    createHook={createUser}
    validator={validator}
    formMapper={formMapper}
  />
);

export default CreateUser;
