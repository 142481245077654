import { ReactElement } from "react";
import Detail from "@shared/FormTemplates/Detail";
import DetailForm from "./DetailForm";
import useGetDetailItem from "@hooks/Clients/Reisinger/nftPieces/useGetAsset";

const DetailItem = (): ReactElement => (
  <Detail CustomForm={DetailForm} detailHook={useGetDetailItem} />
);

export default DetailItem;
