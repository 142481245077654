import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";
import urlFieldValidate from "../../../shared/url.validate";
import priceValidate from "../../../shared/price.validate";
import { OPEN_SEA } from "@constants/types";

export type TErrors = {
  type: string | undefined;
  url: string | undefined;
  title: string | undefined;
  pageLink: string | undefined;
  pieceLink: string | undefined;
  order: string | undefined;
  releaseDate: string | undefined;
  edition: string | undefined;
  price: string | undefined;
};

const NftAddValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    type: undefined,
    url: undefined,
    title: undefined,
    pageLink: undefined,
    pieceLink: undefined,
    order: undefined,
    releaseDate: undefined,
    edition: undefined,
    price: undefined,
  };

  const isOpenSea = FormValues?.type?.value === OPEN_SEA;

  errors.url = urlFieldValidate(
    FormValues.url,
    t("core:common:link"),
    isOpenSea
  );

  errors.title = isOpenSea
    ? undefined
    : emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.pageLink = isOpenSea
    ? undefined
    : urlFieldValidate(
        FormValues.pageLink,
        t("core:common:page_link"),
        !isOpenSea
      );

  errors.pieceLink = isOpenSea
    ? undefined
    : urlFieldValidate(
        FormValues.pieceLink,
        t("core:common:piece_link"),
        !isOpenSea
      );

  errors.order = emptyFieldValidate(FormValues.order, t("core:common:order"));
  errors.edition = emptyFieldValidate(
    FormValues.edition,
    t("core:common:edition")
  );
  errors.price = priceValidate(FormValues.price);

  return errors;
};

export default NftAddValidate;
