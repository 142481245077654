import { Route, Switch } from "react-router-dom";
import ListTimeline from "@pages/Clients/RocketFactory/timeline/ListTimeline";
import CreateTimeline from "@pages/Clients/RocketFactory/timeline/CreateTimeline";
import {
  TIMELINE,
  TIMELINE_CREATE,
  TIMELINE_DETAIL,
  TIMELINE_MODIFY,
} from "@constants/routes/Clients/rocketFactory.routes";
import DetailTimeline from "@pages/Clients/RocketFactory/timeline/DetailTimeline";
import ModifyTimeline from "@pages/Clients/RocketFactory/timeline/ModifyTimeline";

const RocketFactoryModules = () => (
  <Switch>
    <Route exact path={TIMELINE} component={ListTimeline} />
    <Route exact path={TIMELINE_CREATE} component={CreateTimeline} />
    <Route exact path={TIMELINE_DETAIL} component={DetailTimeline} />
    <Route exact path={TIMELINE_MODIFY} component={ModifyTimeline} />
  </Switch>
);

export default RocketFactoryModules;
