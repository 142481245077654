import { Route, Switch } from "react-router-dom";
import {
  DASHBOARD,
  LOCKSCREEN,
  MY_PROFILE,
  UPDATE_PASSWORD,
} from "@constants/routes/routes";
import UpdatePassword from "@pages/Account/UpdatePassword";
import Profile from "@pages/Account/Profile";
import Dashboard from "@pages/Dashboard";
import LockScreen from "@pages/Account/LockScreen";

const Account = () => (
  <Switch>
    <Route exact path={UPDATE_PASSWORD} component={UpdatePassword} />
    <Route exact path={MY_PROFILE} component={Profile} />
    <Route exact path={DASHBOARD} component={Dashboard} />
    <Route exact path={LOCKSCREEN} component={LockScreen} />
  </Switch>
);

export default Account;
