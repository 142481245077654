import { API_URL_WHALESHARK_PARTNER } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditPartner from "types/Clients/WhaleShark/partner/createEditPartner";

const createItem = (newItem: TCreateEditPartner) =>
  apiInstance({
    url: API_URL_WHALESHARK_PARTNER,
    method: "post",
    data: newItem,
  });

export default createItem;
