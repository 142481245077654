import { ReactElement, MouseEventHandler } from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { NavLink } from "react-router-dom";

interface ISidebarLink {
  title?: string;
  icon?: string;
  newLink?: boolean;
  route: string | null;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  dataTestId?: string;
}

const SidebarLink = ({
  title,
  icon,
  newLink,
  route,
  onClick,
  dataTestId,
}: ISidebarLink): ReactElement => {
  if (route) {
    return (
      <NavLink
        to={route}
        onClick={onClick}
        activeClassName="sidebar__link-active"
        data-testid={dataTestId}
      >
        <li className="sidebar__link">
          {icon ? (
            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
          ) : (
            ""
          )}
          <p className="sidebar__link-title">
            {title}
            {newLink ? (
              <Badge className="sidebar__link-badge">
                <span>New</span>
              </Badge>
            ) : (
              ""
            )}
          </p>
        </li>
      </NavLink>
    );
  }

  return <></>;
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => {},
};

export default SidebarLink;
