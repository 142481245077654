import { ReactElement, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import SidebarLink from "../../SidebarLink";
import SidebarCategory from "../../SidebarCategory";
import { IsValidResource } from "@helpers/resources.access";
import { NFT_KEY_PROJECT } from "@constants/routes/Clients/reisinger.routes";
import { WW_KEY_PROJECT } from "@constants/routes/Clients/whale.routes";
import { HACKATAO_KEY_PROJECT } from "@constants/routes/Clients/hackatao.routes";
import MODULES from "@config/Modules/Shared/modules";
import { RF_KEY_PROJECT } from "@constants/routes/Clients/rocketFactory.routes";

interface IModules {
  onClick: MouseEventHandler<HTMLAnchorElement>;
  sidebarCollapse: boolean;
  isOpen: string;
  setIsOpen: Function;
}

const Modules = ({
  onClick,
  sidebarCollapse,
  isOpen,
  setIsOpen,
}: IModules): ReactElement => {
  const { t } = useTranslation([
    "hackatao",
    "whaleshark",
    "reisinger",
    "rocketFactory",
  ]);
  const { hackatao, reisinger, whale_shark, rocket_factory } = MODULES;

  return (
    <>
      {IsValidResource(NFT_KEY_PROJECT) && (
        <ul className="sidebar__block">
          <SidebarCategory
            icon="magic-wand"
            title="Reisinger"
            sidebarCollapse={sidebarCollapse}
            children={reisinger.map(({ name, route }, index) => (
              <SidebarLink
                key={index}
                title={t(name)}
                route={route}
                onClick={onClick}
              />
            ))}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ul>
      )}

      {IsValidResource(WW_KEY_PROJECT) && (
        <ul className="sidebar__block">
          <SidebarCategory
            icon="magic-wand"
            title="Whale Shark"
            sidebarCollapse={sidebarCollapse}
            children={whale_shark.map(({ name, route }, index) => (
              <SidebarLink
                key={index}
                title={t(name)}
                route={route}
                onClick={onClick}
              />
            ))}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ul>
      )}

      {IsValidResource(HACKATAO_KEY_PROJECT) && (
        <ul className="sidebar__block">
          <SidebarCategory
            icon="magic-wand"
            title="Hackatao"
            sidebarCollapse={sidebarCollapse}
            children={hackatao.map(({ name, route }, index) => (
              <SidebarLink
                key={index}
                title={t(name)}
                route={route}
                onClick={onClick}
              />
            ))}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ul>
      )}

      {IsValidResource(RF_KEY_PROJECT) && (
        <ul className="sidebar__block">
          <SidebarCategory
            icon="magic-wand"
            title="Rocket Factory"
            sidebarCollapse={sidebarCollapse}
            children={rocket_factory.map(({ name, route }, index) => (
              <SidebarLink
                key={index}
                title={t(name)}
                route={route}
                onClick={onClick}
              />
            ))}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ul>
      )}
    </>
  );
};

export default Modules;
