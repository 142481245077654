import IMainAvatars from "@interfaces/Clients/Hackatao/mainAvatars/mainAvatar";
import {
  MAINAVATARS_DETAIL,
  MAINAVATARS_MODIFY,
} from "@constants/routes/Clients/hackatao.routes";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listMainAvatarsMapper = (
  dataFromApi: IMainAvatars[],
  handleDelete: Function
) => {
  const listMainAvatars = dataFromApi.map(
    ({ uuid, title, createdAt, updatedAt, state }, itemIndex) => ({
      uuid,
      order: itemIndex + 1,
      title,
      createdAt: timestampToString(createdAt),
      updatedAt: timestampToString(updatedAt),
      state: <BoxStatus state={state} />,
      actions: (
        <BoxListButton
          uuid={uuid}
          description={title}
          detailRoute={MAINAVATARS_DETAIL}
          modifyRoute={MAINAVATARS_MODIFY}
          handleDelete={handleDelete}
        />
      ),
    })
  );

  return listMainAvatars;
};

export default listMainAvatarsMapper;
