import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditClient from "types/Core/ClientManager/createEditClient";

const createClient = (newItem: TCreateEditClient) =>
  apiInstance({
    url: "/clients/",
    method: "post",
    data: newItem,
  });

export default createClient;
