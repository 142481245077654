import { TMainAvatarsItem } from "types/Clients/Hackatao/mainAvatars/TMainAvatarsItem";

const MainAvatarMapper = (formValues: any): TMainAvatarsItem => {
  return {
    title: formValues.title,
    desktopLinkImage: formValues.desktopLinkImage,
    mobileLinkImage: formValues.mobileLinkImage,
    state: Boolean(Number(formValues.state.value)),
  };
};

export default MainAvatarMapper;
