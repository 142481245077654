import { ReactElement, useContext, useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "../TopbarMenuLink";
import { LOCKSCREEN, LOGOUT, MY_PROFILE } from "@constants/routes/routes";
import { TUserContext } from "types/Core/Authentication/userContext";
import UserContext from "@context/UserContext";
import { useTranslation } from "react-i18next";

const TopbarProfile = (): ReactElement => {
  const { t } = useTranslation(["core"]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { currentUser, HandleLogout, HandleLockScreen } =
    useContext<TUserContext>(UserContext);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <img
          className="topbar__avatar-img"
          src={currentUser?.avatar}
          alt="avatar"
        />
        <p className="topbar__avatar-name">{currentUser?.userName}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title={t("core:navigation.my_profile")}
            icon="list"
            path={MY_PROFILE}
            onclick={handleToggleCollapse}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title={t("core:navigation.lock_screen")}
            icon="lock"
            path={LOCKSCREEN}
            onclick={HandleLockScreen}
          />
          <TopbarMenuLink
            title={t("core:navigation.log_out")}
            icon="exit"
            path={LOGOUT}
            onclick={HandleLogout}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
