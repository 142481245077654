import { IButtonCategory } from "@interfaces/Shared/buttonCategory";
import { ReactElement } from "react";
import { Button } from "reactstrap";
import ButtonContent from "../ButtonContent";

export interface IDeleteButton {
  uuid: string;
  category: IButtonCategory;
  handleDelete: Function;
  description: string;
}

const DeleteButton = ({
  uuid,
  category,
  handleDelete,
  description,
}: IDeleteButton): ReactElement => {
  return (
    <Button
      className={category.buttonClass}
      onClick={() => {
        handleDelete({ uuid, description });
      }}
      id={`${category.tooltipTarget}${uuid}`}
    >
      <ButtonContent uuid={uuid} category={category} />
    </Button>
  );
};

export default DeleteButton;
