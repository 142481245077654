import { useTranslation } from "react-i18next";

const EmptyRow = () => {
  const { t } = useTranslation(["core"]);

  return (
    <div className="editable__array-legend">
      {" "}
      {t("core:common.please_add_row")}
    </div>
  );
};

export default EmptyRow;
