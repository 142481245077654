import { API_URL_WHALESHARK_ARTICLES } from "@constants/Clients/whaleshark/whaleshark.env";
import { checkEmptyCoverImage } from "@helpers/Clients/WhaleShark/checkEmptyCoverImage";
import apiInstance from "@services/Shared/instanceAxios";
import TCreateEditArticle from "types/Clients/WhaleShark/articles/createEditArticle";

const updateItem = (uuid: string, editedItem: TCreateEditArticle) => {
  checkEmptyCoverImage(editedItem);

  return apiInstance({
    url: API_URL_WHALESHARK_ARTICLES + uuid,
    method: "put",
    data: editedItem,
  });
};

export default updateItem;
