import { useTranslation } from "react-i18next";

const useListColumns = () => {
  const { t } = useTranslation(["core"]);

  return [
    {
      Header: "#",
      accessor: "order",
      disableSortBy: true,
      disableGlobalFilter: true,
    },
    {
      Header: "",
      accessor: "superAdmin",
      disableSortBy: true,
      disableGlobalFilter: true,
      className: "column-xs",
    },
    {
      Header: t("core:common.name"),
      accessor: "name",
    },
    {
      Header: t("core:common.last_name"),
      accessor: "lastname",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.username"),
      accessor: "username",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.mail"),
      accessor: "mail",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.created_at"),
      accessor: "createDate",
      disableGlobalFilter: true,
    },
    {
      Header: t("core:common.state"),
      accessor: "state",
      disableGlobalFilter: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableSortBy: true,
      disableGlobalFilter: true,
    },
  ];
};

export default useListColumns;
