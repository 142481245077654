import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { isMobileOnly } from "react-device-detect";
import PropTypes from "prop-types";
import { renderComponentField } from "@easydev/form/FormField";

const DateMonthPickerField = ({ onChange }) => {
  const [startDate, setStartDate] = useState(null);
  const handleChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <div className="date-picker">
      <DatePicker
        className="form__form-group-datepicker"
        selected={startDate}
        onChange={handleChange}
        dateFormat="MM/yyyy"
        dropDownMode="select"
        popperPlacement="center"
        showMonthYearPicker
        withPortal={isMobileOnly}
      />
    </div>
  );
};

DateMonthPickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default renderComponentField(DateMonthPickerField);
