import { API_URL_WHALESHARK_BENEFITS } from "@constants/Clients/whaleshark/whaleshark.env";
import IBenefitApi from "@interfaces/Clients/WhaleShark/benefits/benefit";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetItem = (uuid: string) =>
  useApiCall<IBenefitApi>({
    url: API_URL_WHALESHARK_BENEFITS + uuid,
  });

export default useGetItem;
