import { ICurrentUser } from "@interfaces/Core/Authentication/currentUser";
import { TUserContext } from "types/Core/Authentication/userContext";
import UserContext from "./UserContext";
import storageService from "@utils/localStorage.service";
import { useEffect, useState } from "react";
import { ILogin } from "@interfaces/Core/Authentication/login";
import avatar_logo from "@images/account/avatar.png";
import client_logo from "@images/shared/logo.png";
import getLogin from "@services/Core/Authentication/getLogin";
import jwt_decode from "jwt-decode";
import { IUserToken } from "@interfaces/Core/Authentication/userToken";
import { TokenPayload } from "@interfaces/Core/Authentication/tokenPayload";
import checkToken from "@services/Core/Authentication/checkToken";
import getAccount from "@services/Core/Authentication/getAccount";
import { IProfile } from "@interfaces/Core/Account/profile";

type ProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: ProviderProps) => {
  const currentLocalUser = storageService.getLocalUserStorage();

  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(
    currentLocalUser
  );
  const [authenticated, setAuthenticated] = useState(
    currentLocalUser ? true : false
  );
  const [locked, setLocked] = useState(false);
  const [errorAuthMessage, setErrorAuthMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    HandleCheckLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HandleLogin = async (login: ILogin) => {
    setIsLoading(true);
    getLogin(login)
      .then(async (response: { data: IUserToken }) => {
        const user: ICurrentUser = await BuildCurrentUser(response.data);

        storageService.removeLocalStorage();
        storageService.setLocalUserStorage(user);
        setCurrentUser(user);
        setAuthenticated(true);
        setLocked(false);
        setErrorAuthMessage("");
      })
      .catch((error: { response: { data: { error: string } } }) => {
        setErrorAuthMessage(error?.response?.data?.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsInitialized(true);
      });
  };

  const HandleLogout = async () => {
    storageService.removeLocalStorage();
    setCurrentUser(null);
    setAuthenticated(false);
    setIsInitialized(false);
    setLocked(false);
  };

  const HandleCheckLogin = async () => {
    const userLocalStorage = storageService.getLocalUserStorage();

    if (
      authenticated ||
      (currentUser !== null &&
        currentUser.expiresAt - 1600 >
          Math.floor(new Date().getTime() / 1000.0))
    ) {
      return;
    }

    if (userLocalStorage?.refreshToken) {
      setIsLoading(true);

      checkToken(userLocalStorage.refreshToken)
        .then(async (response: { data: IUserToken }) => {
          const user: ICurrentUser = await BuildCurrentUser(response.data);

          storageService.setLocalUserStorage(user);
          setCurrentUser(user);
          setAuthenticated(true);
        })
        .catch(() => {
          HandleLogout();
        })
        .finally(() => {
          setIsLoading(false);
          setIsInitialized(true);
        });
    }
  };

  const HandleLockScreen = async () => {
    setLocked(true);
  };

  const HandleUnLockScreen = async (login: ILogin) => {
    login.email = currentUser?.mail ?? "";
    HandleLogin(login);
  };

  const BuildCurrentUser = async (
    userToken: IUserToken
  ): Promise<ICurrentUser> => {
    const decodedToken: TokenPayload = jwt_decode(userToken.token);

    let user: ICurrentUser = {
      uuid: decodedToken.uuid,
      clientId: decodedToken.clientId,
      expiresAt: userToken.expiresAt,
      token: userToken.token,
      refreshToken: userToken.refreshToken,
      resources: decodedToken.resources,
      isSuperAdministrator: decodedToken.isSuperAdministrator,
    };

    await getAccount(decodedToken.uuid).then((response: { data: IProfile }) => {
      user.userName = response.data.username;
      user.mail = response.data.mail;
      user.avatar = response.data.avatar ?? avatar_logo;
      user.clientLogo = response.data.client.logoUrl ?? client_logo;
    });

    return user;
  };

  const stateValues: TUserContext = {
    errorAuthMessage,
    authenticated,
    locked,
    isLoading,
    isInitialized,
    currentUser,
    setCurrentUser,
    HandleLogin,
    HandleLogout,
    HandleCheckLogin,
    HandleLockScreen,
    HandleUnLockScreen,
  };

  return (
    <UserContext.Provider value={stateValues}>{children}</UserContext.Provider>
  );
};
