import React, { useState } from "react";
import { Collapse } from "reactstrap";
import DownIcon from "mdi-react/ChevronDownIcon";
import { UseLanguage } from "@hooks/Shared/useLanguage";

const TopbarLanguage = () => {
  const { mainButtonContent, changeLanguage, getLanguages } = UseLanguage();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleLanguage = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button className="topbar__btn" type="button" onClick={toggleLanguage}>
        <span className="topbar__language-btn-title">
          <img
            src={mainButtonContent?.flag_image}
            alt={mainButtonContent?.code}
          />
          <span>{mainButtonContent?.code}</span>
        </span>

        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="language button"
          onClick={toggleLanguage}
        />
      )}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content topbar__collapse-content--language"
      >
        {getLanguages().map(({ code, flag_code, flag_image, id }) => (
          <button
            key={id}
            type="button"
            className="topbar__language-btn"
            onClick={() => changeLanguage(code)}
          >
            <span className="topbar__language-btn-title">
              <img src={flag_image} alt={flag_code} />
              <span>{code}</span>
            </span>
          </button>
        ))}
      </Collapse>
    </div>
  );
};

export default TopbarLanguage;
