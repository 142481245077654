import apiInstance from "@services/Shared/instanceAxios";
import { TUpdatePasswordAccountDto } from "types/Core/Account/updatePasswordAccountDto";

const updatePassword = (
  uuid: string,
  updateDataPassword: TUpdatePasswordAccountDto
) =>
  apiInstance({
    url: "/account/" + uuid + "/changePassword",
    method: "put",
    data: updateDataPassword,
  });

export default updatePassword;
