import IClaimTraits from "@interfaces/Clients/Hackatao/claimTraits/claimTraits";
import {
  CLAIMTRAIT_DETAIL,
  CLAIMTRAIT_MODIFY,
} from "@constants/routes/Clients/hackatao.routes";
import BoxStatus from "@components/BoxStatus";
import { timestampToString } from "@helpers/dates.format";
import BoxListButton from "@components/BoxListButton";

const listClaimTraitMapper = (
  usersFromApi: IClaimTraits[],
  handleDelete: Function
) => {
  const listClaimTrait = usersFromApi.map(
    ({ uuid, name, createdAt, updatedAt, state }, itemIndex) => ({
      uuid,
      order: itemIndex + 1,
      name,
      createdAt: timestampToString(createdAt),
      updatedAt: timestampToString(updatedAt),
      state: <BoxStatus state={state} />,
      actions: (
        <BoxListButton
          uuid={uuid}
          description={name}
          detailRoute={CLAIMTRAIT_DETAIL}
          modifyRoute={CLAIMTRAIT_MODIFY}
          handleDelete={handleDelete}
        />
      ),
    })
  );

  return listClaimTrait;
};

export default listClaimTraitMapper;
