import { ReactElement, MouseEventHandler } from "react";
import PropTypes from "prop-types";
import Button from "@components/Button";

interface ITopbarSidebarButton {
  onClickDesktop: MouseEventHandler<HTMLButtonElement>;
  onClickMobile: MouseEventHandler<HTMLButtonElement>;
}

const TopbarSidebarButton = ({
  onClickDesktop,
  onClickMobile,
}: ITopbarSidebarButton): ReactElement => (
  <div>
    <Button onClick={onClickDesktop} className="topbar__button--desktop" />
    <Button onClick={onClickMobile} className="topbar__button--mobile" />
  </div>
);

TopbarSidebarButton.propTypes = {
  onClickDesktop: PropTypes.func.isRequired,
  onClickMobile: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;
