import { ReactElement } from "react";
import { Spinner } from "reactstrap";

interface ILoader {
  isInsideThing?: boolean;
}

const Loader = ({ isInsideThing }: ILoader): ReactElement => (
  <div className={`loader ${isInsideThing && "inside--thing"} `}>
    <Spinner animation="border" role="status" />
  </div>
);

export default Loader;
