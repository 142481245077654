import { Route, Switch } from "react-router-dom";
import ListNfts from "@pages/Clients/Reisinger/NftPieces/ListNft";
import NftDetail from "@pages/Clients/Reisinger/NftPieces/NftDetail";
import ModifyNft from "@pages/Clients/Reisinger/NftPieces/ModifyNft";
import CreateNft from "@pages/Clients/Reisinger/NftPieces/CreateNft";
import ChangeOrderList from "@pages/Clients/Reisinger/NftPieces/ChangeOrderList";
import {
  NFT_PIECES,
  NFT_CREATE,
  NFT_DETAIL,
  NFT_MODIFY,
  NFT_CHANGE_ORDER,
} from "@constants/routes/Clients/reisinger.routes";

const ReisingerModules = () => (
  <Switch>
    <Route path={NFT_CHANGE_ORDER} component={ChangeOrderList} />
    <Route path={NFT_DETAIL} component={NftDetail} />
    <Route path={NFT_MODIFY} component={ModifyNft} />
    <Route path={NFT_CREATE} component={CreateNft} />
    <Route path={NFT_PIECES} component={ListNfts} />
  </Switch>
);

export default ReisingerModules;
