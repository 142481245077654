import { API_URL_RF_TIMELINE } from "@constants/Clients/rocketFactory/rocketFactory.env";
import apiInstance from "@services/Shared/instanceAxios";

const deleteItem = (uuid: string) =>
  apiInstance({
    url: API_URL_RF_TIMELINE + uuid,
    method: "delete",
  });

export default deleteItem;
