import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import ButtonContainer from "@components/ButtonContainer";
import BoxStatus from "@components/BoxStatus";
import FormatTitleIcon from "mdi-react/FormatTitleIcon";
import IMainAvatar from "@interfaces/Clients/Hackatao/mainAvatars/mainAvatar";
import BoxImage from "@components/BoxImage";

interface IDetailForm {
  data: IMainAvatar;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["hackatao", "core"]);

  return (
    <form className="form">
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FormatTitleIcon />
          </div>
          <input type="text" value={data?.title} disabled />
        </div>
      </div>
      <Col md={6}>
        <div className="form__form-group">
          {data && (
            <>
              <span className="form__form-group-label">
                {t("hackatao:common.desktopImageLink")}
              </span>
              <div className="form__form-group-field">
                <BoxImage urlImage={data.desktopLinkImage} />
              </div>
            </>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.state")}
          </span>
          <div className="form__form-group-field">
            <BoxStatus state={data?.state ?? true} />
          </div>
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          {data && (
            <>
              <span className="form__form-group-label">
                {t("hackatao:common.mobileImageLink")}
              </span>
              <div className="form__form-group-field">
                <BoxImage urlImage={data.mobileLinkImage} />
              </div>
            </>
          )}
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
