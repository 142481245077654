import { ReactElement } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  ButtonToolbar,
  Button,
} from "reactstrap";
import ModalConfirmation from "@components/ModalConfirmation";
import FormTextField from "@components/Shared/FormTextField";
import Loader from "@components/Loader";
import useUpdateItem from "@hooks/Shared/useUpdateItem";
import { EUiStates } from "@constants/enum";
import TitleIcon from "mdi-react/FormatTitleIcon";
import useLearnMore from "@hooks/Clients/Hackatao/learnMore/useLearnMore";
import updateLearnMore from "@services/Clients/Hackatao/learnMore/updateLearnMore";
import learnMoreMapper from "@mappers/Clients/Hackatao/learnMore/LearnMore.mapper";
import LearnMoreValidator from "@validators/Clients/Hackatao/learnMore/LearnMore.validate";
import TextEditor from "@easydev/text-editor/TextEditor";
import Title from "@components/Title";

const LearnMore = (): ReactElement => {
  const { t } = useTranslation(["core", "hackatao"]);
  const { isLoading, data, handleCallApi } = useLearnMore();

  const {
    isUpdating,
    isModalOpen,
    handleSaveForm,
    handleUpdate,
    handleIsModalOpen,
  } = useUpdateItem();

  if (isLoading || isUpdating) {
    return <Loader />;
  }

  return (
    <Container>
      <ModalConfirmation
        icon="pencil"
        title={`${t("hackatao:common.learnMore")} | ${t(
          "core:common.modify_confirm"
        )}`}
        body={t("core:notifications.modify_confirm")}
        handleConfirm={() => {
          data?.uuid && handleUpdate(updateLearnMore, data.uuid, handleCallApi);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.WARNING}
      />

      <Title />

      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <Form
                onSubmit={(FormValues) => {
                  handleSaveForm(FormValues, learnMoreMapper);
                }}
                validate={LearnMoreValidator}
                initialValues={data}
              >
                {({ handleSubmit }) => {
                  return (
                    <form className="form" onSubmit={handleSubmit}>
                      <Col xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t("core:common.title")}
                          </span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <TitleIcon />
                            </div>
                            <Field
                              name="title"
                              component={FormTextField}
                              type="textbox"
                              placeholder={t("core:common.title")}
                              isAboveError
                            />
                          </div>
                        </div>

                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t("core:common.body")}
                          </span>
                          <div className="form__form-group-field">
                            <Field name="bodyText" component={TextEditor} />
                          </div>
                        </div>

                        <ButtonToolbar className="form__button-toolbar">
                          <Button className="btn-warning">
                            <span className="lnr lnr-pencil" />{" "}
                            {t("core:common.modify")}
                          </Button>
                        </ButtonToolbar>
                      </Col>
                    </form>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LearnMore;
