export enum boxListButtonClass {
  Create = "btn-sm btn-primary",
  Modify = "btn-sm btn-warning",
  Delete = "btn-sm btn-danger",
}

export enum boxListButtonSpanClass {
  Create = "lnr lnr-eye",
  Modify = "lnr lnr-pencil",
  Delete = "lnr lnr-trash",
}

export enum boxListButtonTooltipTarget {
  Create = "tooltip-view-",
  Modify = "tooltip-modify-",
  Delete = "tooltip-delete-",
}

export enum boxListButtonTooltipText {
  Create = "View",
  Modify = "Modify",
  Delete = "Delete",
}
