import { TCreateAssetDto } from "types/Clients/Reisinger/createAssetDto";
import storageService from "../../../../localStorage.service";
import { OPEN_SEA } from "@constants/types";

const createAssetMapper = (formValues: any): TCreateAssetDto => {
  const userLocalStorage = storageService.getLocalUserStorage();

  return {
    permalink:
      formValues.type.value === OPEN_SEA ? formValues.url : formValues.pageLink,
    title: formValues.title ?? null,
    link_nft: formValues.pieceLink ?? null,
    client: { uuid: userLocalStorage?.clientId ?? "" },
    config: {
      type: formValues.type.value,
      show: Boolean(Number(formValues.state.value)),
      order: formValues.order ? Number(formValues.order) : null,
      price: formValues.price ? Number(formValues.price) : null,
      release_date: formValues.releaseDate,
      description: formValues.edition ? formValues.edition : null,
    },
  };
};

export default createAssetMapper;
