import { t } from "i18next";

const editableArrayValidator = (
  field: Array<Record<string, any>>,
  name: string
) => {
  let errMsg = undefined;

  if (field && field.some((x) => Object.keys(x).some((y) => !x[y]))) {
    errMsg = `${t("core:validators:the-field")} "${name}" ${t(
      "core:validators:fields-shouldnt-empty"
    )}`;
  }

  return errMsg;
};

export default editableArrayValidator;
