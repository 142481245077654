import { API_URL_WHALESHARK_FGINDEX } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import { TCreateFearGreedDto } from "types/Clients/WhaleShark/fearGreed/createFearGreedDto";

const createItem = (newItem: TCreateFearGreedDto) =>
  apiInstance({
    url: API_URL_WHALESHARK_FGINDEX,
    method: "post",
    data: newItem,
  });

export default createItem;
