import { t } from "i18next";
import matchValidate from "../../shared/match.validate";
import passwordLengthValidate from "../../shared/passwordLength.validate";

export type TErrors = {
  oldpassword: string | undefined;
  password: string | undefined;
  confirmpassword: string | undefined;
};

const updatePasswordValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    oldpassword: undefined,
    password: undefined,
    confirmpassword: undefined,
  };

  errors.oldpassword = passwordLengthValidate(
    FormValues.oldpassword,
    t("core:common:old_password")
  );

  errors.password = passwordLengthValidate(
    FormValues.password,
    t("core:common:password")
  );

  errors.confirmpassword = passwordLengthValidate(
    FormValues.confirmpassword,
    `${t("core:common:confirm")} ${t("core:common:password")}`
  );

  errors.confirmpassword = matchValidate(
    FormValues.password,
    FormValues.confirmpassword
  );

  return errors;
};

export default updatePasswordValidate;
