import { timelineCategory } from "@constants/Clients/rocketFactory/enum";
import ITimeline from "@interfaces/Clients/RocketFactory/timeline/timeline";

const timelineRules = (data: ITimeline) => {
  const showSubtitle = data?.category === timelineCategory.press;
  const showLinkImage = data?.category !== timelineCategory.movies;
  const showLinkVideo = data?.category === timelineCategory.movies;
  const showLink = data?.category !== timelineCategory.launches;
  const showSocials = data?.category === timelineCategory.irlEvents;
  const showDateEnd = data?.category === timelineCategory.irlEvents;

  return {
    showSubtitle,
    showLinkImage,
    showLinkVideo,
    showLink,
    showSocials,
    showDateEnd,
  };
};

export default timelineRules;
