/* eslint-disable camelcase */
import en_sources from "./en/default.sources";
import es_sources from "./es/default.sources";

const resources = {
  en: en_sources,
  es: es_sources,
};

export default resources;
