import { API_URL_WHALESHARK_GLANCE } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";
import TGlanceAPI from "types/Clients/WhaleShark/glance/glanceApi";

const createItem = (newItem: TGlanceAPI) =>
  apiInstance({
    url: API_URL_WHALESHARK_GLANCE,
    method: "post",
    data: newItem,
  });

export default createItem;
