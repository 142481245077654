import { API_URL_HACKATAO_CLAIM_TRAIT } from "@constants/Clients/hackatao/hackatao.env";
import useApiCall from "@hooks/Shared/useApiCall";
import IClaimTraits from "@interfaces/Clients/Hackatao/claimTraits/claimTraits";

const useGetClaimTraits = (uuid: string) =>
  useApiCall<IClaimTraits>({
    url: API_URL_HACKATAO_CLAIM_TRAIT + uuid,
  });

export default useGetClaimTraits;
