import { ReactElement } from "react";
import Create from "@shared/FormTemplates/Create";
import CreateForm from "./CreateForm";
import createItemAPI from "@services/Clients/WhaleShark/hotv/createItem";
import validator from "@validators/Clients/WhaleShark/hotv/hotv.validate";
import formMapper from "@mappers/Clients/WhaleShark/hotv/HighlightItem.mapper";

const CreateItem = (): ReactElement => (
  <Create
    CustomForm={CreateForm}
    createHook={createItemAPI}
    validator={validator}
    formMapper={formMapper}
  />
);

export default CreateItem;
