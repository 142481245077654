import { t } from "i18next";
import emptyFieldValidate from "./empty.validate";

const mailValidate = (email: string) => {
  let errMsg = undefined;

  errMsg = emptyFieldValidate(email, "Email");

  if (
    !errMsg &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,9}$/i.test(`${email}`)
  ) {
    errMsg = `${t("core:validators:invalid-email")}: ${email}.`;
  }

  return errMsg;
};

export default mailValidate;
