import { ReactElement } from "react";
import styles from "./BoxImage.module.scss";

export interface IBoxImage {
  title?: string;
  urlImage: string;
  altImage?: string;
}

const BoxImage = ({ title, urlImage, altImage }: IBoxImage): ReactElement => (
  <>
    {title && <label className="form__form-group-label">{title}</label>}
    <div className={styles.box__image}>
      <img src={urlImage} alt={altImage ?? "image preview"} />
    </div>
  </>
);

export default BoxImage;
