import { ReactNode } from "react";
import { Field } from "react-final-form";

interface ICondition {
  when: string;
  is: string;
  children: ReactNode;
}

const Condition = ({ when, is, children }: ICondition) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );
};

export default Condition;
