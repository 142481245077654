import {
  EArticleType,
  EArticleCategories,
} from "@constants/Clients/whaleshark/enum";

export const MAX_NUMBER_DOCUMENTS = 3;

export const ARTICLE_TYPES = [
  {
    label: "General",
    value: EArticleType.GENERAL,
  },
  {
    label: "Highlight",
    value: EArticleType.HIGHLIGHT,
  },
];

export const ARTICLE_CATEGORIES = [
  {
    label: "Public",
    value: EArticleCategories.PUBLIC,
  },
  {
    label: "Members",
    value: EArticleCategories.MEMBERS,
  },
];
