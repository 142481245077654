import { ElementType, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import useUpdateItem from "@hooks/Shared/useUpdateItem";
import ModalConfirmation from "@components/ModalConfirmation";
import getModuleName from "@helpers/modules";
import { EUiStates } from "@constants/enum";
import { THookUpdate } from "types/Shared/hooks";
import Loader from "@components/Loader";
import Title from "@components/Title";
import { bodyEditorMap } from "@mappers/Clients/WhaleShark/articles/bodyEditorMap";

interface IModify {
  CustomForm: ElementType;
  modifyHook: THookUpdate;
  detailHook: (uuid: string) => any;
  validator: (FormValues: Record<string, any>) => any;
  formMapper: (FormValues: any) => any;
}

const Modify = ({
  CustomForm,
  modifyHook,
  detailHook,
  validator,
  formMapper,
}: IModify): ReactElement => {
  const { uuid } = useParams<{ uuid: string }>();
  const { t } = useTranslation(["core"]);
  const { data, loading } = detailHook(uuid);
  const { pathname } = useLocation();
  const {
    isUpdating,
    isModalOpen,
    handleSaveForm,
    handleUpdate,
    handleIsModalOpen,
  } = useUpdateItem();

  return (
    <Container>
      <ModalConfirmation
        icon="plus-circle"
        title={`${t(getModuleName(pathname))} | ${t(
          "core:common.modify_confirm"
        )}`}
        body={t("core:notifications.modify_confirm")}
        handleConfirm={() => {
          handleUpdate(modifyHook, uuid);
        }}
        handleModalStatus={handleIsModalOpen}
        modalStatus={isModalOpen}
        modalType={EUiStates.WARNING}
      />
      <Title />
      <Row>
        <Col xs={12} md={12} lg={12} xl={10}>
          <Card>
            <CardBody>
              <Form
                onSubmit={(FormValues) => {
                  const formValuesWithBody = bodyEditorMap(FormValues, data);
                  handleSaveForm(formValuesWithBody, formMapper);
                }}
                validate={validator}
              >
                {({ handleSubmit, form }) =>
                  isUpdating || loading ? (
                    <Loader isInsideThing />
                  ) : (
                    <CustomForm
                      handleSubmit={handleSubmit}
                      form={form}
                      data={data}
                    />
                  )
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Modify;
