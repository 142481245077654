import { ReactElement } from "react";
import Modify from "@shared/FormTemplates/Modify";
import ModifyForm from "./ModifyForm";
import updateItem from "@services/Clients/Reisinger/nftPieces/updateAsset";
import useGetDetailItem from "@hooks/Clients/Reisinger/nftPieces/useGetAsset";
import validator from "@validators/Clients/Reisinger/nftPieces/NftModify.validate";
import formMapper from "@mappers/Clients/Reisinger/nftPieces/UpdateAsset.mapper";

const ModifyItem = (): ReactElement => (
  <Modify
    CustomForm={ModifyForm}
    modifyHook={updateItem}
    detailHook={useGetDetailItem}
    validator={validator}
    formMapper={formMapper}
  />
);

export default ModifyItem;
