import { API_URL_WHALESHARK_PARTNER } from "@constants/Clients/whaleshark/whaleshark.env";
import apiInstance from "@services/Shared/instanceAxios";

const getItems = () =>
  apiInstance({
    url: API_URL_WHALESHARK_PARTNER,
    params: {
      order: "asc",
      sort: "order",
    },
  });

export default getItems;
