import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";
import { Alert, Button } from "reactstrap";
import PasswordField from "../form/Password";

const ResetPasswordConfirmForm = ({ onSubmit, validate, errorMessage }) => (
  <Form
    onSubmit={(FormValues) => onSubmit(FormValues)}
    validate={(FormValues) => validate(FormValues)}
  >
    {({ handleSubmit }) => (
      <form className="form reset-password-form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">New Password</span>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={PasswordField}
              placeholder="Password"
              className="input-without-border-radius"
              keyIcon
              isAboveError
            />
          </div>
        </div>
        <Alert className="account__btn" color="danger" isOpen={!!errorMessage}>
          <AlertCircleOutlineIcon /> {errorMessage}
        </Alert>
        <Button className="account__btn" type="submit" color="primary">
          Reset Password
        </Button>
      </form>
    )}
  </Form>
);

ResetPasswordConfirmForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordConfirmForm;
