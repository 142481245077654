import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";
import { TObjStrUnd } from "types/Shared/objectFilledWithStrings";
import urlFieldValidate from "@validators/shared/url.validate";
import { EArticleCategories } from "@constants/Clients/whaleshark/enum";
import editableArrayValidator from "@validators/shared/editableArray.validate";

const validator = (FormValues: Record<string, any>) => {
  let errors: TObjStrUnd = {
    title: undefined,
    publishDate: undefined,
    type: undefined,
    category: undefined,
    coverImage: undefined,
  };

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.publishDate = emptyFieldValidate(
    FormValues.publishDate,
    t("core:common:publish_date")
  );

  if (FormValues?.category?.value === EArticleCategories.PUBLIC) {
    errors.type = emptyFieldValidate(FormValues.type, t("core:common:type"));
  }

  errors.coverImage = urlFieldValidate(
    FormValues.coverImage,
    t("core:common:image_link"),
    false
  );

  errors.documents = editableArrayValidator(
    FormValues.documents,
    t("core:common:documents")
  );

  return errors;
};

export default validator;
