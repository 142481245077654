import { ReactElement, useState } from "react";
import { Field } from "react-final-form";
import ButtonContainer from "@components/ButtonContainer";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import FileSignIcon from "mdi-react/FileSignIcon";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import useStates from "@mappers/Shared/getState.mapper";
import { useTranslation } from "react-i18next";
import IFormProps from "@interfaces/Shared/formProps";
import IReport from "@interfaces/Clients/WhaleShark/report/report";

interface IModifyForm extends IFormProps {
  data: IReport;
}

const ModifyForm = ({ handleSubmit, data }: IModifyForm): ReactElement => {
  const { t } = useTranslation(["core", "whaleshark"]);
  const { GetState, GetStates } = useStates();
  const [status] = useState(GetState(data?.state));

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.title")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <FileSignIcon />
          </div>
          <Field
            name="title"
            component={FormTextField}
            type="text"
            placeholder={`${t("core:common.title")} ${t("core:common.report")}`}
            initialValue={data?.title}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.url")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <Field
            name="reportUrl"
            component={FormTextField}
            type="text"
            placeholder="https://...report.pdf"
            initialValue={data?.reportUrl}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.url_image")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <LinkVariantIcon />
          </div>
          <Field
            name="imageUrl"
            component={FormTextField}
            type="text"
            placeholder="https://...report.png"
            initialValue={data?.imageUrl}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={status}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default ModifyForm;
