import { ReactElement } from "react";
import Create from "@shared/FormTemplates/Create";
import formMapper from "@mappers/Clients/Hackatao/claimTrait/ClaimTrait.mapper";
import createItem from "@services/Clients/Hackatao/claimTrait/createItem";
import FormValidator from "@validators/Clients/Hackatao/claimTrait/ClaimTrait.validate";
import CreateForm from "./CreateForm";

const CreateItem = (): ReactElement => (
  <Create
    CustomForm={CreateForm}
    createHook={createItem}
    validator={FormValidator}
    formMapper={formMapper}
  />
);

export default CreateItem;
