import { ReactElement } from "react";
import { Col } from "reactstrap";
import ButtonContainer from "@components/ButtonContainer";
import { format } from "date-fns";
import CounterIcon from "mdi-react/CounterIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import CalendarIcon from "mdi-react/CalendarIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import { useTranslation } from "react-i18next";
import IFearGreed from "@interfaces/Clients/WhaleShark/fearGreed/fearGreed";
import BoxImage from "@components/BoxImage";

interface IDetailForm {
  data: IFearGreed;
}

const DetailForm = ({ data }: IDetailForm): ReactElement => {
  const { t } = useTranslation(["whaleshark", "core"]);

  return (
    <form className="form">
      {data?.imageUrl && (
        <Col md={5}>
          <BoxImage urlImage={data.imageUrl} />
        </Col>
      )}
      <Col md={7}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.year")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarIcon />
            </div>
            <input type="text" value={data?.year} disabled />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.month")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CalendarMonthIcon />
            </div>
            <input
              type="text"
              value={
                data?.year &&
                format(new Date(data?.year, data?.month - 1), "LLLL")
              }
              disabled
            />
          </div>
        </div>

        {data?.imageUrl && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.url_image")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <LinkVariantIcon />
              </div>
              <input type="text" value={data?.imageUrl} disabled />
              <a
                href={data?.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="form__form-group-icon">
                  <OpenInNewIcon />
                </div>
              </a>
            </div>
          </div>
        )}

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.index")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CounterIcon />
            </div>
            <input value={`${data?.index}`} disabled />
          </div>
        </div>
      </Col>
      <ButtonContainer />
    </form>
  );
};

export default DetailForm;
