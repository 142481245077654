import { t } from "i18next";
import emptyFieldValidate from "../../../shared/empty.validate";
import urlFieldValidate from "../../../shared/url.validate";

export type TErrors = {
  title: string | undefined;
  description: string | undefined;
  collectionName: string | undefined;
  imageUrl: string | undefined;
  openSeaUrl: string | undefined;
};

const CreateHighlight = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    title: undefined,
    description: undefined,
    collectionName: undefined,
    imageUrl: undefined,
    openSeaUrl: undefined,
  };

  errors.title = emptyFieldValidate(FormValues.title, t("core:common:title"));

  errors.description = emptyFieldValidate(
    FormValues.description,
    t("core:common:description")
  );

  errors.collectionName = emptyFieldValidate(
    FormValues.collectionName,
    t("core:common:collection_name")
  );

  errors.openSeaUrl = urlFieldValidate(
    FormValues.openSeaUrl,
    "OpenSea URL",
    true
  );

  errors.imageUrl = urlFieldValidate(
    FormValues.imageUrl,
    t("core:common:url_image"),
    true
  );

  return errors;
};

export default CreateHighlight;
