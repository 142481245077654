import EditableArray, {
  IEditableArray,
} from "@components/EditableArray/EditableArray";
import { MAX_NUMBER_DOCUMENTS } from "@constants/Clients/whaleshark/articles";
import useDocumentsColumns from "@hooks/Shared/useDocumentsColumns";
import { IDocument } from "@interfaces/Shared/document";

interface IDocuments
  extends Omit<IEditableArray<IDocument>, "columns" | "maxLength"> {}

const Documents = (props: IDocuments) => {
  const columns = useDocumentsColumns();

  return (
    <EditableArray
      {...props}
      maxLength={MAX_NUMBER_DOCUMENTS}
      columns={columns}
    />
  );
};

export default Documents;
