import { API_URL_WHALESHARK_ARTICLES } from "@constants/Clients/whaleshark/whaleshark.env";
import IArticle from "@interfaces/Clients/WhaleShark/articles/article";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetArticle = (uuid: string) =>
  useApiCall<IArticle>({
    url: API_URL_WHALESHARK_ARTICLES + uuid,
  });

export default useGetArticle;
