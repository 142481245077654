import { useState, ReactElement } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import ButtonContainer from "@components/ButtonContainer";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import CalendarRangeIcon from "mdi-react/CalendarRangeIcon";
import OrderNumericAscendingIcon from "mdi-react/OrderNumericAscendingIcon";
import BookmarkBoxMultipleIcon from "mdi-react/BookmarkBoxMultipleIcon";
import EthereumIcon from "mdi-react/EthereumIcon";
import ViewHeadlineIcon from "mdi-react/ViewHeadlineIcon";
import FormTextField from "@components/Shared/FormTextField";
import renderSelectField from "@easydev/form/Select";
import DatePickerField from "@components/Shared/FormDatePickerField";
import Condition from "@easydev/form/Condition";
import { AORIST, OPEN_SEA } from "@constants/types";
import { useTranslation } from "react-i18next";
import useStates from "@mappers/Shared/getState.mapper";
import useTypes from "@mappers/Clients/Reisinger/nftPieces/getType.mapper";
import { FormApi } from "final-form";
import IFormProps from "@interfaces/Shared/formProps";

const CreateForm = ({ handleSubmit, form }: IFormProps): ReactElement => {
  const { t } = useTranslation(["reisinger", "core"]);
  const { GetState, GetStates } = useStates();
  const { GetType, GetTypes } = useTypes();
  const [initialStateValue] = useState(GetState(true));
  const [initialTypeValue] = useState(GetType(OPEN_SEA));

  const cleanFields = (form: FormApi) => {
    form.change("url", null);
    form.change("title", null);
    form.change("pageLink", null);
    form.change("pieceLink", null);
  };

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.type")}</span>
        <div className="form__form-group-field">
          <Field
            name="type"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.type"
            )}`}
            options={GetTypes()}
            initialValue={initialTypeValue}
            isAboveError
          />
          <OnChange name="type">{() => cleanFields(form)}</OnChange>
        </div>
      </div>

      <Condition when="type.value" is={OPEN_SEA}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="url"
              component={FormTextField}
              type="text"
              placeholder="https://opensea.io..."
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <Condition when="type.value" is={AORIST}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.title")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <ViewHeadlineIcon />
            </div>
            <Field
              name="title"
              component={FormTextField}
              type="text"
              placeholder={t("core:common.title")}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <Condition when="type.value" is={AORIST}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.page_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="pageLink"
              component={FormTextField}
              type="text"
              placeholder={t("core:common.page_link")}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <Condition when="type.value" is={AORIST}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.piece_link")}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LinkVariantIcon />
            </div>
            <Field
              name="pieceLink"
              component={FormTextField}
              type="text"
              placeholder={t("core:common.piece_link")}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.release_date")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CalendarRangeIcon />
          </div>
          <Field
            name="releaseDate"
            component={DatePickerField}
            type="text"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.edition")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <BookmarkBoxMultipleIcon />
          </div>
          <Field
            name="edition"
            component={FormTextField}
            type="text"
            placeholder={t("core:common.edition")}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.price")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <EthereumIcon />
          </div>
          <Field
            name="price"
            component={FormTextField}
            type="number"
            placeholder={0.314}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.order")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OrderNumericAscendingIcon />
          </div>
          <Field
            name="order"
            component={FormTextField}
            type="number"
            placeholder={0}
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.state")}</span>
        <div className="form__form-group-field">
          <Field
            name="state"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.state"
            )}`}
            options={GetStates()}
            initialValue={initialStateValue}
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateForm;
