import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { isMobileOnly } from "react-device-detect";
import { renderComponentField } from "@easydev/form/FormField";

interface IDatePickerField {
  onChange: (date: Date) => void;
  value?: Date;
}

const DatePickerField = ({ onChange, value }: IDatePickerField) => {
  const [startDate, setStartDate] = useState<Date>();
  const handleChange = (date: Date) => {
    setStartDate(date);
    onChange(date);
  };

  useEffect(() => {
    if (value) {
      setStartDate(value);
    }
  }, [value]);

  return (
    <div className="date-picker">
      <DatePicker
        className="form__form-group-datepicker"
        selected={startDate}
        onChange={handleChange}
        closeOnScroll={true}
        isClearable
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        popperPlacement="bottom-start"
        withPortal={isMobileOnly}
      />
    </div>
  );
};

export default renderComponentField(DatePickerField);
