import { t } from "i18next";
import emptyFieldValidate from "../../shared/empty.validate";
import mailValidate from "../../shared/mail.validate";
import usernameValidate from "../../shared/username.validate";

export type TErrors = {
  name: string | undefined;
  lastname: string | undefined;
  username: string | undefined;
  isSuperAdministrator: boolean | undefined;
  mail: string | undefined;
  avatarUrl: string | undefined;
  state: string | undefined;
  client: string | undefined;
  projects: string | undefined;
};

const editUser = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    lastname: undefined,
    username: undefined,
    isSuperAdministrator: undefined,
    mail: undefined,
    avatarUrl: undefined,
    state: undefined,
    client: undefined,
    projects: undefined,
  };

  errors.name = emptyFieldValidate(FormValues.name, t("core:common:name"));

  errors.lastname = emptyFieldValidate(
    FormValues.lastname,
    t("core:common:last_name")
  );

  errors.username = usernameValidate(FormValues.username);

  errors.mail = mailValidate(FormValues.mail);

  errors.client = emptyFieldValidate(
    FormValues.client,
    t("core:common:client")
  );

  errors.projects = emptyFieldValidate(
    FormValues.projects,
    t("core:common:projects")
  );

  errors.state = emptyFieldValidate(FormValues.state, t("core:common:state"));

  return errors;
};

export default editUser;
