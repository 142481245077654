import { ReactElement, useState } from "react";
import { OnChange } from "react-final-form-listeners";
import { Field } from "react-final-form";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AccountBoxOutlineIcon from "mdi-react/AccountBoxOutlineIcon";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import PasswordField from "@easydev/form/Password";
import renderMultiSelectField from "@easydev/form/MultiSelect";
import renderSelectField from "@easydev/form/Select";
import FormTextField from "@components/Shared/FormTextField";
import { useTranslation } from "react-i18next";
import useSelectProjects from "@hooks/Security/Projects/useSelectProjects";
import useSelectClients from "@hooks/Security/Clients/useSelectClients";
import IFormProps from "@interfaces/Shared/formProps";
import ButtonContainer from "@components/ButtonContainer";
import useSuperusers from "@mappers/Shared/getSuperuser.mapper";
import WhenFieldChanges from "@components/Shared/FormTemplates/WhenFieldChanges";
import {
  backofficeSelection,
  superUserSelection,
} from "@constants/userFormHelpers";
import { useFormState } from "react-final-form";
import Condition from "@components/EasyDev/form/Condition";

const CreateUserForm = ({ handleSubmit, form }: IFormProps): ReactElement => {
  const { clientsValues } = useSelectClients();
  const { projectsValues, handleChangeSelect } = useSelectProjects();
  const { GetSuperuser, GetSuperusers } = useSuperusers();
  const [superuser] = useState(GetSuperuser(false));
  const { t } = useTranslation(["core"]);

  const { values } = useFormState();
  const superUserValue = values["isSuperAdministrator"];
  const notSuperUser = superUserValue?.value === "0";

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      {clientsValues && (
        <>
          <WhenFieldChanges
            field={"isSuperAdministrator"}
            becomes={superUserSelection}
            set={"client"}
            to={clientsValues[0]}
          />
          <WhenFieldChanges
            field={"isSuperAdministrator"}
            becomes={superUserSelection}
            set={"projects"}
            to={backofficeSelection}
          />
        </>
      )}

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.name")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="name"
            component={FormTextField}
            type="text"
            placeholder="John"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.last_name")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="lastname"
            component={FormTextField}
            type="text"
            placeholder="Doe"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.username")}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="username"
            component={FormTextField}
            type="text"
            placeholder="jdoe"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">{t("core:common.mail")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AlternateEmailIcon />
          </div>
          <Field
            name="mail"
            component={FormTextField}
            type="email"
            placeholder="john.doe@nftstudios.io"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Avatar</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountBoxOutlineIcon />
          </div>
          <Field
            name="avatarUrl"
            component={FormTextField}
            type="text"
            placeholder="https://www.nftstudios.io/images/Avatar-Profile.png"
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.superuser")}
        </span>
        <div className="form__form-group-field">
          <Field
            name="isSuperAdministrator"
            component={renderSelectField}
            type="text"
            placeholder={`${t("core:common.select_placeholder")} ${t(
              "core:common.superuser"
            )}`}
            options={GetSuperusers()}
            initialValue={superuser}
            isAboveError
          />
        </div>
      </div>

      <Condition when={"isSuperAdministrator.value"} is="0">
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.client")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="client"
              component={renderSelectField}
              type="text"
              placeholder={`${t("core:common.select_placeholder")} ${t(
                "core:common.client"
              )}`}
              options={clientsValues}
              isAboveError
            />
            <OnChange name="client">
              {(selection) => {
                if (selection && notSuperUser) {
                  form.change("projects", null);
                  handleChangeSelect(selection.value);
                }
              }}
            </OnChange>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("core:common.projects")}
          </span>
          <div className="form__form-group-field">
            <Field
              name="projects"
              component={renderMultiSelectField}
              placeholder={`${t("core:common.select_placeholder")} ${t(
                "core:common.projects"
              )}`}
              options={projectsValues}
              isAboveError
            />
          </div>
        </div>
      </Condition>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {t("core:common.password")}
        </span>
        <div className="form__form-group-field">
          <Field
            name="password"
            component={PasswordField as any}
            placeholder={t("core:common.password")}
            keyIcon
            isAboveError
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          {`${t("core:common.confirm")} ${t("core:common.password")}`}
        </span>
        <div className="form__form-group-field">
          <Field
            name="confirmpassword"
            component={PasswordField as any}
            placeholder={`${t("core:common.confirm")} ${t(
              "core:common.password"
            )}`}
            keyIcon
            isAboveError
          />
        </div>
      </div>

      <ButtonContainer />
    </form>
  );
};

export default CreateUserForm;
