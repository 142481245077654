import TBenefit from "types/Clients/WhaleShark/benefits/benefit";

const mapper = (formValues: any): TBenefit => ({
  title: formValues.title,
  description: formValues.description,
  order: formValues.order,
  state: Boolean(Number(formValues.state.value)),
});

export default mapper;
