import { API_URL_REISINGER } from "@constants/Clients/reisinger/reisinger.env";
import { IAsset } from "@interfaces/Clients/Reisinger/asset";
import useApiCall from "@hooks/Shared/useApiCall";

const useGetAsset = (uuid: string) =>
  useApiCall<IAsset>({
    url: API_URL_REISINGER + uuid,
  });

export default useGetAsset;
