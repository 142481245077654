import { ReactElement, ComponentType } from "react";
import {
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import { PAGE401 } from "@constants/routes/routes";
import { IsValidResource } from "@helpers/resources.access";

interface IPrivateRouteProps {
  resourceId: string;
  path: string;
  component: ComponentType<RouteComponentProps<any>>;
}

const PrivateRoute = ({
  resourceId,
  path,
  component,
}: IPrivateRouteProps): ReactElement => {
  const isValid = IsValidResource(resourceId);
  const location = useLocation();

  const isPath = !!matchPath(location.pathname, {
    path: path,
    exact: false,
    strict: false,
  });

  if (!isValid && isPath) {
    return <Redirect to={PAGE401} />;
  }

  return <Route path={path} component={component} />;
};

export default PrivateRoute;
