import { useAxios } from "use-axios-client";
import { API_URL } from "@constants/env";
import { Config, Props } from "use-axios-client/bin/useBaseAxios";
import storageService from "@utils/localStorage.service";

const useApiCall = <T>(config: Config): Props<T> => {
  const userLocalStorage = storageService.getLocalUserStorage();

  return useAxios<T>({
    baseURL: API_URL,
    headers: {
      authorization: userLocalStorage?.token ?? "",
    },
    ...config,
  });
};

export default useApiCall;
