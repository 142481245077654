import { createSocialsArray } from "@helpers/socials";
import TCreateEditTimeline from "types/Clients/RocketFactory/timeline/createEditTimeline";

const timelineItemMapper = (formValues: any): TCreateEditTimeline => {
  return {
    date: Number(formValues.publishDate) / 1000,
    dateEnd: formValues.closingDate
      ? Number(formValues.closingDate) / 1000
      : undefined,
    title: formValues.title,
    subtitle: formValues.subtitle,
    image: formValues.linkImage,
    linkVideo: formValues.linkVideo,
    link: formValues.link,
    socials: createSocialsArray(
      formValues.instagram,
      formValues.twitter,
      formValues.discord
    ),
    category: formValues.category.value,
    state: Boolean(Number(formValues.state.value)),
    description: formValues.body,
  };
};

export default timelineItemMapper;
