import { IEditableArrayColumn } from "@interfaces/Shared/editableArrayColumn";
import { useEffect, useState } from "react";

interface ICell {
  index: number;
  column: IEditableArrayColumn;
  initialValue?: any;
  updateEditableData: (index: number, name: string, value: any) => void;
  disabled?: boolean;
}

const Cell = ({
  initialValue,
  column,
  index,
  updateEditableData,
  disabled,
}: ICell) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value}
      type="text"
      placeholder={column.title}
      onChange={(ev) => setValue(ev.target.value)}
      onBlur={() => updateEditableData(index, column.accessor, value)}
      disabled={disabled}
    />
  );
};

export default Cell;
