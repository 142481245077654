import mailValidate from "../../shared/mail.validate";

export type TErrors = {
  email: string | undefined;
};

const forgotPasswordValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = { email: undefined };

  errors.email = mailValidate(FormValues.email);

  return errors;
};

export default forgotPasswordValidate;
